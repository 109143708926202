<template>
    <div>
        <form ref="form-mercancia"  @submit.prevent="addMercancia()"
              @keydown.enter.prevent>              
            <AppDocumentacionAduanera ref="documentacionAduanera" v-model="documentacion_aduanera" @is-valid="isValidDocAduanera = $event"/>
            <hr>
            <div class="form-row">
                <div class="col-lg-2">
                    <input type="number"
                           class="form-control form-control-sm mb-2"
                           placeholder="Cantidad"
                           ref="cantidad"
                           v-model="mercancia.cantidad"
                           @blur="setFormatCantidad()" 
                           step=".001"
                           required />
                    <div class="text-danger">
                        <small>{{ errorCantidad }}</small>
                    </div>
                </div>
                <div class="col-lg-3">
                    <AppSelectClaveUnidadSat ref="unidadSat" v-model="selectedClaveUnidadSat" @is-valid="isValidClaveUnidadSat = $event"/>
                </div>

                <div class="col-lg-7">
                    <AppSelectClaveProductoSat ref="productoSat" v-model="selectedClaveProductoSat" @is-valid="isValidClaveProductoSat = $event"/>
                </div>
                
            </div>
            <div class="form-row">
                <div class="col-lg-2">
                    <div class="input-group input-group-sm mb-2">
                        <input type="number"
                               ref="pesoKg"
                               class="form-control"
                               placeholder="Peso total"
                               v-model="mercancia.peso_en_kg"
                               @blur="setFormatPesoKg()" 
                               step=".001"
                               required />
                        <div class="input-group-append">
                            <span class="input-group-text">Kg</span>
                        </div>
                    </div>
                    <div class="text-danger">
                        <small>{{ errorPeso }}</small>
                    </div>
                </div>
                <div class="col-lg-3" v-if="optionalMaterialPeligroso || requiredMaterialPeligroso">
                    <VueBootstrapTypeaheadCustom :data="listClavesMaterial"
                                                 v-model="selectedMaterialPeligroso"
                                                 :serializer="(s) => s.descripcion + ' ('+ s.clave +')'"
                                                 placeholder="Clave Material Peligroso"
                                                 @hit="setSelectedClaveMaterial"
                                                 @input="searchClaveMaterial"
                                                 class="mb-2"
                                                 size="sm"
                                                 :minMatchingChars="1"
                                                 :rawResults="true">
                        <template v-slot:suggestion="slotProp">
                            <span class="badge badge-secondary">{{
                                slotProp.data.clave
                            }}</span>
                            {{ slotProp.data.descripcion }}
                        </template>
                    </VueBootstrapTypeaheadCustom>
                    <div class="text-danger">
                        <small>{{ errorMaterial }}</small>
                    </div>
                </div>
                <div class="col-lg-3" v-if="optionalMaterialPeligroso || requiredMaterialPeligroso">
                    <VueBootstrapTypeaheadCustom ref="embalaje"
                                                 :data="listClavesEmbalaje"
                                                 v-model="selectedEmbalaje"
                                                 :serializer="(s) => s.descripcion + ' ('+ s.clave +')'"
                                                 placeholder="Embalaje Material Peligroso"
                                                 @hit="setSelectedClaveEmbalaje"
                                                 @input="searchClaveEmbalaje"
                                                 class="mb-2"
                                                 size="sm"
                                                 :minMatchingChars="1"
                                                 :rawResults="true">
                        <template v-slot:suggestion="slotProp">
                            <span class="badge badge-secondary">{{
                                slotProp.data.clave
                            }}</span>
                            {{ slotProp.data.descripcion }}
                        </template>
                    </VueBootstrapTypeaheadCustom>
                    <div class="text-danger">
                        <small>{{ errorEmbalaje }}</small>
                    </div>
                </div>

                <div class="col-lg-2" v-if="requiredGuia">
                    <input type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Guia identificación"
                            v-model="mercancia.numero_guia_identificacion"
                            required />
                    <div class="text-danger">
                        <small></small>
                    </div>
                </div>
                <div class="col-lg-3" v-if="requiredGuia">
                    <input type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Guia descripción"
                            v-model="mercancia.descripcion_guia_identificacion"
                            required />
                    <div class="text-danger">
                        <small></small>
                    </div>
                </div>
                <div class="col-lg-3" v-if="requiredFraccionArancelaria">
                    <input type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Fracción Arancelaria"
                            v-model.trim="mercancia.fraccion_arancelaria"
                            :required="version == '30'"/>
                    <div class="text-danger">
                        <small></small>
                    </div>
                </div>
                <div class="col-lg-3" v-if="requiredPedimento">
                    <input type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Pedimento"
                            v-model.trim="numPedimento"
                            required />
                    <div class="text-danger">
                        <small>{{ errorPedimento }}</small>
                    </div>
                </div>

                <div class="col-lg-3" v-if="requiredTipoMateria">
                    <select class="custom-select custom-select-sm mb-2"
                            v-model="mercancia.tipo_materia"
                            required>
                            <option selected value="null">
                                Seleccione Tipo Materia
                            </option>
                            <option value="01">01 - Materia prima</option>
                            <option value="02">02 - Materia procesada</option>
                            <option value="03">03 - Materia terminada(producto terminado)</option>
                            <option value="04">04 - Materia para la industria manufacturera</option>
                            <option value="05">05 - Otra</option>
                        </select>
                </div>
                <div class="col-lg-3" v-if="requiredTipoMateria && mercancia.tipo_materia == '05'">
                    <input type="text"
                        class="form-control form-control-sm mb-2"
                        placeholder="Descripción Tipo Materia"
                        v-model.trim="mercancia.descripcion_materia"
                        maxlength="50"
                        required />
                </div>
                <div class="ml-auto col-lg-auto col-md-12 mb-2">
                    <button ref="btn_agregar"
                            type="submit"
                            @keyup.enter="addMercancia()"
                            class="btn btn-block btn-sm btn-outline-info">
                        Agregar
                    </button>
                </div>
            </div>
        </form>
        <hr>
        <div class="form-row">
            <div class="col-lg-4 col-md-12">
                <div class="input-group input-group-sm mb-2">
                    <input type="text"
                    class="form-control form-control-sm"
                    placeholder="Buscar en listado de mercancias"
                    v-model.trim="filter"
                    required />
                    <div class="input-group-append">
                        <button class="btn btn-light border" @click="filter = ''" type="button"><font-awesome-icon icon="eraser" /> Limpiar</button>
                    </div> 
                </div>
            </div>
            <div class="ml-auto col-lg-auto col-md-12">
                <button  v-if="selectedMercancias.length > 0 && isSelectabledMercancias" 
                    type="button"
                    @click="editSelectedMercancias()"
                    class="btn btn-light border btn-sm mb-2">
                        <font-awesome-icon icon="pen" />
                        Editar 
                        <span>Doc. aduanera</span>
                        <!-- <span v-else>Pedimento</span> de  -->
                        
                        <b>{{selectedMercancias.length}} </b> 
                        <span v-if="selectedMercancias.length > 1">mercancias seleccionadas</span> 
                        <span v-else>mercancia seleccionada</span>   
                </button> 
            </div>
        </div>
                   
        <b-table 
                 ref="mercanciasTable"
                 sticky-header
                 hover
                 small
                 outlined
                 :filter="filter"
                 responsive
                 head-variant="light"
                 show-empty
                 select-mode="multi"
                 selectable
                 @row-selected="onRowSelected"
                 :fields="fields"
                 :items="listMercanciasItems">
            <template #empty>
                <div class="py-4 text-center text-muted">
                    <h4> No hay mercancia agregada a la lista </h4>
                    <small>por favor agregue su mercancia</small>
                </div>
            </template>
            <template #head(selected)>
                <template v-if="(selectedMercancias.length == listMercanciasItems.length ) && selectedMercancias.length > 0">
                    <span @click="clearSelected()" class="text-primary" aria-hidden="true"><font-awesome-icon icon="check-square"/></span>
                </template>
                <template v-else>
                    <span @click="selectAllRows()" class="text-secondary" aria-hidden="true"><font-awesome-icon icon="square"/></span>
                </template>
            </template>
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span class="text-primary" aria-hidden="true"><font-awesome-icon icon="check-square" /></span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span class="text-secondary" aria-hidden="true"><font-awesome-icon icon="square" /></span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
            <template #cell(cantidad)="row">
                <span v-if="row.item.cantidad % 1 != 0">
                    <small>
                        {{ row.item.cantidad | money(3) }}
                        {{ row.item.unidad }}
                    </small>
                </span>
                <span v-else>
                    <small>
                        {{ row.item.cantidad | money(0) }}
                        {{ row.item.unidad }}
                    </small>
                </span>
            </template>
            <template #cell(bienes_transp)="row">
                <small>
                    {{ row.value }}
                </small>
            </template>
            <template #cell(descripcion)="row">   
                <div>
                    <small>
                        {{ row.value }}
                    </small>
                </div>
                <small v-if="row.item.cve_material_peligroso" style="font-size: 65%;">
                   {{ row.item.cve_material_peligroso}} - {{ row.item.desc_material_peligroso}}
                </small>
                <small v-if="row.item.numero_guia_identificacion" style="font-size: 65%;">
                    <b>Guia:</b> {{ row.item.numero_guia_identificacion }} - {{row.item.descripcion_guia_identificacion}}
                </small>
                <small style="font-size: 65%;">
                    <div v-for="pedimento in row.item.pedimentos" :key="pedimento">
                        <b>Pedimento:</b> {{ pedimento }}
                    </div>
                    
                </small>

            </template>
            <template #cell(peso_en_kg)="row">
                <small> {{ row.item.peso_en_kg | money(3) }} kg</small>
            </template>
            <template #cell(cve_material_peligroso)="row">
                <small>
                    <span v-if="row.item.cve_material_peligroso">Si</span>
                    <span v-else>No</span>
                </small>
            </template>
            <template #cell(documentacion_aduanera)="row">
                <small>
                    <b>
                    <span v-if="row.value.tipo_documento =='01'">01 - Pedimento</span>
                    <span v-if="row.value.tipo_documento =='02'">02 - Autorización de importación temporal</span>
                    <span v-if="row.value.tipo_documento =='03'">03 - Otro</span>
                    </b>
                    <br>

                    <span v-if="row.value.tipo_documento =='01'">
                        {{row.value.num_pedimento}} <br>
                        <small>RFC:{{row.value.rfc_impo}}</small>
                    </span>
                    <span v-if="row.value.tipo_documento !='01'">{{row.value.ident_doc_aduanero}}</span>
                </small>
            </template>
            <template #cell(fraccion_arancelaria)="row">
                <small>
                    {{ row.value }}
                </small>
            </template>
            <template #cell(tipo_materia)="row">
                <small>{{ row.value }}</small><br>
                <small>{{ row.item.descripcion_materia}}</small>
            </template>
            <template #cell(options)="row">
                <a href="#" class="text-danger text-decoration-none mr-2"
                   @click.prevent="removeMercancia(row.index)"><small>
                        <font-awesome-icon icon="times" /> Quitar
                    </small></a>
                 <!--   <a href="#" class="text-primary text-decoration-none"
                   @click.prevent="editMercancia(row)"><small>
                        <font-awesome-icon icon="pen" /> Editar
                    </small></a> -->
            </template>
            <template #custom-foot>
                <tr>
                    <td colspan="3">
                        <b>Mercancias: {{ listMercanciasItems.length }}</b>
                    </td>
                    <td colspan="7"
                        class="text-right">
                        <b class="text-info">{{ pesoTotal | money }} kg</b>
                    </td>
                </tr>
            </template>

        </b-table>
        <br>
        <div class="row justify-content-between">
            <div class="col-md-12 col-lg-auto">
                <button type="button"
                        @click="confirmDeleteAll()"
                        class="btn btn-danger btn-block mb-2 mb-lg-0">
                    <font-awesome-icon icon="trash-alt" />
                    Limpiar Toda la Factura
                </button> 
            </div>
            <div class="col-md-12 col-lg-auto d-flex justify-content-end">
                <button type="button"
                    @click="previousTab()"
                    class="btn btn-light border mr-2 col-md-6 col-lg-auto">
                    <font-awesome-icon icon="arrow-alt-circle-left" /> Anterior
                </button>

                <button type="button"
                        @click="createPreview()"
                        class="btn btn-light border mr-2"
                        :disabled="loadInvoice">
                    <font-awesome-icon icon="eye" />
                    Vista Previa
                </button> 
                <button type="button"
                        @click="createInvoice()"
                        class="btn btn-success col-md-6 col-lg-auto"
                        :disabled="loadInvoice">
                    <span v-if="loadInvoice">
                        <font-awesome-icon icon="file-invoice" />
                        Procesando Factura
                    </span>
                    <span v-else>
                        <font-awesome-icon icon="file-invoice" />
                        Facturar
                    </span>
                </button>
            </div>
        </div>
        
        <b-modal id="modal-pdf-preview"
                 body-class="vh-75"
                 footer-class="d-flex justify-content-between"
                 size="xl"
                 title="Vista Previa">
            <template #default="">
                <object class="embed-responsive-item"
                        type="application/pdf"
                        :data="'//' + preview.pdf"
                        height="100%"
                        width="100%"></object>
            </template>

            <template #modal-footer="{ hide }">
                <a :href="'//' + preview.pdf"
                   :download="preview.pdf.split('/')[2]"
                   target="_blank"
                   class="btn btn-light border">
                    <font-awesome-icon icon="file-invoice" /> Descargar Vista
                    Previa
                </a>
                <button class="btn btn-light border"
                        @click="hide()">
                    Cerrar vista previa
                </button>
            </template>
        </b-modal>

        <b-modal id="modal-download-invoice"
                 ok-only
                 ok-title="Aceptar"
                 title="Factura Emitida">
            <div class="row">
                <div class="col-6">
                    <a :href="'//' + invoice.pdf"
                       :download="invoice.pdf.split('/')[2]"
                       class="btn btn-success btn-block"
                       target="_blank">
                        <font-awesome-icon icon="file-invoice" /> Descargar PDF
                    </a>
                </div>
                <div class="col-6">
                    <a :href="'//' + invoice.xml"
                       :download="invoice.xml.split('/')[2]"
                       class="btn btn-success btn-block"
                       target="_blank">
                        <font-awesome-icon icon="file-code" /> Descargar XML
                    </a>
                </div>
            </div>
            <div v-if="invoice.error != ''"
                 class="alert alert-danger">
                {{ invoice.error }}
            </div>
        </b-modal>

        <b-modal id="modal-errors"
                 ok-only
                 ok-title="Cerrar"
                 ok-variant="light border"
                 title="Complete los siguientes campos faltantes">
				<ul>
                    <li v-if="errorsRequired.cfdi.length > 0"> <b> CFDI: </b> </br> {{errorsRequired.cfdi}} </li>
					<li v-if="errorsRequired.origen.length > 0"> <b> Origen: </b> </br> {{errorsRequired.origen}} </li>
					<li v-if="errorsRequired.destino.length > 0"> <b> Destino: </b> </br> {{errorsRequired.destino}} </li>
					<li v-if="errorsRequired.autotransporte.length > 0"> <b> Transporte: </b> </br> {{errorsRequired.autotransporte}} </li>
				</ul>
        </b-modal>
        <ModalEditPedimentos ref="modalEditPedimentos"/>

    </div>
</template>
<script>
import { mapGetters } from "vuex";
import AppSelectClaveUnidadSat from "@/components/AppSelectClaveUnidadSat.vue";
import AppSelectClaveProductoSat from "@/components/AppSelectClaveProductoSat.vue";
import AppDocumentacionAduanera from "@/components/AppDocumentacionAduanera.vue";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";
import ModalEditPedimentos from "@/views/crear_factura/mercancia/ViewModalEditPedimentos.vue";

export default {
    name: "TabViewMercancia",
    components: { 
        VueBootstrapTypeaheadCustom,
        AppDocumentacionAduanera,
        AppSelectClaveUnidadSat,
        AppSelectClaveProductoSat,
        ModalEditPedimentos
    },
    data() {
        return {
            selectedMercancias: [],
            isValidDocAduanera:false,
            isValidClaveUnidadSat: false,
            isValidClaveProductoSat: false,
            preview: {
                pdf: "",
            },
            invoice: {
                pdf: "",
                xml: "",
                error: "",
            },
            loadInvoice: false,
            optionalMaterialPeligroso: false,
            requiredMaterialPeligroso: false,
            selectedMaterialPeligroso: "",
            selectedEmbalaje: "",
            numPedimento: "",
            errorCantidad: "",
            errorPrecio: "",
            errorPeso: "",
            errorMaterial: "",
            errorEmbalaje: "",
            errorPedimento:"",
            errorTipoDocumento:"",
			errorsRequired:{
                cfdi:"",
				origen:"",
				destino:"",
				autotransporte:"",
			},
            pedimentos:[],
            documentacion_aduanera:{
                tipo_documento: null,
                num_pedimento: null,
                rfc_impo: null,
                ident_doc_aduanero: null
            },
            selectedClaveUnidadSat:{
                clave_unidad: "",
                unidad: "",
            },
            selectedClaveProductoSat:{
                bienes_transp: "",
                descripcion: "",
                is_material_peligroso: null  
            },
            mercancia: {
                bienes_transp: "",
                descripcion: "",
                cantidad: "",
                clave_unidad: "",
                unidad: "",
                cve_material_peligroso: null,
                material_peligroso: null,
                desc_material_peligroso:null,
                embalaje: null,
                descrip_embalaje: null,
                peso_en_kg: "",
                numero_guia_identificacion: null,
                descripcion_guia_identificacion: null,
                peso_guia_identificacion: null,
                fraccion_arancelaria:null,
                tipo_materia:null,
                descripcion_materia:null,
                pedimentos: null,
                documentacion_aduanera: null,
            },
            filter:"",

            fields: [
                {
                    key: "selected",
                    label: "",
                    class: "text-center",
                    thStyle: "width: 5%",
                },
                {
                    key: "cantidad",
                    label: "Cantidad",
                    class: "text-center",
                    thStyle: "width: 5%",
                },
                {
                    key: "bienes_transp",
                    label: "Clave",
                    class: "text-center",
                    thStyle: "width: 10%",
                },
                {
                    key: "descripcion",
                    label: "Descripción",
                    thStyle: "width: 20%",
                },
                {
                    key: "peso_en_kg",
                    label: "Peso",
                    class: "text-right",
                    thStyle: "width: 15%",
                },
                {
                    key: "cve_material_peligroso",
                    label: "Peligroso",
                    class: "text-center",
                    thStyle: "width: 10%",
                },
                {
                    key: "documentacion_aduanera",
                    label: "Doc. Aduanera",
                    class: "text-center",
                    thStyle: "width: 10%",
                },
                {
                    key: "fraccion_arancelaria",
                    label: "Fracción Aran.",
                    class: "text-center",
                    thStyle: "width: 10%",
                },
                {
                    key: "tipo_materia",
                    label: "Tipo Materia",
                    class: "text-center",
                    thStyle: "width: 10%",
                },
                {
                    key: "options",
                    label: "",
                    class: "text-center",
                    thStyle: "width: 15%",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            listClavesMaterial: "catalogos/listClavesMaterial",
            listClavesEmbalaje: "catalogos/listClavesEmbalaje",
            listMonedas: "catalogos/listMonedas",
            listMercanciasItems: "carta_porte/listMercanciasItems",
            customer: "cfdi/getCustomer",
			ubicaciones: "carta_porte/getUbicaciones",
			figura_transporte_operador: "carta_porte/getFiguraTransporteOperador",
			autotransporte_federal: "carta_porte/getAutoTransporteFederal",
            tipoTrasporte: "carta_porte/getTipoTrasporte",
            usoCfdi: "cfdi/getUsoCfdi",
            version: "carta_porte/getVersion",
            serieFactura: "cfdi/getSerieFac"
        }),

        pesoTotal() {
            return this.listMercanciasItems.reduce((accum, item) => {
                return accum + parseFloat(item.peso_en_kg);
            }, 0.0);
        },

        isSelectabledMercancias(){
            if(this.tipoTrasporte.transp_internac == "Sí"){
                return true;
            }else{
                return false;
            }
        },

        requiredFraccionArancelaria(){
            if(this.tipoTrasporte.transp_internac == "Sí"){
                return true;
            }else{
                return false;
            }
        },

        requiredPedimento(){
            if(this.version == "20"){
                if(this.tipoTrasporte.entrada_salida_merc == "Entrada"){
                    return true;
                }else{
                    return false;
                }
            }
        },

        requiredTipoMateria(){
            //if(this.version == "30"){
                if(this.tipoTrasporte.transp_internac == "Sí"){
                    return true;
                }else{
                    return false;
                }
            //}
            
        },

        requiredGuia(){
            /*if(this.mercancia.bienes_transp == "31181701" || this.mercancia.bienes_transp == "24112700"){
                return true;
            }else{
                return false;
            }*/

            return false;
        }
    },
    mounted() {
        //this.loadMonedas();
    },
    watch: {
    "selectedClaveUnidadSat.clave_unidad"(newValue) {
      this.$refs.productoSat.setFocusBienesTransp();
    },
    "selectedClaveProductoSat.is_material_peligroso"(newValue) {
        if (newValue == "1") {
            // material peligroso obligatorio
            this.optionalMaterialPeligroso = false;
            this.requiredMaterialPeligroso = true;
        } else if (newValue == "0,1") {
            // material peligroso opcional
            this.optionalMaterialPeligroso = true;
            this.requiredMaterialPeligroso = false;
        } else {
            this.optionalMaterialPeligroso = false;
            this.requiredMaterialPeligroso = false;
            //Se limpia campos al deshabilitar en caso de que tengan valores
            this.selectedMaterialPeligroso = "";
            this.selectedEmbalaje = "";
            this.mercancia.cve_material_peligroso = null;
            this.mercancia.material_peligroso = null;
            this.mercancia.desc_material_peligroso = null;
            this.mercancia.embalaje = null;
            this.mercancia.descrip_embalaje = null;
        }
      this.$refs.pesoKg.focus();
    },
  },
    methods: {
        setFormatCantidad(){
            if(this.mercancia.cantidad % 1 != 0){
                this.mercancia.cantidad = parseFloat(this.mercancia.cantidad).toFixed(3)
            }
            
        },
        setFormatPesoKg(){
            this.mercancia.peso_en_kg = parseFloat(this.mercancia.peso_en_kg).toFixed(3)
        },

        onRowSelected(items) {
            this.selectedMercancias = items;
        },

        selectAllRows() {
            this.$refs.mercanciasTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.mercanciasTable.clearSelected()
        },

        /*loadMonedas() {
            this.$store.dispatch("catalogos/fetchMonedas");
        },*/

        searchClaveMaterial(query) {
            this.$store.dispatch("catalogos/fetchClavesMaterial", query);
        },

        searchClaveEmbalaje(query) {
            this.$store.dispatch("catalogos/fetchClavesEmbalaje", query);
        },

        setSelectedClaveMaterial(value) {
            this.mercancia.cve_material_peligroso = value.clave;
            this.mercancia.desc_material_peligroso = value.descripcion;
            this.mercancia.material_peligroso = "Sí";

            setTimeout(() => {
                this.$store.dispatch("catalogos/setClavesMaterialEmpty");
            }, 200);

            this.$refs.embalaje.setFocus();
        },

        setSelectedClaveEmbalaje(value) {
            this.mercancia.embalaje = value.clave;
            this.mercancia.descrip_embalaje = value.descripcion;
            setTimeout(() => {
                this.$store.dispatch("catalogos/setClavesEmbalajeEmpty");
            }, 200);
            this.$refs.btn_agregar.focus();
        },

        async addMercancia() {
            if(this.mercancia.numero_guia_identificacion){
                this.mercancia.peso_guia_identificacion = this.mercancia.peso_en_kg;
            }
        
            if (await this.isValidMercancia()) {

                this.mercancia.clave_unidad = this.selectedClaveUnidadSat.clave_unidad;
                this.mercancia.unidad = this.selectedClaveUnidadSat.unidad;
                this.mercancia.bienes_transp = this.selectedClaveProductoSat.bienes_transp;
                this.mercancia.descripcion = this.selectedClaveProductoSat.descripcion;
                   
                if(this.tipoTrasporte.entrada_salida_merc == "Entrada" ){
                    //if(this.version == "30"){
                        this.mercancia.documentacion_aduanera = this.documentacion_aduanera;
                        if(this.mercancia.documentacion_aduanera.tipo_documento == "01"){
                            if(new RegExp('[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}').test(this.mercancia.documentacion_aduanera.num_pedimento)){
                                this.mercancia.documentacion_aduanera.num_pedimento = this.formatoPedimento(this.mercancia.documentacion_aduanera.num_pedimento)
                            }
                        }
                   /*  }else{
                        if(new RegExp('[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}').test(this.numPedimento)){
                            this.pedimentos.push(this.formatoPedimento(this.numPedimento))
                        }else{
                            this.pedimentos.push(this.numPedimento)
                        }
                        this.mercancia.pedimentos = this.pedimentos;
                    } */
                }

                if (this.optionalMaterialPeligroso &&(this.mercancia.cve_material_peligroso == null ||this.mercancia.material_peligroso == null)) {
                    this.mercancia.material_peligroso = "No";
                }

                this.$store.dispatch(
                    "carta_porte/addMercanciasItem",
                    this.mercancia
                );
                this.cleanMercanciaForm();
                this.$refs.cantidad.focus();
            }
        },

        formatoPedimento(numPedimento){
            let anio, aduana, patente, anio_prog_aduana;
            anio = numPedimento.substring(0,2);   
            aduana = numPedimento.substring(2,4);
            patente = numPedimento.substring(4,8);
            anio_prog_aduana = numPedimento.substring(8,15);

            let pedimento = anio + "  " + aduana + "  " + patente + "  " + anio_prog_aduana;
            return pedimento;
        },

        removeMercancia(index) {
            this.$store.dispatch("carta_porte/removeMercanciasItem", index);
        },

        editSelectedMercancias() {
             this.$refs.modalEditPedimentos.show(this.selectedMercancias);
        },

        // editMercancia(data) {
        //     this.$refs.ModalMercancia.show({id:data.index,...data.item});
        // },

        createPreview() {
            this.$store
                .dispatch("cfdi/createInvoce", {
                    preview: true,
                    sendmail: false,
                })
                .then((response) => {
                    this.preview.pdf = response.data.pdf;
                    this.$bvModal.show("modal-pdf-preview");
                })
                .catch((error) => {
                    this.isFail(error);
                });
        },

        createInvoice() {
			// Checa que los form esten completos
			this.isValidRequired();
			if (this.errorsRequired.cfdi.length > 0  || this.errorsRequired.origen.length > 0  || this.errorsRequired.destino.length > 0 || this.errorsRequired.autotransporte.length > 0 )  {
				this.$bvModal.show("modal-errors")
					return
			}
            if (this.customer.email1 == "" && this.customer.email2 == "") {
                this.$bvModal
                    .msgBoxConfirm(
                        "El cliente al que usted desea facturar no tiene email capturado, desea continuar?",
                        {
                            title: "Email no capturado",
                            size: "lg",
                            buttonSize: "sm",
                            okVariant: "success",
                            headerClass: "p-2 border-bottom-0",
                            footerClass: "p-2 border-top-0",
                            okTitle: "Facturar",
                            cancelTitle: "Cancelar",
                            centered: true,
                        }
                    )
                    .then((value) => {
                        if (value) {
                            this.$store
                                .dispatch("cfdi/createInvoce", {
                                    preview: false,
                                    sendmail: false,
                                })
                                .then((response) => {
                                    this.isSuccess(response);
                                })
                                .catch((error) => {
                                    this.isFail(error);
                                });
                        }
                    });
            } else {
                this.loadInvoice = true;
                this.$store
                    .dispatch("cfdi/createInvoce", {
                        preview: false,
                        sendmail: true,
                    })
                    .then((response) => {
                        this.loadInvoice = false;
                        this.isSuccess(response);
                    })
                    .catch((error) => {
                        this.loadInvoice = false;
                        this.isFail(error);
                    });
            }
        },

        isSuccess(response) {
            this.$store.dispatch("cfdi/setCfdiDefaults");
            this.$store.dispatch("carta_porte/setCartaPorteDefaults");
            this.$emit("resetTabsView");

            this.$bvModal.show("modal-download-invoice");

            this.invoice.pdf = response.data.pdf;
            this.invoice.xml = response.data.xml;

            if (response.data.error) this.invoice.error = response.data.error;
        },

        isFail(error) {
            var msg = "";
            
            if(error.data.msg){
                msg = error.data.msg;
            }else{
                const h = this.$createElement
                msg = h('div', { domProps: { innerHTML: error.data } })
            }

            this.$bvModal  
                .msgBoxOk(msg, {
                    title: "Error al Facturar",
                    size: "lg",
                    buttonSize: "sm",
                    okVariant: "primary",
                    headerClass: "p-2 border-bottom-0",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {});
                console.log(error.data)               
        },

        async isValidMercancia() {
            let isValid = true;
            this.errorCantidad = "";
            this.errorPrecio = "";
            this.errorPeso = "";
            this.errorMaterial = "";
            this.errorEmbalaje = "";  
            this.errorPeso = "";  
            this.errorPedimento = "";  

            await this.$refs.unidadSat.validateUnidadSat();  

            if(!this.isValidClaveUnidadSat){
                isValid = false  
            }
        
            await this.$refs.productoSat.validateProductoSat();  

            if(!this.isValidClaveProductoSat){
                isValid = false  
            }

            if (this.mercancia.cantidad <= 0 || this.mercancia.cantidad == "") {
                this.errorCantidad = "Cantidad invalida";
                isValid = false;
            }

            if (
                this.requiredMaterialPeligroso &&
                (this.mercancia.cve_material_peligroso == null ||
                    this.mercancia.material_peligroso == null)
            ) {
                this.errorMaterial = "Clave Material Peligroso indefinida";
                isValid = false;
            }

            if (
                this.requiredMaterialPeligroso &&
                (this.mercancia.embalaje == null ||
                    this.mercancia.descrip_embalaje == null)
            ) {
                this.errorEmbalaje = "Clave Embalaje indefinida";
                isValid = false;
            }

            if (
                parseFloat(this.mercancia.peso_en_kg) <= 0 ||
                this.mercancia.peso_en_kg == ""
            ) {
                this.errorPeso = "Peso no valido";
                isValid = false;
            }

            if(this.tipoTrasporte.entrada_salida_merc == "Entrada"){
                //if(this.version == "30"){
                    this.$refs.documentacionAduanera.validateDoc();
                    if(!this.isValidDocAduanera){
                        isValid = false  
                    }
                /* }else{
                    if(!new RegExp('[0-9]{2}  [0-9]{2}  [0-9]{4}  [0-9]{7}').test(this.numPedimento) 
                        && !new RegExp('[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}').test(this.numPedimento) 
                        && !new RegExp('[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}').test(this.numPedimento)){
                            this.errorPedimento = "Numero de pedimento mal formado";
                            isValid = false;
                    }else if(new RegExp('[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}').test(this.numPedimento)){
                        this.errorPedimento = "Numero de pedimento mal formado, separe con 2 espacios cada grupo de dígitos";
                    }
                } */
            }

            return isValid;
        },

        confirmDeleteAll(){
            this.$bvModal.msgBoxConfirm('¿Esta seguro(a) de cancelar/borrar todos los datos de esta operación?, ' + 
            'incluyendo las secciones CFDI, Origen y Destino, Transporte y Mercancias?', {
                title: 'Limpiar Factura',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Borrar Todo',
                cancelTitle: 'Cancelar',
                cancelVariant: 'light border',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
              if(value){
                this.$store.dispatch("cfdi/setCfdiDefaults");
                this.$store.dispatch("carta_porte/setCartaPorteDefaults");
                this.$emit("resetTabsView");
              }
            })
            .catch(err => {
                // An error occurred
            })
        },

        previousTab(){
            this.$emit("previousTab");
        },

        cleanMercanciaForm() {
            this.selectedClaveUnidadSat.clave_unidad = "";
            this.selectedClaveUnidadSat.unidad = "";
            this.selectedClaveProductoSat.bienes_transp = "";
            this.selectedClaveProductoSat.descripcion = "";
            this.selectedClaveProductoSat.is_material_peligroso = null;

            this.optionalMaterialPeligroso = false;
            this.requiredMaterialPeligroso = false;
            this.selectedMaterialPeligroso = "";
            this.selectedEmbalaje = "";
            this.numPedimento  = "";
            this.errorCantidad = "";
            this.errorPrecio = "";
            this.errorPeso = "";
            this.errorMaterial = "";
            this.errorEmbalaje = "";
            this.errorTipoDocumento = "";
            this.pedimentos= [];

            this.mercancia.bienes_transp = "";
            this.mercancia.descripcion = "";
            this.mercancia.cantidad = "";
            this.mercancia.clave_unidad = "";
            this.mercancia.unidad = "";
            this.mercancia.cve_material_peligroso = null;
            this.mercancia.material_peligroso = null;
            this.mercancia.desc_material_peligroso = null;
            this.mercancia.embalaje = null;
            this.mercancia.descrip_embalaje = null;
            this.mercancia.peso_en_kg = "";
            this.mercancia.numero_guia_identificacion = null;
            this.mercancia.descripcion_guia_identificacion = null;
            this.mercancia.peso_guia_identificacion = null;
            this.mercancia.fraccion_arancelaria = null;
            this.mercancia.tipo_materia = null;
            this.mercancia.descripcion_materia = null;
            this.mercancia.pedimentos = null;
            this.mercancia.documentacion_aduanera = null;
        },

        resetView() {
            this.preview.pdf = "";
            this.invoice.pdf = "";
            this.invoice.xml = "";
            this.invoice.error = "";
            this.loadInvoice = false;

            this.documentacion_aduanera.tipo_documento = null;
            this.documentacion_aduanera.num_pedimento = null;
            this.documentacion_aduanera.rfc_impo = null;
            this.documentacion_aduanera.ident_doc_aduanero = null;  
            this.mercancia.documentacion_aduanera = null;

        },

		isValidRequired: function () {
            var errorCfdi = [];
			var errorOrigen = [];
			var errorDestino = [];
			var errorAutotransporte = [];
			let ubicacion_origen = this.ubicaciones.origenes[0]
			let ubicacion_destino = this.ubicaciones.destinos[0]

            if(!this.serieFactura.idSerieFac &&  !this.serieFactura.type){
                errorCfdi.push('Serie Factura');
            }

            if(this.serieFactura.type == "F" && (!this.usoCfdi || this.usoCfdi == "" ) ){
                errorCfdi.push('Uso CFDI');
            }

            this.errorsRequired.cfdi = errorCfdi.join(", ")

            if(this.tipoTrasporte.transp_internac == "Sí"){
                if(this.version == "30"){
                    if (!this.tipoTrasporte.regimen_aduanero )
                        errorOrigen.push('Regímen Aduanero');
                }else{
                    if (!this.tipoTrasporte.regimenes_aduaneros.length)
                    errorOrigen.push('Regímenes Aduaneros');
                }      
            }

			if (!ubicacion_origen){
				errorOrigen.push('No se definio ubicación origen');
			}else{
				if (!ubicacion_origen.rfc_remitente)
					errorOrigen.push('RFC del remitente');

                if (!ubicacion_origen.nombre_remitente)
					errorOrigen.push('Nombre del remitente');

                if (ubicacion_origen.rfc_remitente == 'XEXX010101000' && !ubicacion_origen.num_reg_id_trib)
					errorOrigen.push('Registro de Identidad Fiscal');

                if (ubicacion_origen.rfc_remitente == 'XEXX010101000' && !ubicacion_origen.residencia_fiscal)
					errorOrigen.push('Residencia Fiscal');
				
				if (!ubicacion_origen.fechahora_salida)
					errorOrigen.push('Fecha-Hora de salida');
				
				if (!ubicacion_origen.domicilio.pais_descripcion)
					errorOrigen.push('País');
				
				if (!ubicacion_origen.domicilio.codigo_postal)
					errorOrigen.push('Codigo Postal');

				if (!ubicacion_origen.domicilio.estado_descripcion)
					errorOrigen.push('Estado');

				if (!ubicacion_origen.domicilio.municipio_descripcion)
					errorOrigen.push('Municipio');

				if (!ubicacion_origen.domicilio.calle)
					errorOrigen.push('Calle');

				if (!ubicacion_origen.domicilio.colonia || ubicacion_origen.domicilio.colonia == "")
					errorOrigen.push('Colonia');

			}
			this.errorsRequired.origen = errorOrigen.join(", ")
		    if (!ubicacion_destino){
				errorDestino.push('No se definio ubicación destino');
			}else{
				if (!ubicacion_destino.rfc_destinatario)
					errorDestino.push('RFC del destinatario');

				if (!ubicacion_destino.nombre_destinatario)
					errorDestino.push('Nombre del destinatario');

                if (ubicacion_destino.rfc_destinatario == 'XEXX010101000' && !ubicacion_destino.num_reg_id_trib)
                    errorDestino.push('Registro de Identidad Fiscal');
				
                if (ubicacion_destino.rfc_destinatario == 'XEXX010101000' && !ubicacion_destino.residencia_fiscal)
                    errorDestino.push('Residencia Fiscal');

				if (!ubicacion_destino.fechahora_prog_llegada)
					errorDestino.push('Fecha-Hora de llegada');

				if (!ubicacion_destino.distancia_recorrida)
					errorDestino.push('Distancia recorrida');

				if (!ubicacion_destino.domicilio.pais_descripcion)
					errorDestino.push('País');
				
				if (!ubicacion_destino.domicilio.codigo_postal)
					errorDestino.push('Codigo Postal');

				if (!ubicacion_destino.domicilio.estado_descripcion)
					errorDestino.push('Estado');

				if (!ubicacion_destino.domicilio.municipio_descripcion)
					errorDestino.push('Municipio');

				if (!ubicacion_destino.domicilio.calle)
					errorDestino.push('Calle');

				if (!ubicacion_destino.domicilio.numero_exterior)
					errorDestino.push('Numero Exterior');

				if (!ubicacion_destino.domicilio.colonia)
					errorDestino.push('Colonia');
			}
			this.errorsRequired.destino = errorDestino.join(", ")
			if (!this.autotransporte_federal.perm_sct)
				errorAutotransporte.push('Tipo de permiso SCT');

			if (!this.autotransporte_federal.num_permiso_sct)
				errorAutotransporte.push('Numero de permiso SCT');

			if (!this.autotransporte_federal.seguros.nombre_aseg)
				errorAutotransporte.push('Nombre de la aseguradora');
			
			if (!this.autotransporte_federal.seguros.num_poliza_seguro)
				errorAutotransporte.push('Numero de la poliza de seguro');
           
            if (!this.autotransporte_federal.identificacion_vehicular.pesobruto_vehicular)
                errorAutotransporte.push('Peso bruto vehicular');

			if (!this.autotransporte_federal.identificacion_vehicular.config_vehicular)
				errorAutotransporte.push('Configuracion vehicular');
			
			if (!this.autotransporte_federal.identificacion_vehicular.placa_vm)
				errorAutotransporte.push('Placa de vehiculo');
			
			if (!this.autotransporte_federal.identificacion_vehicular.anio_modelo_vm)
				errorAutotransporte.push('Año/modelo');
						
			this.listMercanciasItems.some(({material_peligroso})=>{
				if (material_peligroso == "Sí"){
						if(!this.autotransporte_federal.seguros.asegura_med_ambiente || !this.autotransporte_federal.seguros.poliza_med_ambiente){
							errorAutotransporte.push('Nombre y/o poliza de la aseguradora de medio ambiente');
							return true
						}
					}
				})

			if (!this.figura_transporte_operador){
				errorAutotransporte.push('No se definio operador');
			}else{
				if (!this.figura_transporte_operador.rfc_operador)
					errorAutotransporte.push('RFC del operador requerido');
				if (!this.figura_transporte_operador.nombre_operador)
					errorAutotransporte.push('Nombre del operador requerido');
				if (!this.figura_transporte_operador.num_licencia)
					errorAutotransporte.push('Numero de licencia del operador requerido');
			}
			this.errorsRequired.autotransporte = errorAutotransporte.join(", ")
    	}
    },
};

</script>

