<template>
    <b-modal ref="modal-concepto"
    id="modal-concepto"
             size="lg"
             :title="tituloModal"
             :ok-title="titleOkButton"
             cancel-title="Cerrar"
             cancel-variant="light border"
             @shown="setInitFocus()"
             @hidden="cleanForm"
             @ok="handleOk">
        <form @submit.stop.prevent="save">
            <!-- <div class="form-row">
                <div class="form-group col-lg-4">
                    <label>Cantidad</label>
                    <input ref="cantidad"
                           type="text"
                           v-model.trim="form.cant"
                           class="form-control form-control-sm"
                           placeholder="Cantidad"
                           required />
                    <div class="text-danger">
                        <small>{{ errorCantidad }}</small>
                    </div>
                </div>
                <div class="form-group col-lg-4">
                    <label>Unidad SAT</label>
                    <VueBootstrapTypeaheadCustom ref="claveUnidad"
                                                 :data="listClavesUnidad"
                                                 v-model="selectedUnidad "
                                                 :serializer="(s) => s.descripcion + ' ('+ s.clave +')'"
                                                 placeholder="Unidad SAT"
                                                 @hit="setSelectedClaveUnidad"
                                                 @input="searchClaveUnidad"
                                                 size="sm"
                                                 :minMatchingChars="1"
                                                 :rawResults="true"
                                                 :disabled="id != 'new'"
                                                 required>
                        <template v-slot:suggestion="slotProp">
                            <span class="badge badge-secondary">{{
                                slotProp.data.clave
                            }}</span>
                            {{ slotProp.data.descripcion }}
                        </template>
                    </VueBootstrapTypeaheadCustom>
                    <div class="text-danger">
                        <small>{{ errorUnidadSat }}</small>
                    </div>
                </div>
                <div class="form-group col-lg-4">
                    <label>Clave SAT</label>
                    <VueBootstrapTypeaheadCustom ref="claveProducto"
                                                 :data="listClavesProducto"
                                                 v-model="form.claveprodserv"
                                                 :serializer="(s) => s.clavesat"
                                                 placeholder="Clave SAT"
                                                 @hit="setSelectedClaveProducto"
                                                 @input="searchClaveProducto"
                                                 class="mb-2"
                                                 size="sm"
                                                 :minMatchingChars="1"
                                                 :rawResults="true"
                                                 :disabled="id != 'new'"
                                                 required>
                        <template v-slot:suggestion="slotProp">
                            <span class="badge badge-secondary">{{
                                slotProp.data.clavesat
                            }}</span>
                            {{ slotProp.data.descripcion }}
                        </template>
                    </VueBootstrapTypeaheadCustom>
                    <div class="text-danger">
                        <small>{{ errorClaveSat }}</small>
                    </div>
                </div>
            </div> -->

            <div class="form-row">
                <div class="form-group col-6">
                    <label>Clave SAT</label>
                    <select class="custom-select custom-select-sm"
                            ref="claveServicios"
                            v-model="form.claveprodserv"
                            @change="setSelectedServicio()">
                            <option v-for="item in clavesServicios"
                                    :key="item.clave"
                                    :value="item.clave">
                                    {{ item.clave }} - {{ item.desc }} 
                            </option>
                    </select>
                </div>
                <div class="form-group col-6">
                    <label>Descripción</label>
                    <textarea ref="descripcion" v-model.trim="form.descripcion" class="form-control form-control-sm" placeholder="Descripción" rows="2" required></textarea>
                    <div class="text-danger">
                        <small>{{ errorDescripcion }}</small>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-3">
                    <label>Precio</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input ref="precio"
                               type="text"
                               v-model.number="form.precio"
                               class="text-right form-control form-control-sm"
                               placeholder="Precio"
                               required />
                    </div>
                    <div class="text-danger">
                        <small>{{ errorPrecio }}</small>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label>IVA</label>
                    <div class="input-group input-group-sm mb-3">
                        <input type="text"
                               v-model="form.val_iva"
                               class="text-right form-control form-control-sm" />
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label>Ret. IVA</label>
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="checkbox"
                                       v-model="form.usa_retiva"
                                       @change="changeRetIva()">
                            </div>
                        </div> 
                        <input type="text"
                              :disabled="!form.usa_retiva"
                               v-model="form.val_retiva"
                               class="text-right form-control form-control-sm">
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label>Ret. ISR</label>
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="checkbox"
                                       v-model="form.usa_retisr"
                                       @change="changeRetIsr()">
                            </div>
                        </div> 
                        <input type="text"
                               v-model="form.val_retisr "
                               class="text-right form-control form-control-sm"
                               disabled>
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";
import { round } from "@/utils/filters";

export default {
    name: "ModalConcepto",
    components: { VueBootstrapTypeaheadCustom },
    data() {
        return {
            tituloModal:"",
            titleOkButton:"",
            id: 0,
            selectedUnidad: "",
            errorClaveSat: "",
            errorDescripcion: "",
            errorCantidad: "",
            errorUnidadSat: "",
            errorPrecio: "",

            form: {
                clave: "",
                claveprodserv: "78101800",
                unidad: "SERVICIO",
                unidadclave: "E48",
                descripcion: "Transporte de carga por carretera",
                cant: 1,
                precio: "",
                val_iva: 0,
                val_retiva: 0,
                usa_retiva: 0,
                usa_retisr: 0,
                val_retisr: 0,
            },

            clavesServicios:[
                {clave:"78101800", desc:"Transporte de carga por carretera"},
                {clave:"78101801", desc:"Servicios de transporte de carga por carretera (en camión) en área local"},
                {clave:"78101802", desc:"Servicios transporte de carga por carretera (en camión) a nivel regional y nacional"},
                {clave:"78101803", desc:"Servicios de transporte de vehículos"},
                {clave:"78101804", desc:"Servicios de reubicación"},
                {clave:"78101806", desc:"Servicios transporte de carga por carretera a nivel internacional"},
                {clave:"78101807", desc:"Servicios de transporte de carga de petróleo o químicos por carretera"},
                {clave:"78101900", desc:"Transporte de carga intermodal"},
                {clave:"78101901", desc:"Transporte aéreo a marítimo "},
                {clave:"78101902", desc:"Transporte marítimo a ferroviario "},
                {clave:"78101903", desc:"Transporte marino a carretera (por camión) "},
                {clave:"78101904", desc:"Transporte aéreo a carretera (por camión) "},
                {clave:"78101905", desc:"Transporte por vagones de ferrocarril "},
                {clave:"78102200", desc:"Servicios postales de paqueteo y courrier"},
                {clave:"78102201", desc:"Servicios de entrega postal nacional"},
                {clave:"78102203", desc:"Servicios de envío, recogida o entrega de correo"},
                {clave:"78102204", desc:"Servicios de entrega a nivel mundial de cartas o paquetes pequeños"},
                {clave:"78102205", desc:"Servicios de entrega local de cartas o paquetes pequeños"},
                {clave:"78121603", desc:"Tarifa de los fletes"},
                {clave:"78141500", desc:"Servicios de organización de transportes"},
                {clave:"78141501", desc:"Servicios de expedidores de fletes"},
                {clave:"84121806", desc:"Servicios de custodia de valores"},
                {clave:"92121800", desc:"Servicios de carros blindados y transporte de valores"},
                {clave:"92121801", desc:"Servicio de carros blindados"},
                {clave:"92121802", desc:"Servicio de transporte de dinero"},
            ]
        };
    },
    computed: {
        ...mapGetters({
            listClavesUnidad: "catalogos/listClavesUnidad",
            listClavesProducto: "catalogos/listClavesProducto",
            impuestos: "catalogos/getImpuestos",
            decimales: "catalogos/getDecimales",
        }),
    },
    methods: {
        setInitFocus(){
            this.$refs.claveServicios.focus();
        },

        show(data) {
            this.id = data.id;
            if (this.id != "new") {
                this.tituloModal = "Editar Concepto";
                this.titleOkButton = "Guardar cambios";
                this.loadData(data);
            } else {
                this.tituloModal = "Nuevo Concepto"
                this.titleOkButton = "Agregar Concepto"
                this.form.val_iva = parseFloat(this.impuestos.val_iva);
            }
            this.$store.dispatch("catalogos/fetchImpuestos");
            this.$bvModal.show("modal-concepto");   
        },

        hide() {
            this.$bvModal.hide("modal-concepto");
        },

        loadData(concepto) {
            this.id = concepto.id;
            this.form.clave = concepto.clave;
            this.form.claveprodserv = concepto.claveprodserv;
            this.form.unidad = concepto.unidad;
            this.form.unidadclave = concepto.unidadclave;
            this.form.descripcion = concepto.descripcion;
            this.form.cant = concepto.cant;
            this.form.precio = concepto.precio;
            this.form.val_iva = concepto.val_iva;
            this.form.val_retiva = concepto.val_retiva;
            this.form.usa_retiva= concepto.usa_retiva;
            this.form.usa_retisr= concepto.usa_retisr;
            this.form.val_retisr= concepto.usa_retisr ? this.impuestos.val_retisr: 0;

            this.selectedUnidad = concepto.unidad;
            this.selectedProducto = concepto.claveprodserv;
        },

        changeRetIva(){
            if(this.form.usa_retiva){
                this.form.val_retiva = round(
                    (this.impuestos.val_iva / 3) * 2,
                    6
                );
            }else{
                this.form.val_retiva = 0;
            }
            
        },

        changeRetIsr(){
            if(this.form.usa_retisr){
                this.form.val_retisr = this.impuestos.val_retisr;
            }else{
                this.form.val_retisr = 0;
            }
            
        },

        searchClaveUnidad(query) {
            this.$store.dispatch("catalogos/fetchClavesUnidad", query);
        },

        searchClaveProducto(query) {
            this.$store.dispatch("catalogos/fetchClavesProducto", query);
        },

        setSelectedClaveUnidad(value) {
            this.form.unidadclave = value.clave;
            this.form.unidad = value.descripcion;
            setTimeout(() => {
                this.$store.dispatch("catalogos/setClavesUnidadEmpty");
            }, 200);
            this.$refs.claveProducto.setFocus();
        },

        setSelectedClaveProducto(value) {
            this.form.claveprodserv = value.clavesat;
            this.form.descripcion = value.descripcion;

            setTimeout(() => {
                this.$store.dispatch("catalogos/setClavesProductoEmpty");
            }, 200);
            this.$refs.precio.focus();
        },

        setSelectedServicio() {
            let servicio = this.clavesServicios.find((item) => item.clave == this.form.claveprodserv); 
            this.form.descripcion = servicio.desc;
            this.$refs.precio.focus();
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.save()
        },

        save() {
            if (!this.isValidConcepto()) return;

            this.form.cant = parseFloat(this.form.cant);
            this.form.precio = parseFloat(
                parseFloat(this.form.precio).toFixed(this.decimales)
            );
            this.form.unidad = this.form.unidad.toUpperCase();
            this.form.val_iva = parseFloat(
                parseFloat(this.form.val_iva).toFixed(this.decimales)
            ); 
            this.form.val_retiva = parseFloat(this.form.val_retiva);
            this.form.usa_retiva = this.form.usa_retiva ? 1 : 0;
            this.form.usa_retisr = this.form.usa_retisr ? 1 : 0;

            if (this.id == "new") {
                this.$store.dispatch("cfdi/addConceptosItem", this.form);
            } else {
                this.$store.dispatch("cfdi/editConceptosItem", {
                    index: this.id,
                    ...this.form,
                });
                
            }
            this.$nextTick(() => {
                this.$bvModal.hide("modal-concepto");
            })
            
        },
        
        isValidConcepto() {
            let isValid = true;
            this.errorClaveSat = "";
            this.errorDescripcion = "";
            this.errorCantidad = "";
            this.errorPrecio = "";
            this.errorUnidadSat = "";

            if (this.form.claveprodserv == "") {
                this.errorClaveSat =
                    "Clave del Producto/Servicio debe de ser numerica";
                isValid = false;
            }

            if (this.form.claveprodserv.length != 8) {
                this.errorClaveSat =
                    "Clave del Producto/Servicio Invalida, minimo 8 digitos";
                isValid = false;
            }

            if (
                this.form.descripcion.length < 4 ||
                this.form.descripcion == ""
            ) {
                this.errorDescripcion = "Descripción debe de ser más extensa.";
                isValid = false;
            }

            if (this.form.descripcion.length >= 1000) {
                this.errorDescripcion =
                    "Descripción del producto o servicio es muy extensa, máximo 1,000 caracteres";
                isValid = false;
            }

            if (this.form.descripcion.indexOf("|") > 1) {
                this.errorDescripcion =
                    "Descripción del producto o servicio tiene el caracter invalido '|' ";
                isValid = false;
            }

            if (this.form.cant <= 0 || this.form.cant == "") {
                this.errorCantidad = "Cantidad invalida";
                isValid = false;
            }

            if ((("" + this.form.precio).split(".")[1] || []).length > 6) {
                this.errorPrecio = "Solo se permiten un máximo de 6 decimales.";
                isValid = false;
            }

            if (this.form.precio < 0 || this.form.precio == "") {
                this.errorPrecio = "Precio no valido";
                isValid = false;
            }

            if (this.form.unidadclave == "" || this.form.unidad == "") {
                this.errorUnidadSat = "Clave Unidad SAT indefinida";
                isValid = false;
            }

            /*if (this.form.unidad.length >= 20) {
                this.errorUnidadSat =
                    "Descripción de la unidad es demasiado larga";
                isValid = false;
            }*/

            return isValid;
        },

        cleanForm() {
            this.selectedUnidad = "";
            this.selectedProducto = "";
            this.form.clave = "";
            this.form.claveprodserv = "78101800"
            this.form.descripcion = "Transporte de carga por carretera"
            this.form.cant = 1;
            this.form.unidad = "SERVICIO";
            this.form.unidadclave = "E48";
            this.form.precio = "";
            this.form.val_iva = 0;
            this.form.val_retiva = 0;
            this.form.usa_retiva = 0;
            this.form.val_retisr = 0;
            this.form.usa_retisr = 0;

            this.errorClaveSat = "";
            this.errorDescripcion = "";
            this.errorCantidad = "";
            this.errorPrecio = "";
            this.errorUnidadSat = "";
        },
    },
};
</script>   