import { HTTP } from "@/core/init";
import { dataConfigStorage } from "@/utils/localStorage";
import { round } from '@/utils/filters';

export default {
    namespaced: true,
    state: {
        configConceptsAggregated:false, //uso en vista
        id: null,
        folio: null,
        type: null,
        idSerieFac: null,
        date: "",
        cfdisRelacionados: [],
        cfdisTipoRelacion: null,
        exportacion: "01",
        customer: {
            idcli: null,
            rfc: "",
            nombre: "",
            cp:"",
            regimen: "",
            email1: "",
            email2: "",
            pais:"",
            num_reg_idtrib: null,
        },
        cfdiuse: "",
        paymentCurrency: "MXN",
        paymentCurrencyTC: "1",
        paymentPromise: false, //pagare
        paymentMethod: "PUE",
        paymentForm: "01",
        paymentAccountNumber: null,
        paymentCondition: "CONTADO",
        cfdiRel: "",
        items: [],
        observations: "",
        authorize: "",
        terms: "",
        discountReason: "",
        discountRate: 0,
        complements: [],
        total: null
    },

    getters: {
        isConfigConceptsAggregated: state => state.configConceptsAggregated,

        listConceptos: state => {
            return state.items;
        },

        getCustomer: state => state.customer,

        getCfdisRelacionados: state => {
            return {
                cfdisRelacionados: state.cfdisRelacionados,
                cfdisTipoRelacion: state.cfdisTipoRelacion
            };
        },

        getObservaciones:state=> state.observations,

        getMontos: state => {
            let config_decimales = dataConfigStorage.getValue("decimales");
            let config_val_retisr = dataConfigStorage.getValue("val_retisr");
            let config_val_iva = dataConfigStorage.getValue("val_iva");
            let montos_subtotal = 0, montos_base = 0, montos_descuento = 0, montos_total = 0;
            let montos_traslados = 0, montos_iva = 0;
            let montos_retenciones = 0, montos_retiva = 0, montos_retisr = 0;
                
                
            state.items.forEach(concepto => {
                let subtotal = 0,
                    total = 0,
                    descuento = 0,
                    precio = 0,
                    cant = 0,
                    base = 0;
                let traslados = 0,
                    iva = 0;
                let retenciones = 0,
                    retisr = 0,
                    retiva = 0;
                let descto_val = 0,
                    descto_tasa = 0,
                    iva_tasa = 0,
                    retisr_tasa = 0,
                    retiva_tasa = 0;

                cant = round(concepto.cant || 0, 6);
                precio = round(concepto.precio || 0, config_decimales);
                descto_val = round(concepto.descuento || 0, 6);

                //Calcula Base para impuestos
                base = round(precio * cant, 6);

                //Calcula Tasas de Impuestos
                iva_tasa = round((config_val_iva || 0),6);
                retisr_tasa = round((config_val_retisr || 0),6);
                retiva_tasa =
                    concepto.val_retiva == 0
                        ? round(iva_tasa / 3 * 2, 6)
                        : round(concepto.val_retiva || 0, 6);

                //Calcula Importe
                subtotal = round(precio * cant, 6);

                //Calcula Descuento
                if (concepto.es_tasa_descuento)
                    descuento = round(subtotal * (descto_val / 100), 6);
                else descuento = round(descto_val, 6);

                //calcula tasa % para mostrar
                descto_tasa = (parseFloat(descuento) / base * 100).toFixed(2);

                //Si tiene descuento, se re-calcula la base para impuestos
                if (descuento > 0) base = base - descuento;

                //calculo IVA
                if (concepto.usa_iva && concepto.val_iva == null)
                    iva = round(base * (iva_tasa / 100), 6);
                else if (concepto.usa_iva && concepto.val_iva > 0)
                    iva = round(
                        base * (round(concepto.val_iva || 0, 6) / 100),
                        6
                    );
                traslados = round(iva, 6);

                //Calcula Retenciones
                if (concepto.usa_retiva)
                    retiva = round(base * (retiva_tasa / 100), 6);
                if (concepto.usa_retisr)
                    retisr = round(base * (retisr_tasa / 100),6);
                retenciones = round(retiva + retisr, 6);

                total = subtotal - retenciones + traslados;
                if (descuento > 0) total = total - descuento;
                total = round(total, 6);

                montos_base += base;
                montos_descuento += descuento;

                montos_iva += iva;
                montos_traslados += traslados;

                montos_retiva += retiva;
                montos_retisr += retisr;
                montos_retenciones += retenciones;

                montos_subtotal += subtotal;
                montos_total += total;
            });

            return {
                base: montos_base,
                descuento: montos_descuento,

                iva: round(montos_iva, 2),
                traslados: montos_iva,

                retiva: montos_retiva,
                retisr: montos_retisr,
                retenciones: montos_retenciones,

                subtotal: montos_subtotal,
                total: round(
                    montos_subtotal -
                        montos_descuento +
                        montos_traslados -
                        montos_retenciones,
                    2
                )
            };
        },

        getCondicionesPago: state => {
            return {
                paymentMethod: state.paymentMethod,
                paymentCondition: state.paymentCondition,
                paymentForm: state.paymentForm,
                paymentCurrency: state.paymentCurrency,
                paymentCurrencyTC: state.paymentCurrencyTC
            };
        },

        getUsoCfdi: state => {
            return state.cfdiuse
        },

        getSerieFac: state => {
            return {
                idSerieFac: state.idSerieFac,
                type: state.type,
            }
        }
    },

    mutations: {
        SET_CUSTOMER: (state, payload) => {
            state.customer = payload;
        },

        SET_CFDI_USE: (state, payload) => {
            state.cfdiuse = payload;
        },

        SET_CFDIS_REL: (state, payload) => {
            state.cfdisRelacionados = [];
            state.cfdisRelacionados = payload.cfdisRelacionados;
            state.cfdisTipoRelacion = payload.cfdisTipoRelacion;
        },

        SET_OBSERVATIONS: (state, payload) => {
            state.observations = payload;
        },

        SET_SERIE_FAC: (state, payload) => {
            state.idSerieFac = payload.id;
            state.type = payload.tipo;
        },

        SET_DATE: (state, payload) => {
            state.date = payload;
        },

        CONCEPTOS_CONFIG_AGGREGATED: (state, status) =>{
            state.configConceptsAggregated = status;
        },

        ADD_CONCEPTOS_ITEM: (state, data) => {
            state.items.push({
                idprod: null,
                clave: data.clave,
                claveprodserv: data.claveprodserv,
                unidad: data.unidad,
                unidadclave: data.unidadclave,
                descripcion: data.descripcion,
                obs: "",
                cant: data.cant,
                precio: data.precio,
                descuento: 0,
                descuento_tasa: 0,
                es_tasa_descuento: 1,
                es_tasa_ieps: 1,
                val_implocales: [],
                val_iva: data.val_iva,
                val_ieps: 0,
                val_retiva: data.val_retiva,
                val_predial: "",
                val_aduana_pedimento: "",
                usa_iva: 1,
                usa_ieps: 0,
                usa_retiva: data.usa_retiva,
                usa_retisr: data.usa_retisr,
                usa_predial: 0,
                usa_ish: 0,
                usa_aduana: 0,
                usa_implocales: 0,
                objetoimp: "02"
            });
        },

        EDIT_CONCEPTOS_ITEM: (state, data) => {
            let concepto = state.items.find(
                (item, index) => index == data.index
            );
            concepto.cant = data.cant;
            concepto.descripcion = data.descripcion;
            concepto.precio = data.precio;
            concepto.val_iva = data.val_iva;
           // concepto.usa_iva = data.usa_iva;
            concepto.val_retiva = data.val_retiva;
            concepto.usa_retiva = data.usa_retiva;
            concepto.usa_retisr = data.usa_retisr;
        },

        REMOVE_CONCEPTOS_ITEM: (state, index) => {
            state.items.splice(index, 1);
        },

        SET_CONDICIONES_PAGO: (state, data) => {
            state.paymentMethod = data.paymentMethod;
            state.paymentCondition = data.paymentCondition;
            state.paymentForm = data.paymentForm;
            state.paymentCurrency = data.paymentCurrency;
            state.paymentCurrencyTC = data.paymentCurrencyTC;
        },

        SET_CFDI_DEFAULTS: state => {
            state.configConceptsAggregated = false, //uso en vista
            state.id = null;
            state.folio = null;
            state.type = null;
            state.idSerieFac = null;
            state.date = "";
            state.cfdisRelacionados = [];
            state.cfdisTipoRelacion = null;
            state.exportacion = "01";

            state.customer = {
                idcli: null,
                rfc: "",
                nombre: "",
                cp: "",
                regimen: "",
                email1: "",
                email2: "",
                pais:"",
                num_reg_idtrib: null,
            };

            state.cfdiuse = "";
            state.paymentCurrency = "MXN";
            state.paymentCurrencyTC = "1";
            state.paymentPromise = false;
            state.paymentMethod = "PUE";
            state.paymentForm = "01";
            state.paymentAccountNumber = null;
            state.paymentCondition = "CONTADO";
            state.cfdiRel = "";
            state.items = [];
            state.observations = "";
            state.authorize = "";
            state.terms = "";
            state.discountReason = "";
            state.discountRate = 0;
            state.complements = [];
            state.total = null;
        }
    },

    actions: {
        setCustomer: async ({ commit }, data) => {
            commit("SET_CUSTOMER", {
                idcli: data.idcli,
                rfc: data.rfc.trim(),
                nombre: data.nombre.trim(),
                cp: data.cp != null? data.cp.trim(): "",
                regimen: data.regimen != null? data.regimen.trim(): "",
                email1: data.email1? data.email1.trim():"",
                email2: "",
                pais: data.pais != null? data.pais.trim(): "",
                num_reg_idtrib: data.num_reg_idtrib != null? data.num_reg_idtrib.trim(): null
            });
        },

        setCustomerID: async ({ commit }, payload) => {
            let { data } = await HTTP.get("/customers/" + payload.idcli);
            commit("SET_CUSTOMER", {
                idcli: data.idcli,
                rfc: data.rfc.trim(),
                nombre: data.nombre.trim(),
                cp: data.cp != null? data.cp.trim(): "",
                regimen: data.regimen != null? data.regimen.trim(): "",
                email1: data.email1 != null ? data.email1.trim(): "" ,
                email2: data.email2 != null ? data.email2.trim(): "",
                pais: data.pais != null? data.pais.trim(): "",
                num_reg_idtrib: data.num_reg_idtrib != null? data.num_reg_idtrib.trim(): null
            });
        },

        setCustomerEmpty: ({ commit }) => {
            commit("SET_CUSTOMER", {
                idcli: null,
                rfc: "",
                nombre: "",
                cp: "",
                regimen: "",
                email1: "",
                email2: "",
                pais:"",
                num_reg_idtrib: null,
            });
        },

        setCfdiUse: ({ commit }, payload) => {
            commit("SET_CFDI_USE", payload);
        },

        setSerieFac: ({ commit }, payload) => {
            commit("SET_SERIE_FAC", payload);
        },

        setCfdisRel: ({ commit }, payload) => {
            commit("SET_CFDIS_REL", payload);
        },

        setCfdiObservations: ({ commit }, payload) => {
            commit("SET_OBSERVATIONS", payload);
        },

        addSerie:({ commit }, data) => {
            return new Promise((resolve, reject) => {
                HTTP.post(`/series`, {
                    id: null,
                    serie: data.serie,
                    tipo: data.tipo,
                    numcert: "",
                    folioini: 1,
                    foliofin: null,
                    foliosig: data.foliosig,
                    regimen: "",
                    expedidoen: 0,
                    calle: "",
                    numext: "",
                    numint: "",
                    colonia: "",
                    cp: "",
                    ciudad: "",
                    estado: "",
                    pais: "",
                    idfto: data.idfto,
                    logotipo: null,
                    textheader: "",
                    activa: 1,
                    updated: ""
                }).then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err.response);
                });
            });
        },

        setDate: ({ commit }, payload) => {
            commit("SET_DATE", payload);
        },

        addConceptosItem({ commit }, item) {
            commit("ADD_CONCEPTOS_ITEM", item);
        },

        addConfigConceptosItems({ commit }, conceptos) {
            conceptos.forEach((item) => {
                commit("ADD_CONCEPTOS_ITEM", item);
            });
            commit("CONCEPTOS_CONFIG_AGGREGATED", true);
        },

        editConceptosItem({ commit }, data) {
            commit("EDIT_CONCEPTOS_ITEM", data);
        },

        removeConceptosItem({ commit }, index) {
            commit("REMOVE_CONCEPTOS_ITEM", index);
        },

        setCondicionesPago({ commit }, data) {
            commit("SET_CONDICIONES_PAGO", data);
        },

        setCfdiDefaults({ commit }) {
            commit("SET_CFDI_DEFAULTS");
        },

        createInvoce({ rootState, state, commit }, params) {
            let versionCFDI = dataConfigStorage.getValue("val_version");
            let cfdiuse = "";
            let paymentMethod = "";
            let paymentCondition = "";
            let paymentForm =  "";
            let paymentCurrency = "";
            let paymentCurrencyTC = "";   
            let items = [];

            if(state.type == "T"){
                cfdiuse = "S01";
                paymentMethod= "";
                paymentCondition= "";
                paymentForm= "";
                paymentCurrency= "XXX";
                paymentCurrencyTC = null;    

                rootState.carta_porte.mercancias.items.forEach(item => {
                    items.push({
                        clave: "",
                        claveprodserv: item.bienes_transp,
                        unidad: item.unidad.toUpperCase(),
                        unidadclave: item.clave_unidad,
                        descripcion: item.descripcion,
                        cant: parseFloat(item.cantidad),
                        precio: 0,
                        usa_iva: 0,
                        val_iva: null,
                        usa_retiva: 0,
                        val_retiva: 0,
                        objetoimp: "01"
                    })
                });
            }else{
                cfdiuse = state.cfdiuse;
                paymentMethod = state.paymentMethod;
                paymentCondition = state.paymentCondition;
                paymentForm = state.paymentForm;
                paymentCurrency = state.paymentCurrency;
                paymentCurrencyTC = state.paymentCurrencyTC;    

                items = state.items;
            }

            return new Promise((resolve, reject) => {
                HTTP.get("/cfdis/time")
                    .then(() => {
                        HTTP.post(
                            "/cfdis",
                            {
                                id: state.id,
                                folio: state.folio,
                                type: state.type,
                                idSerieFac: state.idSerieFac,
                                date: state.date,
                                cfdisRelacionados: state.cfdisRelacionados,
                                cfdisTipoRelacion: state.cfdisTipoRelacion,
                                exportacion: state.exportacion,
                                customer: state.customer,
                                cfdiuse: cfdiuse,
                                paymentCurrency: paymentCurrency,
                                paymentCurrencyTC: paymentCurrencyTC,
                                paymentPromise: state.paymentPromise,
                                paymentMethod: paymentMethod,
                                paymentForm: paymentForm,
                                paymentAccountNumber:
                                    state.paymentAccountNumber,
                                paymentCondition: paymentCondition,
                                cfdiRel: state.cfdiRel,
                                items: items,
                                observations: state.observations,
                                authorize: state.authorize,
                                terms: state.terms,
                                discountReason: state.discountReason,
                                discountRate: state.discountRate,
                                complements: [
                                    {
                                        carta_porte: rootState.carta_porte
                                    }
                                ],
                                total: state.total
                            },
                            {
                                params: {
                                    preview: params.preview,
                                    sendmail: params.sendmail,
                                    ver: versionCFDI
                                }
                            }
                        )
                            .then(response => {
                                resolve(response);
                            })
                            .catch(error => {
                                reject(error.response);
                            });
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        }
    }
};
