<template>
    <form @submit.prevent="nextStep()"
          @keydown.enter.prevent>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="mb-2">RFC y Nombre Origen</div>
                <div class="form-row">
                    <div class="col-6">
                        <input ref="rfc_remitente_valid"
                               v-model.trim="ubicacion_origen.rfc_remitente"
                               @blur="rfcOrigenToUpperCase()"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               :class="{ 'is-invalid': validations.rfc_remitente_valid }"
                               placeholder="RFC"
                               maxlength="13"
                               required />
                    </div>
                    <div class="col-6">
                        <input v-model.trim="ubicacion_origen.nombre_remitente"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Nombre"
                               required />
                    </div>
                </div>
                <div class="form-row" v-if="ubicacion_origen.rfc_remitente =='XEXX010101000'">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_origen.num_reg_id_trib"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Registro de Identidad Fiscal"
                               maxlength="40"
                               required />
                    </div>
                    <div class="col-6">
                        <AppSelectPais 
                            ref="residencia_origen_descripcion" 
                            v-model="selectedResidenciaFiscalOrigen"
                            placeHolder="Residencia Fiscal"/>
<!--                         <VueBootstrapTypeaheadCustom ref="pais_origen_descripcion"
                                                     :data="listPaises"
                                                     v-model.trim="ubicacion_origen.residencia_fiscal_descripcion"
                                                     :serializer="(s) => s.descripcion"
                                                     placeholder="Residencia Fiscal"
                                                     @hit="setSelectedOrigenResidenciaFiscal"
                                                     @input="searchPais"
                                                     :prepend="ubicacion_origen.residencia_fiscal"
                                                     :rawResults="true"
                                                     :minMatchingChars="1"
                                                     size="sm"
                                                     class="mb-2"
                                                     required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.clave
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                </div>
                <div class="mt-2 mb-2">Domicilio Origen</div>
                <div class="form-row">
                    <div class="col-8">
                        <!-- <input ref="ubicacion_destino_cp"
                               v-model="ubicacion_origen.domicilio.pais_descripcion"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               disabled
                               required /> -->
                        <AppSelectPais ref="pais_origen_descripcion" v-model="selectedPaisOrigen"/>
<!--                         <VueBootstrapTypeaheadCustom ref="pais_origen_descripcion"
                                                     :data="listPaises"
                                                     v-model.trim="
                                ubicacion_origen.domicilio.pais_descripcion
                            "
                                                     :serializer="(s) => s.descripcion"
                                                     placeholder="Pais"
                                                     @hit="setSelectedOrigenPais"
                                                     @input="searchPais"
                                                     :prepend="ubicacion_origen.domicilio.pais"
                                                     :rawResults="true"
                                                     :minMatchingChars="1"
                                                     class="mb-2"
                                                     size="sm"
                                                     required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.clave
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                    <div class="col-4">
                       <div class="mb-2">
                            <input ref="ubicacion_origen_cp"
                            
                               v-model.trim="ubicacion_origen.domicilio.codigo_postal"
                               type="text"
                               class="form-control form-control-sm"
                               placeholder="Codigo Postal"
                               required />
                               <small v-if="errorOrigenColonia != ''" class="text-danger">{{errorOrigenColonia}}</small>
                       </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-5">
                       <!--  <input v-model="
                                ubicacion_origen.domicilio.estado_descripcion
                            "
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Estado"
                               :disabled="disabledOrigenEstado"
                               required /> -->
                               <AppSelectEstado ref="estado_Origen_descripcion" 
                               v-model="selectedEstadoOrigen" 
                               :pais="ubicacion_origen.domicilio.pais"
                               :disabled="disabledOrigenEstado"/>
                        <!-- <VueBootstrapTypeaheadCustom ref="estado_Origen_descripcion"
                                :data="listEstados"
                                v-model.trim="ubicacion_origen.domicilio.estado_descripcion"
                                :serializer="(s) => s.descripcion"
                                placeholder="Estado"
                                @hit="setSelectedOrigenEstado"
                                @input="searchEstado({search:ubicacion_origen.domicilio.estado_descripcion,country:ubicacion_origen.domicilio.pais})"
                                :prepend="ubicacion_origen.domicilio.estado"
                                :rawResults="true"
                                :minMatchingChars="1"
                                class="mb-2"
                                size="sm"
                                :disabled="disabledOrigenEstado"
                                required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.estado
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                    <div class="col-7">
                        <input v-model.trim="
                                ubicacion_origen.domicilio.municipio_descripcion
                            "
                               ref="ubicacion_origen_municipio"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Ciudad / Municipio"
                               :disabled="disabledOrigenMunicipio"
                               required />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_origen.domicilio.calle"
                               ref="ubicacion_origen_calle"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Calle"
                               required />
                    </div>
                    <div class="col-3">
                        <input v-model.trim="ubicacion_origen.domicilio.numero_exterior"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="N° Ext" />
                    </div>
                    <div class="col-3">
                        <input v-model.trim="ubicacion_origen.domicilio.numero_interior"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="N° Int" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_origen.domicilio.referencia"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Referencia" />
                    </div>
                    <div class="col-6">
                        <input v-if="ubicacion_origen.domicilio.pais !='MEX'" 
                                v-model.trim="ubicacion_origen.domicilio.colonia"
                                type="text"
                                class="form-control form-control-sm mb-2"
                                placeholder="Colonia" />
                        <select v-else 
                                v-model="ubicacion_origen.domicilio.colonia"
                                class="custom-select custom-select-sm mb-2"
                                :disabled="disabledOrigenColonia"
                                required>
                            <option value="">Seleccione Colonia</option>
                            <option v-for="item in this.ubicacion_origen
                                    .list_colonias"
                                    :key="item.colonia"
                                    :value="item.colonia">
                                {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="mt-2 mb-2">Fecha y Hora de Salida <small>(Aproximada)</small></div>
                <div class="form-row mb-2">
                    <div class="col-6">
                        <b-form-datepicker 
                            v-model="ubicacion_origen.fecha"
                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                            locale="es" 
                            size="sm"
                            class="mb-2">
                        </b-form-datepicker>
                    </div>
                    <div class="col-6">
                        <b-form-timepicker 
                            v-model="ubicacion_origen.hora" 
                            hour12
                            size="sm" 
                            locale="es" 
                            class="mb-2">
                        </b-form-timepicker>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="mb-2">RFC y Nombre Destino</div>
                <div class="form-row">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_destino.rfc_destinatario"
                               @blur="rfcDestinoToUpperCase()"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="RFC"
                               maxlength="13"
                               required />
                    </div>
                    <div class="col-6">
                        <input v-model.trim="ubicacion_destino.nombre_destinatario"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Nombre"
                               required />
                    </div>
                </div>
                <div class="form-row" v-if="ubicacion_destino.rfc_destinatario =='XEXX010101000'">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_destino.num_reg_id_trib"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Registro de Identidad Fiscal"
                               maxlength="40"
                               required />
                    </div>
                    <div class="col-6">
                        <AppSelectPais 
                            ref="residencia_destino_descripcion" 
                            v-model="selectedResidenciaFiscalDestino"
                            placeHolder="Residencia Fiscal"/>
<!--                         <VueBootstrapTypeaheadCustom ref="pais_destino_descripcion"
                                                     :data="listPaises"
                                                     v-model.trim="ubicacion_destino.residencia_fiscal_descripcion"
                                                     :serializer="(s) => s.descripcion"
                                                     placeholder="Residencia Fiscal"
                                                     @hit="setSelectedDestinoResidenciaFiscal"
                                                     @input="searchPais"
                                                     :prepend="ubicacion_destino.residencia_fiscal"
                                                     :rawResults="true"
                                                     :minMatchingChars="1"
                                                     size="sm"
                                                     class="mb-2"
                                                     required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.clave
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                </div>
                <div class="mt-2 mb-2">Domicilio Destino</div>
                <div class="form-row">
                    <div class="col-8">
                        <!-- <input ref="ubicacion_destino_cp"
                               v-model="ubicacion_destino.domicilio.pais_descripcion"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               disabled
                               required /> -->
                               <AppSelectPais ref="pais_destino_descripcion" v-model="selectedPaisDestino"/>
                        <!-- <VueBootstrapTypeaheadCustom ref="pais_destino_descripcion"
                                                     :data="listPaises"
                                                     v-model.trim="ubicacion_destino.domicilio.pais_descripcion"
                                                     :serializer="(s) => s.descripcion"
                                                     placeholder="Pais"
                                                     @hit="setSelectedDestinoPais"
                                                     @input="searchPais"
                                                     :prepend="ubicacion_destino.domicilio.pais"
                                                     :rawResults="true"
                                                     :minMatchingChars="1"
                                                     size="sm"
                                                     class="mb-2"
                                                     required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.clave
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                    <div class="col-4">
                        <div class="mb-2">
                            <input ref="ubicacion_destino_cp"
                                v-model.trim="ubicacion_destino.domicilio.codigo_postal"
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Codigo Postal"
                                required />
                                <small v-if="errorDestinoColonia != ''" class="text-danger">{{errorDestinoColonia}}</small>
                        </div>        
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-5">
                        <!-- <input v-model="
                                ubicacion_destino.domicilio.estado_descripcion
                            "
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Estado"
                               :disabled="disabledDestinoEstado"
                               required /> -->


                        <AppSelectEstado ref="estado_destino_descripcion" 
                               v-model="selectedEstadoDestino" 
                               :pais="ubicacion_destino.domicilio.pais"
                               :disabled="disabledDestinoEstado"/>

<!--                         <VueBootstrapTypeaheadCustom ref="estado_destino_descripcion"
                                                     :data="listEstados"
                                                     v-model.trim="ubicacion_destino.domicilio.estado_descripcion"
                                                     :serializer="(s) => s.descripcion"
                                                     placeholder="Estado"
                                                     @hit="setSelectedDestinoEstado"
                                                     @input="searchEstado({search:ubicacion_destino.domicilio.estado_descripcion,country:ubicacion_destino.domicilio.pais})"
                                                     :prepend="ubicacion_destino.domicilio.estado"
                                                     :rawResults="true"
                                                     :minMatchingChars="1"
                                                     class="mb-2"
                                                     size="sm"
                                                     :disabled="disabledDestinoEstado"
                                                     required>
                            <template v-slot:suggestion="slotProp">
                                <small class="badge badge-secondary">{{
                                    slotProp.data.estado
                                }}</small>&nbsp;{{ slotProp.data.descripcion }}
                            </template>
                        </VueBootstrapTypeaheadCustom> -->
                    </div>
                    <div class="col-7">
                        <input  ref="ubicacion_destino_municipio"
                                v-model.trim="
                                ubicacion_destino.domicilio
                                    .municipio_descripcion
                            "
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Ciudad / Municipio"
                               :disabled="disabledDestinoMunicipio"
                               />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_destino.domicilio.calle"
                               ref="ubicacion_destino_calle"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Calle"
                               required />
                    </div>
                    <div class="col-3">
                        <input v-model.trim="
                                ubicacion_destino.domicilio.numero_exterior
                            "
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="N° Ext" />
                    </div>
                    <div class="col-3">
                        <input v-model.trim="
                                ubicacion_destino.domicilio.numero_interior
                            "
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="N° Int" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-6">
                        <input v-model.trim="ubicacion_destino.domicilio.referencia"
                               type="text"
                               class="form-control form-control-sm mb-2"
                               placeholder="Referencia" />
                    </div>
                    <div class="col-6">
                        <input v-if="ubicacion_destino.domicilio.pais !='MEX'" 
                                v-model.trim="ubicacion_destino.domicilio.colonia"
                                type="text"
                                class="form-control form-control-sm mb-2"
                                placeholder="Colonia" />
                        <select v-else 
                                v-model="ubicacion_destino.domicilio.colonia"
                                class="custom-select custom-select-sm"
                                :disabled="disabledDestinoColonia"
                                required>
                            <option value="">Seleccione Colonia</option>
                            <option v-for="item in this.ubicacion_destino
                                    .list_colonias"
                                    :key="item.colonia"
                                    :value="item.colonia">
                                {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="mt-2 mb-2">Fecha y Hora de Llegada <small>(Aproximada)</small></div>
                <div class="form-row">
                    <div class="col-6">
                        <b-form-datepicker 
                            v-model="ubicacion_destino.fecha"
                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                            locale="es" 
                            size="sm"
                            class="mb-2">
                        </b-form-datepicker>
                    </div>
                    <div class="col-6">
                        <b-form-timepicker 
                            v-model="ubicacion_destino.hora" 
                            hourCycle= "h12"
                            hour12
                            size="sm" 
                            locale="es" 
                            class="mb-2">
                        </b-form-timepicker>
                    </div>
                 </div>
                
            </div>
            <div class="col-lg-12" v-if="showRegimenAduanero && version == '30' ">  
                <div class="mt-2 mb-2">
                    Regímen Aduanero
                </div>
                <div class="form-row mb-2">
                    <div class="col-6">
                        <select v-model="tipoTrasporte.regimen_aduanero"
                                class="custom-select custom-select-sm"
                                required>
                            <option selected value="">Seleccione Regímen Aduanero</option>
                            <option v-for="item in this.listFilterRegimenesAduanero"
                                    :key="item.clave"
                                    :value="item.clave">
                                    {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-12" v-if="showRegimenAduanero && version == '31'">  
                <b-card header="Regímenes Aduaneros">
                    <select v-model="sltRegimenAduanero"
                            class="custom-select custom-select-sm mb-2 col-6"
                            @change="changeSelectRegimenes">
                        <option selected value="null">Seleccione Regímen Aduanero</option>
                        <option v-for="item in this.listFilterRegimenesAduanero"
                                :key="item.clave"
                                :value="item.clave">
                                {{ item.clave }} - {{ item.descripcion }}
                        </option>
                    </select>
                  
                    <b-list-group >
                        <b-list-group-item 
                        v-for="item in this.regimenesAduaneros"
                        :key="item"
                        class="py-2 d-flex justify-content-between align-items-center"
                        >
                        
                        <div><b>{{item}}</b> - {{getDescRegimenAduanero(item)}} </div>                   
                        <a href="#" class="text-danger text-decoration-none mr-2"
                    @click.prevent="removeRegimen(item)"><small>
                            <font-awesome-icon icon="times" /> Quitar
                        </small></a>
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </div>
            
        </div>
        <br>
        <div class="row justify-content-between">
            <div class="col-md-12 col-lg-auto">
                <button type="button"
                        @click="confirmDeleteSection()"
                        class="btn btn-danger btn-block mb-2 mb-lg-0">
                    <font-awesome-icon icon="trash-alt" />
                    Limpiar Origen y Destino
                </button> 
            </div>
            <div class="col-md-12 col-lg-auto d-flex justify-content-end">
                <button type="button"
                        @click="previousTab()"
                        class="btn btn-light border mr-2 col-md-6 col-lg-auto">
                    <font-awesome-icon icon="arrow-alt-circle-left" /> Anterior
                </button>
                <button type="submit"
                        class="btn btn-success col-md-6 col-lg-auto">
                <font-awesome-icon icon="arrow-alt-circle-right" /> Siguiente
                </button>
            </div>
        </div>
    </form>
</template>
<script>
import { mapGetters } from "vuex";
import { isObjectEmpty} from '@/utils/filters';
import AppSelectPais from "@/components/AppSelectPais";
import AppSelectEstado from "@/components/AppSelectEstado";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
    name: "TabViewUbicaciones",
    components: { AppSelectPais, AppSelectEstado, VueBootstrapTypeaheadCustom },
    data() {
        return {
            sltRegimenAduanero:null,
            regimenesAduaneros: [],
            disabledOrigenMunicipio:true,
            disabledOrigenEstado:true,
            disabledOrigenColonia:true,
            errorOrigenColonia:"",
            disabledDestinoMunicipio:true,
            disabledDestinoEstado:true,
            disabledDestinoColonia:true,
            errorDestinoColonia:"",
            validations: {
                rfc_remitente_valid: false,
            },

            listRegimenesAduanero:[
                {clave:"IMD", descripcion:"Definitivo de importación.", impoExpo:1},
                {clave:"EXD", descripcion:"Definitivo de exportación.", impoExpo:0},
                {clave:"ITR", descripcion:"Temporales de importación para retomar al extranjero en el mismo estado.", impoExpo:1},
                {clave:"ITE", descripcion:"Temporales de importación para elaboración, transformación o reparación para empresas con programa IMMEX.", impoExpo:1},
                {clave:"ETR", descripcion:"Temporales de exportación para retornar al país en el mismo estado.", impoExpo:0	},
                {clave:"ETE", descripcion:"Temporales de exportación para elaboración, transformación o reparación.", impoExpo:0},
                {clave:"DFI", descripcion:"Depósito Fiscal.", impoExpo:2},
                {clave:"RFE", descripcion:"Elaboración, transformación o reparación en recinto fiscalizado.", impoExpo:2},
                {clave:"RFS", descripcion:"Recinto fiscalizado estratégico.", impoExpo:2},
                {clave:"TRA", descripcion:"Tránsitos.", impoExpo:2},
            ],

            tipoTrasporte:{
                transp_internac: "No",
                entrada_salida_merc: null,
                pais_origen_destino: null,
                regimen_aduanero: null,
                regimenes_aduaneros: [],
            },

            selectedResidenciaFiscalOrigen: {
                pais: "",
                pais_descripcion: "",
            },

            selectedPaisOrigen: {
                pais: "MEX",
                pais_descripcion: "México",
            },

            selectedEstadoOrigen:{
                estado: "",
                estado_descripcion: "",
            },

            ubicacion_origen: {
                id_origen: null,
                rfc_remitente: "",
                nombre_remitente: "",
                num_reg_id_trib: null,
                residencia_fiscal: null,
                residencia_fiscal_descripcion: "",
                fechahora_salida: null,
                fecha: "",
                hora: "",
                domicilio: {
                    calle: "",
                    numero_exterior: "",
                    numero_interior: null,
                    colonia: "",
                    referencia: "",
                    municipio: "",
                    municipio_descripcion: "",
                    estado: "",
                    estado_descripcion: "",
                    pais: "MEX",
                    pais_descripcion: "México",
                    codigo_postal: "",
                },

                list_paises: [],
                list_colonias: [],
            },

            selectedResidenciaFiscalDestino: {
                pais: "",
                pais_descripcion: "",
            },

            selectedPaisDestino: {
                pais: "MEX",
                pais_descripcion: "México",
            },

            selectedEstadoDestino:{
                estado: "",
                estado_descripcion: "",
            },

            ubicacion_destino: {
                distancia_recorrida: "0",
                id_destino: null,
                rfc_destinatario: "",
                nombre_destinatario: "",
                num_reg_id_trib: null,
                residencia_fiscal: null,
                residencia_fiscal_descripcion: "",
                fechahora_prog_llegada: null,
                fecha: "",
                hora: "",
                domicilio: {
                    calle: "",
                    numero_exterior: "",
                    numero_interior: null,
                    colonia: "",
                    referencia: "",
                    municipio: "",
                    municipio_descripcion: "",
                    estado: "",
                    estado_descripcion: "",
                    pais: "MEX",
                    pais_descripcion: "México",
                    codigo_postal: "",
                },

                list_paises: [],
                list_colonias: [],
            },
        };
    },
    mounted() {
        this.setDatesDefault()
        this.loadLocalData();
    },
    watch: {
        "selectedResidenciaFiscalOrigen.pais"(value) {
            this.ubicacion_origen.residencia_fiscal = this.selectedResidenciaFiscalOrigen.pais;
            this.ubicacion_origen.residencia_fiscal_descripcion = this.selectedResidenciaFiscalOrigen.pais_descripcion;

           /*  setTimeout(() => {
                this.$refs.pais_origen_descripcion.setFocus();
            }, 200); */
        },


        "selectedPaisOrigen.pais"(value) {
            this.ubicacion_origen.domicilio.pais = this.selectedPaisOrigen.pais;
            this.ubicacion_origen.domicilio.pais_descripcion = this.selectedPaisOrigen.pais_descripcion;
            if(value != "MEX"){
                this.disabledOrigenEstado = false;
                this.disabledOrigenMunicipio = false;
                this.disabledOrigenColonia = true;
               // this.ubicacion_origen.domicilio.colonia = "";
            }else{
                this.disabledOrigenEstado = true;
                this.disabledOrigenMunicipio = true;
            }

            this.ubicacion_origen.domicilio.colonia = "";

            if(value != ""){
                setTimeout(() => {
                    this.$refs.ubicacion_origen_cp.focus();
                }, 200);
            }
            
        },

        "selectedEstadoOrigen.estado"(value) {
            this.ubicacion_origen.domicilio.estado = this.selectedEstadoOrigen.estado;
            this.ubicacion_origen.domicilio.estado_descripcion =
                this.selectedEstadoOrigen.estado_descripcion;

            if(value != ""){
                setTimeout(() => {
                    this.$refs.ubicacion_origen_municipio.focus();
                }, 200);
            }
        },

        "selectedResidenciaFiscalDestino.pais"(value) {
            this.ubicacion_destino.residencia_fiscal = this.selectedResidenciaFiscalDestino.pais;
            this.ubicacion_destino.residencia_fiscal_descripcion = this.selectedResidenciaFiscalDestino.pais_descripcion;

            /* setTimeout(() => {
                this.$refs.pais_destino_descripcion.setFocus();
            }, 200); */
        },

        "selectedPaisDestino.pais"(pais) {
            this.ubicacion_destino.domicilio.pais = this.selectedPaisDestino.pais;
            this.ubicacion_destino.domicilio.pais_descripcion = this.selectedPaisDestino.pais_descripcion;

            if(pais != "MEX"){
                this.disabledDestinoEstado = false;
                this.disabledDestinoMunicipio = false;
                this.disabledDestinoColonia = true;
              //  this.ubicacion_destino.domicilio.colonia = "";
            }else{
                this.disabledDestinoEstado = true;
                this.disabledDestinoMunicipio = true;
            }

            this.ubicacion_destino.domicilio.colonia = ""

            if(value != ""){
                setTimeout(() => {
                    this.$refs.ubicacion_destino_cp.focus();
                }, 200);
            }
        },

        "selectedEstadoDestino.estado"(value) {
            this.ubicacion_destino.domicilio.estado = this.selectedEstadoDestino.estado;
            this.ubicacion_destino.domicilio.estado_descripcion =
                this.selectedEstadoDestino.estado_descripcion;

            if(value != ""){
                setTimeout(() => {
                    this.$refs.ubicacion_destino_municipio.focus();
                }, 200);
            }
        },


        "ubicacion_origen.domicilio.codigo_postal"(cp) {
            this.errorOrigenColonia = "";

            if(this.ubicacion_origen.domicilio.pais != "MEX") return // si el pais es diferente de mexico no hacer busqueda de codigo postal
            
            if (cp && cp.length == 5) {
                this.$store
                    .dispatch("catalogos/fetchDataCodigoPostal",cp)
                    .then(() => {

                        this.selectedEstadoOrigen.estado =
                            this.dataCodigoPostal.estado;
                        this.selectedEstadoOrigen.estado_descripcion =
                            this.dataCodigoPostal.estado_desc;

                        this.ubicacion_origen.domicilio.estado =
                            this.dataCodigoPostal.estado;
                        this.ubicacion_origen.domicilio.estado_descripcion =
                            this.dataCodigoPostal.estado_desc;
                        this.ubicacion_origen.domicilio.municipio =
                            this.dataCodigoPostal.municipio;
                        this.ubicacion_origen.domicilio.municipio_descripcion =
                            this.dataCodigoPostal.municipio_desc;
                        this.ubicacion_origen.list_colonias =
                            this.dataCodigoPostal.colonias;
                        if (this.ubicacion_origen.list_colonias.length == 1) {
                            this.ubicacion_origen.domicilio.colonia =
                                this.dataCodigoPostal.colonias[0].colonia;
                        }

                        this.disabledOrigenColonia = false;
                    }).catch((err)=>{
                        this.errorOrigenColonia = err.data.msg
                    });
            }else{
                this.disabledOrigenColonia = true;
                this.ubicacion_origen.domicilio.colonia = "";
                this.ubicacion_origen.domicilio.estado = "";
                this.ubicacion_origen.domicilio.estado_descripcion = "";
                this.ubicacion_origen.domicilio.municipio = "";
                this.ubicacion_origen.domicilio.municipio_descripcion = "";
            }

        },
        "ubicacion_destino.domicilio.codigo_postal"(cp) {
            
            this.errorDestinoColonia = "";
            if(this.ubicacion_destino.domicilio.pais != "MEX") return // si el pais es diferente de mexico no hacer busqueda de codigo postal

            if (cp && cp.length == 5) {
                this.$store
                    .dispatch("catalogos/fetchDataCodigoPostal",cp)
                    .then(() => {
                        this.selectedEstadoDestino.estado =
                            this.dataCodigoPostal.estado;
                        this.selectedEstadoDestino.estado_descripcion =
                            this.dataCodigoPostal.estado_desc;

                        this.ubicacion_destino.domicilio.estado =
                            this.dataCodigoPostal.estado;
                        this.ubicacion_destino.domicilio.estado_descripcion =
                            this.dataCodigoPostal.estado_desc;
                        this.ubicacion_destino.domicilio.municipio =
                            this.dataCodigoPostal.municipio;
                        this.ubicacion_destino.domicilio.municipio_descripcion =
                            this.dataCodigoPostal.municipio_desc;
                        this.ubicacion_destino.list_colonias =
                            this.dataCodigoPostal.colonias;
                        if (this.ubicacion_destino.list_colonias.length == 1) {
                            this.ubicacion_destino.domicilio.colonia =
                                this.dataCodigoPostal.colonias[0].colonia;
                        }
                    
                        this.disabledDestinoColonia = false;
                    }).catch((err)=>{
                        this.errorDestinoColonia = err.data.msg
                    });
            }else{
                this.disabledDestinoColonia = true;
                this.ubicacion_destino.domicilio.colonia = "";
                this.ubicacion_destino.domicilio.estado = "";
                this.ubicacion_destino.domicilio.estado_descripcion = "";
                this.ubicacion_destino.domicilio.municipio = "";
                this.ubicacion_destino.domicilio.municipio_descripcion = "";
            }
        },
    },
    computed: {
        ...mapGetters({
            //listPaises: "catalogos/listPaises",
            listEstados: "catalogos/listEstados",
            dataCodigoPostal: "catalogos/getDataCodigoPostal",
            version: "carta_porte/getVersion",
            getUbicaciones: "carta_porte/getUbicaciones",
            getTipoTrasporte: "carta_porte/getTipoTrasporte"
        }),

        listFilterRegimenesAduanero(){
            let list = [];
            if(this.ubicacion_origen.domicilio.pais == "MEX" && this.ubicacion_destino.domicilio.pais != "MEX"){ // si es exportacion
                list = this.listRegimenesAduanero.filter((item) => item.impoExpo == 0 || item.impoExpo == 2);

            }else if(this.ubicacion_origen.domicilio.pais != "MEX" && this.ubicacion_destino.domicilio.pais == "MEX"){ // si es importacion
                list = this.listRegimenesAduanero.filter((item) => item.impoExpo == 1 || item.impoExpo == 2);
            }

            return list;
        },

        showRegimenAduanero(){
            //if(this.version == "30"){
                if(this.ubicacion_origen.domicilio.pais == "MEX" && this.ubicacion_destino.domicilio.pais == "MEX"){ // si es nacional 
                    return false;

                }else if(this.ubicacion_origen.domicilio.pais == "MEX" && this.ubicacion_destino.domicilio.pais != "MEX"){ // si es exportacion
                    return true;

                }else if(this.ubicacion_origen.domicilio.pais != "MEX" && this.ubicacion_destino.domicilio.pais == "MEX"){ // si es importacion
                    return true;
                }
           // }
            
        }
    },
    methods: {

        getDescRegimenAduanero(clave) {
            let regimAdu = this.listRegimenesAduanero.find((item) => item.clave == clave )

            if(regimAdu){
                return regimAdu.descripcion
            }          
        },

        changeSelectRegimenes(e) {
            let regimAdu = this.listRegimenesAduanero.find((item) => item.clave == e.target.value )
            let alredyInList = this.regimenesAduaneros.find((item) => item == e.target.value )
            if(regimAdu && !alredyInList){
                this.regimenesAduaneros.push(regimAdu.clave)
            }  
            this.sltRegimenAduanero = null         
        },

        removeRegimen(clave) {
            let regimenIndex = this.regimenesAduaneros.findIndex((item) => item == clave )    
            this.regimenesAduaneros.splice(regimenIndex, 1);
        },

        loadLocalData() {

            if(this.getUbicaciones.origenes[0].fechahora_salida){
                let origen_fechaHora = this.getUbicaciones.origenes[0].fechahora_salida.split("T")

                this.ubicacion_origen.fecha = origen_fechaHora[0];
                this.ubicacion_origen.hora = origen_fechaHora[1];
            }

            this.ubicacion_origen.rfc_remitente = this.getUbicaciones.origenes[0].rfc_remitente
            this.ubicacion_origen.nombre_remitente = this.getUbicaciones.origenes[0].nombre_remitente
            this.ubicacion_origen.num_reg_id_trib = this.getUbicaciones.origenes[0].num_reg_id_trib

            this.selectedResidenciaFiscalOrigen.pais = this.getUbicaciones.origenes[0].residencia_fiscal
            this.selectedResidenciaFiscalOrigen.pais_descripcion = this.getUbicaciones.origenes[0].residencia_fiscal_descripcion
            this.ubicacion_origen.residencia_fiscal = this.getUbicaciones.origenes[0].residencia_fiscal
            
            
            if(!isObjectEmpty(this.getUbicaciones.origenes[0].domicilio)){
                this.ubicacion_origen.domicilio = this.getUbicaciones.origenes[0].domicilio  

                this.selectedPaisOrigen.pais = this.getUbicaciones.origenes[0].domicilio.pais
                this.selectedPaisOrigen.pais_descripcion = this.getUbicaciones.origenes[0].domicilio.pais_descripcion

                this.selectedEstadoOrigen.estado = this.getUbicaciones.origenes[0].domicilio.estado
                this.selectedEstadoOrigen.estado_descripcion = this.getUbicaciones.origenes[0].domicilio.estado_descripcion  
            } 

            if(this.getUbicaciones.destinos[0].fechahora_prog_llegada){
                let destino_fechaHora = this.getUbicaciones.destinos[0].fechahora_prog_llegada.split("T")

                this.ubicacion_destino.fecha = destino_fechaHora[0]
                this.ubicacion_destino.hora = destino_fechaHora[1]
            }     

            this.ubicacion_destino.rfc_destinatario = this.getUbicaciones.destinos[0].rfc_destinatario
            this.ubicacion_destino.nombre_destinatario = this.getUbicaciones.destinos[0].nombre_destinatario
            this.ubicacion_destino.num_reg_id_trib = this.getUbicaciones.destinos[0].num_reg_id_trib

            this.selectedResidenciaFiscalDestino.pais = this.getUbicaciones.destinos[0].residencia_fiscal
            this.selectedResidenciaFiscalDestino.pais_descripcion = this.getUbicaciones.destinos[0].residencia_fiscal_descripcion
            this.ubicacion_destino.residencia_fiscal = this.getUbicaciones.destinos[0].residencia_fiscal

            if(!isObjectEmpty(this.getUbicaciones.destinos[0].domicilio)){
                this.ubicacion_destino.domicilio = this.getUbicaciones.destinos[0].domicilio

                this.selectedPaisDestino.pais = this.getUbicaciones.destinos[0].domicilio.pais
                this.selectedPaisDestino.pais_descripcion = this.getUbicaciones.destinos[0].domicilio.pais_descripcion

                this.selectedEstadoDestino.estado = this.getUbicaciones.destinos[0].domicilio.estado
                this.selectedEstadoDestino.estado_descripcion = this.getUbicaciones.destinos[0].domicilio.estado_descripcion   
            }
  
            this.tipoTrasporte.regimen_aduanero= this.getTipoTrasporte.regimen_aduanero  

            if(this.getTipoTrasporte.regimenes_aduaneros != null){
                this.regimenesAduaneros = this.getTipoTrasporte.regimenes_aduaneros 
            }else{
                this.regimenesAduaneros = [];
            }
            
        },

        setDatesDefault() {
            // Calcular la fecha para mostrarla por default
            let now = new Date()
            let formatToday = now.getFullYear().toString() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate().toString().padStart(2, "0");

            // Solamente setear la fecha si esta vacia
            if (!this.ubicacion_origen.fecha) {
                this.ubicacion_origen.fecha = formatToday
            }
            if (!this.ubicacion_destino.fecha) {
                this.ubicacion_destino.fecha = formatToday
            }
            // Setear hora si no est definida
            if (!this.ubicacion_origen.hora) {
                this.ubicacion_origen.hora = now.getHours().toString().padStart(2, "0") + ":00:00";
            }
            // Setear hora + 1 si no est definida
            if (!this.ubicacion_destino.hora) {
                this.ubicacion_destino.hora =(now.getHours() + 1).toString().padStart(2, "0") + ":00:00";
            }
        },

        rfcOrigenToUpperCase(){
            if(this.ubicacion_origen.rfc_remitente != null )
                this.ubicacion_origen.rfc_remitente = this.ubicacion_origen.rfc_remitente.toUpperCase()
        },

        rfcDestinoToUpperCase(){
            if(this.ubicacion_destino.rfc_destinatario != null)
                this.ubicacion_destino.rfc_destinatario = this.ubicacion_destino.rfc_destinatario.toUpperCase()
        },
        /* searchPais(query) {
            this.$store.dispatch("catalogos/fetchPaises", query);
        }, */
        
        /* searchEstado(query) {
            //hacer busqueda de estados solo cuando pais sea USA o CANADA, (cuando pais es mexico se asigna aumaticammnete mediante cp)
            if(this.ubicacion_destino.domicilio.pais == "USA" || this.ubicacion_destino.domicilio.pais == "CAN" || this.ubicacion_origen.domicilio.pais == "USA" || this.ubicacion_origen.domicilio.pais == "CAN" ){
                this.$store.dispatch("catalogos/fetchEstados", {search:query.search, country: query.country});
            }
        }, */
/* 
        setSelectedOrigenResidenciaFiscal(pais) {
            this.ubicacion_origen.residencia_fiscal = pais.clave;
            this.ubicacion_origen.residencia_fiscal_descripcion = pais.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setPaisesEmpty");
                this.$refs.pais_origen_descripcion.setFocus();
            }, 200);
        }, */

        /* setSelectedOrigenPais(pais) {
            this.ubicacion_origen.domicilio.pais = pais.clave;
            this.ubicacion_origen.domicilio.pais_descripcion = pais.descripcion;

            this.ubicacion_origen.domicilio.colonia = "";

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setPaisesEmpty");
                this.$refs.ubicacion_origen_cp.focus();
            }, 200);
        },
 */
       /*  setSelectedOrigenEstado(estado) {
            this.ubicacion_origen.domicilio.estado = estado.estado;
            this.ubicacion_origen.domicilio.estado_descripcion =
                estado.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setEstadosEmpty");
                this.$refs.ubicacion_origen_municipio.focus();
            }, 200);
        }, */

      /*   setSelectedDestinoResidenciaFiscal(pais) {
            this.ubicacion_destino.residencia_fiscal = pais.clave;
            this.ubicacion_destino.residencia_fiscal_descripcion = pais.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setPaisesEmpty");
                this.$refs.pais_destino_descripcion.setFocus();
            }, 200);
        }, */

      /*   setSelectedDestinoPais(pais) {
            this.ubicacion_destino.domicilio.pais = pais.clave;
            this.ubicacion_destino.domicilio.pais_descripcion =
                pais.descripcion;

            this.ubicacion_destino.domicilio.colonia = ""

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setPaisesEmpty");
                this.$refs.ubicacion_destino_cp.focus();
            }, 200);
        }, */

       /*  setSelectedDestinoEstado(estado) {
            this.ubicacion_destino.domicilio.estado = estado.estado;
            this.ubicacion_destino.domicilio.estado_descripcion =
                estado.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setEstadosEmpty");
                this.$refs.ubicacion_destino_municipio.focus();
            }, 200);
        }, */

        setNullEmptyString(obj) {
            // Si hay objetos string vacios, asignarles valor null
            for (const [key, value] of Object.entries(obj)) {
                if (typeof value == "string") {
                    if (value == "") {
                        obj[key] = null;
                    }
                }
            }
        },

        validateForm() {
            //TODO: poder agregar clase is-invalid a componente typeahead por medio de propiedad class
            for (let input in this.$refs) {
                if (this.$refs[input].value == "") {
                    this.validations[input] = true;
                }
            }
        },

        isRFC(rfc){
            let expresion = "/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/";
            return (rfc).match(expresion);
        },

        previousTab(){
            this.$emit("previousTab");
        },

        /*async save() {

            // this.validateForm() TODO: hacer valiaciones a los inputs
            // Concatenar la fecha y hora de salida
            this.ubicacion_origen.fechahora_salida =
                this.ubicacion_origen.fecha + "T" + this.ubicacion_origen.hora;
            // Concatenar la fecha y hora de llegada
            this.ubicacion_destino.fechahora_prog_llegada =
                this.ubicacion_destino.fecha + "T" + this.ubicacion_destino.hora;
            // Si hay objetos string vacios, asignarles valor null
            this.setNullEmptyString(this.ubicacion_origen);
            this.setNullEmptyString(this.ubicacion_origen.domicilio);
            this.setNullEmptyString(this.ubicacion_destino);
            this.setNullEmptyString(this.ubicacion_destino.domicilio);
            await this.$store.dispatch("carta_porte/setUbicacionesOrigenes", this.ubicacion_origen);
            await this.$store.dispatch("carta_porte/setUbicacionesDestinos", this.ubicacion_destino);
            this.$emit("nextTab");
        },*/

        nextStep(){
            this.$emit("nextTab");
        },

        async saveTab() {
            this.tipoTrasporte.regimenes_aduaneros = this.regimenesAduaneros;

            if(this.ubicacion_origen.rfc_remitente !='XEXX010101000'){
                this.ubicacion_origen.num_reg_id_trib = null;
                this.ubicacion_origen.residencia_fiscal = null;
                this.ubicacion_origen.residencia_fiscal_descripcion = "";
            }

            if(this.ubicacion_destino.rfc_destinatario !='XEXX010101000'){
                this.ubicacion_destino.num_reg_id_trib = null;
                this.ubicacion_destino.residencia_fiscal = null;
                this.ubicacion_destino.residencia_fiscal_descripcion = "";
            }

            if(this.ubicacion_origen.domicilio.pais == "MEX" && this.ubicacion_destino.domicilio.pais == "MEX"){ // si es nacional 
                this.tipoTrasporte.transp_internac = "No";
                this.tipoTrasporte.entrada_salida_merc = null;
                this.tipoTrasporte.pais_origen_destino = null;
                this.tipoTrasporte.regimen_aduanero = null;
                this.tipoTrasporte.regimenes_aduaneros = null;

            }else if(this.ubicacion_origen.domicilio.pais == "MEX" && this.ubicacion_destino.domicilio.pais != "MEX"){ // si es exportacion
                this.tipoTrasporte.transp_internac = "Sí";
                this.tipoTrasporte.entrada_salida_merc = "Salida";
                this.tipoTrasporte.pais_origen_destino = this.ubicacion_destino.domicilio.pais;

            }else if(this.ubicacion_origen.domicilio.pais != "MEX" && this.ubicacion_destino.domicilio.pais == "MEX"){ // si es importacion
                this.tipoTrasporte.transp_internac = "Sí";
                this.tipoTrasporte.entrada_salida_merc = "Entrada";
                this.tipoTrasporte.pais_origen_destino = this.ubicacion_origen.domicilio.pais;

            }else if(this.ubicacion_origen.domicilio.pais != "MEX" && this.ubicacion_destino.domicilio.pais != "MEX"){ 
                this.tipoTrasporte.transp_internac = "No";
                this.tipoTrasporte.entrada_salida_merc = null;
                this.tipoTrasporte.pais_origen_destino = null;
                this.tipoTrasporte.regimen_aduanero = null;
                this.tipoTrasporte.regimenes_aduaneros = null;
            }

            // Si pais es diferente de MEX, USA o CAD, el campo estado es texto libre , (cuando pais es mexico se asigna el estado aumaticammnete mediante cp)
            if(this.ubicacion_destino.domicilio.pais != "MEX" && this.ubicacion_destino.domicilio.pais != "USA" && this.ubicacion_destino.domicilio.pais != "CAN"){
                this.ubicacion_destino.domicilio.estado =  this.ubicacion_destino.domicilio.estado_descripcion;
            }

            if(this.ubicacion_origen.domicilio.pais != "MEX" && this.ubicacion_origen.domicilio.pais != "USA" && this.ubicacion_origen.domicilio.pais != "CAN"){
                this.ubicacion_origen.domicilio.estado =  this.ubicacion_origen.domicilio.estado_descripcion;
            }

            // si pais es diferente de MEX el campo municipio es libre, (cuando pais es mexico se asigna el municipio aumaticammnete mediante cp)
            if(this.ubicacion_origen.domicilio.pais != "MEX" ){
                this.ubicacion_origen.domicilio.municipio =  this.ubicacion_origen.domicilio.municipio_descripcion;
            }

            if(this.ubicacion_destino.domicilio.pais != "MEX" ){
                this.ubicacion_destino.domicilio.municipio =  this.ubicacion_destino.domicilio.municipio_descripcion;
                
            }

            // this.validateForm() TODO: hacer valiaciones a los inputs
            // Concatenar la fecha y hora de salida
            this.ubicacion_origen.fechahora_salida =
                this.ubicacion_origen.fecha + "T" + this.ubicacion_origen.hora;
            // Concatenar la fecha y hora de llegada
            this.ubicacion_destino.fechahora_prog_llegada =
                this.ubicacion_destino.fecha + "T" + this.ubicacion_destino.hora;
            // Si hay objetos string vacios, asignarles valor null
            this.setNullEmptyString(this.ubicacion_origen);
            this.setNullEmptyString(this.ubicacion_origen.domicilio);
            this.setNullEmptyString(this.ubicacion_destino);
            this.setNullEmptyString(this.ubicacion_destino.domicilio);

            await this.$store.dispatch("carta_porte/saveTipoTransporte", this.tipoTrasporte);
            await this.$store.dispatch("carta_porte/setUbicacionesOrigenes", this.ubicacion_origen);
            await this.$store.dispatch("carta_porte/setUbicacionesDestinos", this.ubicacion_destino);
        },

        confirmDeleteSection() {
            this.$bvModal.msgBoxConfirm('¿Esta seguro(a) de limpiar/borrar todos los datos de esta sección?', {
                title: 'Limpiar Origen y Destino',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Limpiar Origen y Destino',
                cancelTitle: 'Cancelar',
                cancelVariant: 'light border',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
              if(value){
                
                this.resetView()
                this.saveTab()
              }
            })
            .catch(err => {
                // An error occurred
            })
        },

        resetView() {
            this.disabledOrigenMunicipio = true;
            this.disabledOrigenEstado = true;
            this.disabledOrigenColonia = true;
            this.disabledDestinoMunicipio = true;
            this.disabledDestinoEstado = true;
            this.disabledDestinoColonia = true;

            this.validations.rfc_remitente_valid = false;

            this.tipoTrasporte.transp_internac = "No"
            this.tipoTrasporte.entrada_salida_merc = null
            this.tipoTrasporte.pais_origen_destino = null
            this.tipoTrasporte.regimen_aduanero = null;
            this.tipoTrasporte.regimenes_aduaneros = null
           
            this.selectedResidenciaFiscalOrigen.pais = ""
            this.selectedResidenciaFiscalOrigen.pais_descripcion = ""

            this.selectedPaisOrigen.pais= "MEX"
            this.selectedPaisOrigen.pais_descripcion= "México"

            this.selectedEstadoOrigen.estado = ""
            this.selectedEstadoOrigen.estado_descripcion= ""

            this.ubicacion_origen.id_origen= null
            this.ubicacion_origen.rfc_remitente=  ""
            this.ubicacion_origen.nombre_remitente=  ""
            this.ubicacion_origen.num_reg_id_trib=  null
            this.ubicacion_origen.residencia_fiscal=  null
            this.ubicacion_origen.residencia_fiscal_descripcion=  ""
            this.ubicacion_origen.fechahora_salida=  null
            this.ubicacion_origen.fecha=  ""
            this.ubicacion_origen.hora=  ""
            this.ubicacion_origen.domicilio.calle=  ""
            this.ubicacion_origen.domicilio.numero_exterior= ""
            this.ubicacion_origen.domicilio.numero_interior= null
            this.ubicacion_origen.domicilio.colonia= ""
            this.ubicacion_origen.domicilio.referencia= ""
            this.ubicacion_origen.domicilio.municipio= ""
            this.ubicacion_origen.domicilio.municipio_descripcion= ""
            this.ubicacion_origen.domicilio.estado= ""
            this.ubicacion_origen.domicilio.estado_descripcion= ""
            this.ubicacion_origen.domicilio.pais= "MEX"
            this.ubicacion_origen.domicilio.pais_descripcion= "México"
            this.ubicacion_origen.domicilio.codigo_postal= ""
                

            this.ubicacion_origen.list_paises= []
            this.ubicacion_origen.list_colonias= []

            this.selectedResidenciaFiscalDestino.pais = "MEX"
            this.selectedResidenciaFiscalDestino.pais_descripcion = "México"
            
            this.selectedPaisDestino.pais= "MEX"
            this.selectedPaisDestino.pais_descripcion= "México"

            this.selectedEstadoDestino.estado= ""
            this.selectedEstadoDestino.estado_descripcion= ""

            this.ubicacion_destino.distancia_recorrida= "0"
            this.ubicacion_destino.id_destino= null
            this.ubicacion_destino.rfc_destinatario= ""
            this.ubicacion_destino.nombre_destinatario= ""
            this.ubicacion_destino.num_reg_id_trib= null
            this.ubicacion_destino.residencia_fiscal= null
            this.ubicacion_destino.residencia_fiscal_descripcion= ""
            this.ubicacion_destino.fechahora_prog_llegada= null
            this.ubicacion_destino.fecha= ""
            this.ubicacion_destino.hora= ""
            this.ubicacion_destino.domicilio.calle= ""
            this.ubicacion_destino.domicilio.numero_exterior= ""
            this.ubicacion_destino.domicilio.numero_interior= null
            this.ubicacion_destino.domicilio.colonia= ""
            this.ubicacion_destino.domicilio.referencia= ""
            this.ubicacion_destino.domicilio.municipio= ""
            this.ubicacion_destino.domicilio.municipio_descripcion= ""
            this.ubicacion_destino.domicilio.estado= ""
            this.ubicacion_destino.domicilio.estado_descripcion= ""
            this.ubicacion_destino.domicilio.pais= "MEX"
            this.ubicacion_destino.domicilio.pais_descripcion= "México"
            this.ubicacion_destino.domicilio.codigo_postal= ""
                

            this.ubicacion_destino.list_paises= []
            this.ubicacion_destino.list_colonias= []
            
            this.setDatesDefault();
        },
    },
};
</script>