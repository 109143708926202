<template>
  <div class="p-4">

    <div class="row pb-2">
          <div class="AjustesTitulo col-6">Configuracion de Servicios</div>
          <div class="col-6"><button class="btn btn-success float-right">Guardar</button></div>
    </div>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab title="Flete" active>
            <Flete />
          </b-tab>
          <b-tab title="Seguros">
            <Seguros />
          </b-tab>
          <b-tab title="Maniobras">
            <Maniobras />
          </b-tab>
          <b-tab title="Otros">
            <Otros />
          </b-tab>
          <b-tab title="No Definidos">
            <NoDefinidos />
          </b-tab>

          <!-- Render Tabs, supply a unique `key` to each tab -->
          <b-tab v-for="i in tabs" :key="'dyn-tab-' + i" :title="'Servicio #' + i">
            Servicio #{{ i }}
            <b-button
              size="sm"
              class="float-right btn btn-danger"
              @click="closeTab(i)"
            >
              Cerrar ventanada
            </b-button>
            <ServicioNuevo />
          </b-tab>

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-end>
            <b-nav-item role="presentation" @click.prevent="newTab" href="#">
              Agregar <b>+</b>
            </b-nav-item>
          </template>

          <!-- Render this if no tabs -->
          <template #empty> No hay nada</template>
        </b-tabs>
      </b-card>

  </div>
</template>

<script>
import Flete from "@/views/ajustes/flete/View.vue";
import Maniobras from "@/views/ajustes/maniobras/View.vue";
import Otros from "@/views/ajustes/otros/View.vue";
import Seguros from "@/views/ajustes/seguros/View.vue";
import NoDefinidos from "@/views/ajustes/noDefinidos/View.vue";
import ServicioNuevo from "@/views/ajustes/servicioNuevo/View.vue";

export default {
  name: "AjustesCartaPorte",
  components: {
    Flete,
    Maniobras,
    Otros,
    Seguros,
    NoDefinidos,
    ServicioNuevo,
  },

  data() {
    return {
      tabs: [],
      tabCounter: 0,
    };
  },
  methods: {
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i] === x) {
          this.tabs.splice(i, 1);
        }
      }
    },
    newTab() {
      this.tabs.push(this.tabCounter++);
    },
  },
};
</script>