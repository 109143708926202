export const cookie = {

    setToken(value) {
        let token;
        token = JSON.stringify(value);
        try {
            document.cookie =
               "f123-cookie-token" + "=" + token + "; path=/;domain=.factura123.mx;";
        } catch (e) {
            throw new Error(
                "Error al tratar de guardar cookies " + e.toString()
            );
        }
    },

    getToken() {
        var cookiedata = null;

        try {
            cookiedata = JSON.parse(cookie.getCookie("f123-cookie-token"));
        } catch (e) {
            throw new Error(
                "Error al tratar de obtener cookie " + key + ", " + e.toString()
            );
        }

        return cookiedata;
    },

    getCookie(nameCookie) {
        let nameEQ = nameCookie + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    remove() {
        document.cookie =
            "f123-cookie-token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
};
