<template>
    <form @submit.prevent="nextStep()" @keydown.enter.prevent>
        <div class="row">
            <div class="col-lg-6 col-md-12 mb-2">
                <div class="mb-2">Permiso SCT*</div>
                <div class="form-row">
                    <div class="col-6">
                        <select
                            class="custom-select custom-select-sm mb-2"
                            v-model="autotransporte_federal.perm_sct"
                            required
                        >
                            <option selected value="">
                                Seleccione Tipo de Permiso
                            </option>
                            <option
                                v-for="item in listClavesPermisoSCT"
                                :key="item.clave"
                                :value="item.clave"
                            >
                                {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                    <div class="col-6">
                        <input
                            ref="num_permiso_sct"
                            v-model.trim="autotransporte_federal.num_permiso_sct"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Numero"
                            required
                        />
                    </div>
                </div>
                <br>
                <div class="mb-2">Seguro de Responsabilidad Civil*</div>
                <div class="form-row">
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.nombre_aseg"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Nombre Aseguradora"
                            required
                        />
                    </div>
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.num_poliza_seguro"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="N° Poliza"
                            required
                        />
                    </div>
                </div>
                <div class="mb-2">Seguro de Medio Ambiente</div>
                <div class="form-row">
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.asegura_med_ambiente"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Nombre Aseguradora"
                        />
                    </div>
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.poliza_med_ambiente"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="N° Poliza"
                        />
                    </div>
                </div>
                <div class="mb-2">Seguro de Carga</div>
                <div class="form-row">
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.asegura_carga"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Nombre Aseguradora"
                        />
                    </div>
                    <div class="col-6">
                        <input
                            v-model.trim="seguros.poliza_carga"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="N° Poliza"
                        />
                    </div>
                </div>
                <br />
                <div class="mb-2">Operador*</div>
                <div class="form-row">
                    <div class="col-6">
                        <input
                            v-model.trim="figura_transporte_operador.rfc_operador"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="RFC"
                            maxlength="13"
                            required
                        />
                    </div>
                    <div class="col-6">
                        <input
                            v-model.trim="figura_transporte_operador.nombre_operador"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Nombre"
                            required
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <input
                            v-model.trim="figura_transporte_operador.num_licencia"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Licencia"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-row">
                    <div class="col-12">
                        <label for="">Distancia recorrida <small>(Km.)</small>*</label>
                        <input v-model="ubicacion_destino.distancia_recorrida"
                            type="number"
                            class="form-control form-control-sm mb-2"
                            placeholder="KM"
                            step="0.01"
                            min="0"
                            required />
                    </div>
                    <div class="col-12">
                        <label for="">Peso bruto vehicular <small>(Toneladas)</small>*</label>
                        <input v-model="autotransporte_federal.identificacion_vehicular.pesobruto_vehicular "
                            type="number"
                            class="form-control form-control-sm mb-2"
                            placeholder="Toneladas"
                            step="0.01"
                            min="0"
                            required />
                            <small class="form-text text-muted">Suma del peso vehicular y el peso de la carga.</small>
                    </div>
                </div>         
                <br>       
                <div class="mb-2">
                    Camión <small>(identificador vehicular)</small>*
                </div>
                <div class="form-row">
                    <div class="col">
                        <select
                            class="custom-select custom-select-sm mb-2"
                           @change="changeConfigAutotransporte()" 
                            v-model="selectedConfigAutotransporte"
                            required
                        >
                            <option selected value="">
                                Seleccione Configuración
                            </option>
                            <option
                                v-for="item in listClavesConfigAutotransporte"
                                :key="item.clave"
                                :value="{clave:item.clave, remolque: item.remolque}"
                            >
                                {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-6">
                        <input
                            ref="placa_vm"
                            v-model.trim="
                                autotransporte_federal.identificacion_vehicular
                                    .placa_vm
                            "
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Placa"
                            required
                        />
                    </div>
                    <div class="col-6">
                        <input
                            v-model.trim="
                                autotransporte_federal.identificacion_vehicular
                                    .anio_modelo_vm
                            "
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Año Modelo"
                            required
                        />
                    </div>
                </div>
                <br />
                <div class="mb-2">
                    <span>Remolques</span> &nbsp;
                    <div class="form-check form-check-inline">
                        <input
                            v-model="radioNumRemolques"
                            class="form-check-input"
                            type="radio"
                            value="0"
                            :disabled="radio0Disabled"
                        />
                        <label class="form-check-label">0</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            v-model="radioNumRemolques"
                            class="form-check-input"
                            type="radio"
                            value="1"
                            :disabled="radio1Disabled"
                        />
                        <label class="form-check-label">1</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            v-model="radioNumRemolques"
                            class="form-check-input"
                            type="radio"
                            value="2"
                            :disabled="radio2Disabled"
                        />
                        <label class="form-check-label">2</label>
                    </div>
                </div>
                <div
                    v-if="radioNumRemolques == '1' || radioNumRemolques == '2'"
                    class="form-row"
                >
                    <div class="col-6">
                        <input
                            v-model.trim="remolque1.placa"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Placa"
                            required
                        />
                    </div>
                    <div class="col-6">
                        <select
                            class="custom-select custom-select-sm mb-2"
                            v-model="remolque1.sub_tipo_rem"
                            required
                        >
                            <option selected value="">
                                Seleccione Subtipo
                            </option>
                            <option
                                v-for="item in listClavesRemolque"
                                :key="item.clave"
                                :value="item.clave"
                            >
                                {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="radioNumRemolques == '2'" class="form-row">
                    <div class="col-6">
                        <input
                            v-model.trim="remolque2.placa"
                            ref="placa2"
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Placa"
                            required
                        />
                    </div>
                    <div class="col-6">
                        <select
                            class="custom-select custom-select-sm mb-2"
                            v-model="remolque2.sub_tipo_rem"
                            required
                        >
                            <option selected value="">
                                Seleccione Subtipo
                            </option>
                            <option
                                v-for="item in listClavesRemolque"
                                :key="item.clave"
                                :value="item.clave"
                            >
                                {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row justify-content-between">
            <div class="col-md-12 col-lg-auto">
                <button type="button"
                        @click="confirmDeleteSection()"
                        class="btn btn-danger btn-block mb-2 mb-lg-0">
                    <font-awesome-icon icon="trash-alt" />
                    Limpiar Trasporte
                </button> 
            </div>
            <div class="col-md-12 col-lg-auto d-flex justify-content-end">
                <button type="button"
                        @click="previousTab()"
                        class="btn btn-light border mr-2 col-md-6 col-lg-auto">
                    <font-awesome-icon icon="arrow-alt-circle-left" /> Anterior
                </button>
                <button type="submit" class="btn btn-success col-md-6 col-lg-auto">
                <font-awesome-icon icon="arrow-alt-circle-right" /> Siguiente
                </button>
            </div>
        </div>
    </form>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "TabViewTransporte",
    data() {
        return {
            selectedPermiso: "",
            selectedConfigAutotransporte: {
                clave:null,
                remolque: null,
            },
            selectedRemolque1: "",
            selectedRemolque2: "",
            radioNumRemolques: "0",
            radio0Disabled: false,
            radio1Disabled: true,
            radio2Disabled: true,

            ubicacion_destino: {
                distancia_recorrida: "0.01",
            },

            autotransporte_federal: {
                perm_sct: "",
                num_permiso_sct: "",
                seguros:{},
                identificacion_vehicular: {
                    pesobruto_vehicular : "",
                    config_vehicular: "",
                    placa_vm: "",
                    anio_modelo_vm: "",
                },
                remolques: [],
            },

            figura_transporte_operador: {
                rfc_operador: "",
                num_licencia: "",
                nombre_operador: "",
            },

            seguros:{
                nombre_aseg: "",
                num_poliza_seguro: "",
                asegura_med_ambiente: "",
                poliza_med_ambiente: "",
                asegura_carga: "",
                poliza_carga: ""
            },

            remolque1: {
                sub_tipo_rem: "",
                placa: "",
            },

            remolque2: {
                sub_tipo_rem: "",
                placa: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            listClavesPermisoSCT: "catalogos/listClavesPermisoSCT",
            listClavesConfigAutotransporte:
                "catalogos/listClavesConfigAutotransporte",
            listClavesRemolque: "catalogos/listClavesRemolque",

            figuraTransporteOperador: "carta_porte/getFiguraTransporteOperador",
            autoTransporteFederal: "carta_porte/getAutoTransporteFederal",
            totalDistRec:"carta_porte/getTotalDistRec",
            version: "carta_porte/getVersion"
        }),
    },
    mounted() {
        this.$store.dispatch("catalogos/fetchClavesPermisoSCT", "");
        this.$store.dispatch("catalogos/fetchClavesConfigAutotransporte", "");
        this.$store.dispatch("catalogos/fetchClavesRemolque", "");
        this.loadLocalData();
    },
    methods: {
        loadLocalData() {
            this.autotransporte_federal.num_permiso_sct = this.autoTransporteFederal.num_permiso_sct
            this.autotransporte_federal.perm_sct = this.autoTransporteFederal.perm_sct

            if(this.autoTransporteFederal.seguros.nombre_aseg != ""){
                this.seguros.nombre_aseg = this.autoTransporteFederal.seguros.nombre_aseg
                this.seguros.num_poliza_seguro = this.autoTransporteFederal.seguros.num_poliza_seguro
            } 

            if(this.autoTransporteFederal.seguros.asegura_med_ambiente!= ""){
                this.seguros.asegura_med_ambiente = this.autoTransporteFederal.seguros.asegura_med_ambiente;
                this.seguros.poliza_med_ambiente = this.autoTransporteFederal.seguros.poliza_med_ambiente;
            }   
            
            if(this.autoTransporteFederal.seguros.asegura_carga != ""){
                this.seguros.asegura_carga = this.autoTransporteFederal.seguros.asegura_carga ;
                this.seguros.poliza_carga = this.autoTransporteFederal.seguros.poliza_carga;
            } 

            if(this.figuraTransporteOperador){
                this.figura_transporte_operador.rfc_operador = this.figuraTransporteOperador.rfc_operador;
                this.figura_transporte_operador.nombre_operador = this.figuraTransporteOperador.nombre_operador;
                this.figura_transporte_operador.num_licencia = this.figuraTransporteOperador.num_licencia;
            }
           
            this.ubicacion_destino.distancia_recorrida = this.totalDistRec;
            this.autotransporte_federal.identificacion_vehicular.pesobruto_vehicular = this.autoTransporteFederal.identificacion_vehicular.pesobruto_vehicular
            this.selectedConfigAutotransporte.clave = this.autoTransporteFederal.identificacion_vehicular.config_vehicular;
            this.selectedConfigAutotransporte.remolque = this.autoTransporteFederal.identificacion_vehicular.config_vehicular_remolque;

            this.autotransporte_federal.identificacion_vehicular.placa_vm = this.autoTransporteFederal.identificacion_vehicular.placa_vm;
            this.autotransporte_federal.identificacion_vehicular.anio_modelo_vm = this.autoTransporteFederal.identificacion_vehicular.anio_modelo_vm;

            this.changeConfigAutotransporte();

            if (this.autoTransporteFederal.remolques && this.autoTransporteFederal.remolques.length) {
                this.radioNumRemolques = this.autoTransporteFederal.remolques.length

                this.remolque1.placa = this.autoTransporteFederal.remolques[0].placa;
                this.remolque1.sub_tipo_rem = this.autoTransporteFederal.remolques[0].sub_tipo_rem;

                if(this.autoTransporteFederal.remolques.length == 2){
                    this.remolque2.placa = this.autoTransporteFederal.remolques[1].placa
                    this.remolque2.sub_tipo_rem = this.autoTransporteFederal.remolques[1].sub_tipo_rem; 
                }
                
                              
            }
        },

        previousTab(){
            this.$emit("previousTab");
        },

        changeConfigAutotransporte(){
            switch (this.selectedConfigAutotransporte.remolque) {
                case "0":
					this.radioNumRemolques = "0"
                    this.radio0Disabled = false;
                    this.radio1Disabled = true;
                    this.radio2Disabled = true;
                    break;
                case "0, 1":
                case "0,1":
					this.radioNumRemolques = "1"
                    this.radio0Disabled = false;
                    this.radio1Disabled = false;
                    this.radio2Disabled = false;
                    break;
                case "1":
					this.radioNumRemolques = "1"
                    this.radio0Disabled = true;
                    this.radio1Disabled = false;
                    this.radio2Disabled = false;
                    break;
            }

        },

       /* async saveTransporte() {
            this.autotransporte_federal.seguros = {};
            this.autotransporte_federal.remolques = [];

            this.autotransporte_federal.seguros.nombre_aseg = this.seguros.nombre_aseg;
            this.autotransporte_federal.seguros.num_poliza_seguro = this.seguros.num_poliza_seguro;
            if(this.seguros.asegura_med_ambiente != ""){
                this.autotransporte_federal.seguros.asegura_med_ambiente = this.seguros.asegura_med_ambiente;
                this.autotransporte_federal.seguros.poliza_med_ambiente = this.seguros.poliza_med_ambiente;
            }

            this.autotransporte_federal.identificacion_vehicular.config_vehicular = this.selectedConfigAutotransporte.clave;

            if (
                this.radioNumRemolques == "1" ||
                this.radioNumRemolques == "2"
            ) {
                this.autotransporte_federal.remolques.push(this.remolque1);
            }
            if (this.radioNumRemolques == "2") {
                this.autotransporte_federal.remolques.push(this.remolque2);
            }

            await this.$store.dispatch(
                "carta_porte/setTotalDistRec",
                this.ubicacion_destino.distancia_recorrida
            );

            await this.$store.dispatch(
                "carta_porte/setUbicacionDestinoDistRec",
                this.ubicacion_destino.distancia_recorrida
            );

            await this.$store.dispatch(
                "carta_porte/saveAutotransporteFederal",
                this.autotransporte_federal
            );

            //por el momento solo se hara para un operador
            await this.$store.dispatch(
                "carta_porte/saveFiguraTransporteOperador",
                this.figura_transporte_operador
            );
            this.$emit("nextTab");
        },*/

        nextStep(){
            this.$emit("nextTab");
        },

        async saveTab() {
            this.autotransporte_federal.seguros = {};
            this.autotransporte_federal.remolques = [];

            this.autotransporte_federal.seguros.nombre_aseg = this.seguros.nombre_aseg;
            this.autotransporte_federal.seguros.num_poliza_seguro = this.seguros.num_poliza_seguro;
            if(this.seguros.asegura_med_ambiente != ""){
                this.autotransporte_federal.seguros.asegura_med_ambiente = this.seguros.asegura_med_ambiente;
                this.autotransporte_federal.seguros.poliza_med_ambiente = this.seguros.poliza_med_ambiente;
            }   
            
            if(this.seguros.asegura_carga != ""){
                this.autotransporte_federal.seguros.asegura_carga = this.seguros.asegura_carga;
                this.autotransporte_federal.seguros.poliza_carga = this.seguros.poliza_carga;
            } 

            this.autotransporte_federal.identificacion_vehicular.config_vehicular = this.selectedConfigAutotransporte.clave;
            this.autotransporte_federal.identificacion_vehicular.config_vehicular_remolque = this.selectedConfigAutotransporte.remolque;
            if (
                this.radioNumRemolques == "1" ||
                this.radioNumRemolques == "2"
            ) {
                this.autotransporte_federal.remolques.push(this.remolque1);
            }
            if (this.radioNumRemolques == "2") {
                this.autotransporte_federal.remolques.push(this.remolque2);
            }

            await this.$store.dispatch(
                "carta_porte/setTotalDistRec",
                this.ubicacion_destino.distancia_recorrida
            );

            await this.$store.dispatch(
                "carta_porte/setUbicacionDestinoDistRec",
                this.ubicacion_destino.distancia_recorrida
            );

            await this.$store.dispatch(
                "carta_porte/saveAutotransporteFederal",
                this.autotransporte_federal
            );

            //por el momento solo se hara para un operador
            await this.$store.dispatch(
                "carta_porte/saveFiguraTransporteOperador",
                this.figura_transporte_operador
            );
        },
        
        confirmDeleteSection() {
            this.$bvModal.msgBoxConfirm('¿Esta seguro(a) de limpiar/borrar todos los datos de esta sección?', {
                title: 'Limpiar Trasporte',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Limpiar Trasporte',
                cancelTitle: 'Cancelar',
                cancelVariant: 'light border',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
              if(value){
                
                this.resetView()
                this.saveTab()
              }
            })
            .catch(err => {
                // An error occurred
            })
        },

        resetView() {
            this.selectedPermiso = "";
            this.selectedConfigAutotransporte.clave = null;
            this.selectedConfigAutotransporte.remolque = null;
            this.selectedRemolque1 = "";
            this.selectedRemolque2 = "";
            this.radioNumRemolques = "0";
            this.radio0Disabled = false,
            this.radio1Disabled = true,
            this.radio2Disabled = true,


            this.ubicacion_destino.distancia_recorrida = "0.01";

            this.autotransporte_federal.perm_sct= ""
            this.autotransporte_federal.num_permiso_sct ="" 
            this.autotransporte_federal.seguros={}; 
            this.autotransporte_federal.identificacion_vehicular.pesobruto_vehicular= "";
            this.autotransporte_federal.identificacion_vehicular.config_vehicular= "";
            this.autotransporte_federal.identificacion_vehicular.placa_vm= "";
            this.autotransporte_federal.identificacion_vehicular.anio_modelo_vm= "";
            this.autotransporte_federal.remolques= [];

            this.seguros.nombre_aseg= "";
            this.seguros.num_poliza_seguro= "";
            this.seguros.asegura_med_ambiente= "";      
            this.seguros.poliza_med_ambiente= "";
            this.seguros.asegura_carga= ""; 
            this.seguros.poliza_carga= "";
            
            this.figura_transporte_operador.rfc_operador= "";
            this.figura_transporte_operador.num_licencia= "";
            this.figura_transporte_operador.nombre_operador= "";
        
            this.remolque1.sub_tipo_rem= "";
            this.remolque1.placa= "";

            this.remolque2.sub_tipo_rem= "";
            this.remolque2.placa= "";

        },
    },
};
</script>
