export default {
    namespaced: true,
    state: {
        version: "31",// version cartaporte 31
        transp_internac: "",
        entrada_salida_merc: null,
        pais_origen_destino: null,
        regimen_aduanero: null,
        regimenes_aduaneros: null,
        via_entrada_salida: null,
        total_dist_rec: "0",
        ubicaciones: {
            origenes: [
                {
                    id_origen: null,
                    rfc_remitente: "",
                    nombre_remitente: "",
                    num_reg_id_trib: null,
                    residencia_fiscal: null,
                    fechahora_salida: null,
                    domicilio: {}
                }
            ],
            destinos: [
                {
                    distancia_recorrida:"0",
                    id_destino: null,
                    rfc_destinatario: "",
                    nombre_destinatario: "",
                    num_reg_id_trib: null,
                    residencia_fiscal: null,
                    fechahora_prog_llegada: null,
                    domicilio: {}
                }
            ]
        },
        mercancias: {
            num_total_mercancias: "",
            items: [],
            autotransporte_federal: {
                perm_sct: "",
                num_permiso_sct: "",
                seguros:{},
                identificacion_vehicular: {
                    pesobruto_vehicular :"",
                    config_vehicular: "",
                    placa_vm: "",
                    anio_modelo_vm: ""
                },
                remolques: null
            }
        },
        figura_transporte: {
            operadores: null,
            propietarios: null,
            arrendatarios: null,
            notificados: null
        }
    },

    getters: {
        getVersion: state => {
            return state.version
        },

        getTipoTrasporte: state => {
            return {
                transp_internac: state.transp_internac,
                entrada_salida_merc: state.entrada_salida_merc,
                regimen_aduanero: state.regimen_aduanero,
                regimenes_aduaneros: state.regimenes_aduaneros
            };
        },

        listMercanciasItems: state => {
            return state.mercancias.items;
        },

        getFiguraTransporteOperador: state => {
            return state.figura_transporte.operadores
                ? state.figura_transporte.operadores[0]
                : null;
        },

        getAutoTransporteFederal: state => {
            return state.mercancias.autotransporte_federal;
        },
		getUbicaciones: state => {
            return state.ubicaciones
        },
        getTotalDistRec: state => {
            return state.total_dist_rec
        }
    },

    mutations: {
        SET_VERSION: (state, version) => {
            state.version = version;
        },

        SET_TIPO_TRASPORTE: (state, data) => {
            state.transp_internac = data.transp_internac;
            state.entrada_salida_merc = data.entrada_salida_merc;
            state.regimen_aduanero = data.regimen_aduanero;
            state.regimenes_aduaneros = data.regimenes_aduaneros;
            state.pais_origen_destino = data.pais_origen_destino;
            if(data.transp_internac == "Sí"){
                state.via_entrada_salida = "01";
            }else{
                state.via_entrada_salida = null;
            }     
        },

        ADD_MERCANCIAS_ITEM: (state, data) => {
            let doc = {}
            let idMercancia = new Date().getTime();
            Object.assign(doc, data.documentacion_aduanera);
            state.mercancias.items.push({
                id:idMercancia,
                bienes_transp: data.bienes_transp,
                descripcion: data.descripcion,
                cantidad: data.cantidad,
                clave_unidad: data.clave_unidad,
                unidad: data.unidad,
                material_peligroso: data.material_peligroso,
                cve_material_peligroso: data.cve_material_peligroso,
                desc_material_peligroso: data.desc_material_peligroso,
                embalaje: data.embalaje,
                descrip_embalaje: data.descrip_embalaje,
                peso_en_kg: data.peso_en_kg,
                numero_guia_identificacion: data.numero_guia_identificacion,
                descripcion_guia_identificacion: data.descripcion_guia_identificacion,
                peso_guia_identificacion: data.peso_guia_identificacion,
                fraccion_arancelaria: data.fraccion_arancelaria,
                tipo_materia: data.tipo_materia,
                descripcion_materia: data.descripcion_materia,
                pedimentos: data.pedimentos ? data.pedimentos : null,
                documentacion_aduanera: doc.tipo_documento? doc : null

            });
            state.mercancias.num_total_mercancias = state.mercancias.items.length.toString();
        },

        REMOVE_MERCANCIAS_ITEM: (state, index) => {
            state.mercancias.items.splice(index, 1);
        },

        EDIT_MERCANCIAS_ITEM: (state, data) => {
            const mercancia = state.mercancias.items.find(item => item.id == data.id);
            
            let doc = {}
            Object.assign(doc, data.documentacion_aduanera);

            
            mercancia.pedimentos = data.pedimentos ? data.pedimentos : null;
            mercancia.documentacion_aduanera = doc.tipo_documento ? doc : null;
        },

        SAVE_FIGURA_TRANSPORTE_OPERADOR: (state, data) => {
            state.figura_transporte.operadores = [];
            state.figura_transporte.operadores.push({
                rfc_operador: data.rfc_operador,
                num_licencia: data.num_licencia,
                nombre_operador: data.nombre_operador,
                numregid_trib_operador: null,
                residencia_fiscal_operador: null
            });
        },

        SAVE_AUTOTRANSPORTE_FEDERAL: (state, data) => {
            state.mercancias.autotransporte_federal.perm_sct = data.perm_sct;
            state.mercancias.autotransporte_federal.num_permiso_sct =
                data.num_permiso_sct;
            state.mercancias.autotransporte_federal.seguros = data.seguros;
            state.mercancias.autotransporte_federal.identificacion_vehicular.pesobruto_vehicular =
            data.identificacion_vehicular.pesobruto_vehicular;
            state.mercancias.autotransporte_federal.identificacion_vehicular.config_vehicular =
                data.identificacion_vehicular.config_vehicular;
            state.mercancias.autotransporte_federal.identificacion_vehicular.config_vehicular_remolque =
                data.identificacion_vehicular.config_vehicular_remolque;
            state.mercancias.autotransporte_federal.identificacion_vehicular.placa_vm =
                data.identificacion_vehicular.placa_vm;
            state.mercancias.autotransporte_federal.identificacion_vehicular.anio_modelo_vm =
                data.identificacion_vehicular.anio_modelo_vm;

            if (data.remolques.length) {
                state.mercancias.autotransporte_federal.remolques =
                    data.remolques;
            } else {
                state.mercancias.autotransporte_federal.remolques = null;
            }
        },

        SET_UBICACIONES_ORIGENES: (state, origenes) => {
            state.ubicaciones.origenes[0].id_origen = origenes.id_origen;
            state.ubicaciones.origenes[0].rfc_remitente = origenes.rfc_remitente;
            state.ubicaciones.origenes[0].nombre_remitente = origenes.nombre_remitente;
            state.ubicaciones.origenes[0].num_reg_id_trib = origenes.num_reg_id_trib;
            state.ubicaciones.origenes[0].residencia_fiscal = origenes.residencia_fiscal;
            state.ubicaciones.origenes[0].residencia_fiscal_descripcion = origenes.residencia_fiscal_descripcion;
            state.ubicaciones.origenes[0].fechahora_salida = origenes.fechahora_salida;
            state.ubicaciones.origenes[0].domicilio = origenes.domicilio;
        },

        SET_UBICACIONES_DESTINOS: (state, destinos) => {
            state.ubicaciones.destinos[0].distancia_recorrida = state.total_dist_rec ;
            state.ubicaciones.destinos[0].id_destino = destinos.id_destino
            state.ubicaciones.destinos[0].rfc_destinatario = destinos.rfc_destinatario
            state.ubicaciones.destinos[0].nombre_destinatario = destinos.nombre_destinatario
            state.ubicaciones.destinos[0].num_reg_id_trib = destinos.num_reg_id_trib
            state.ubicaciones.destinos[0].residencia_fiscal = destinos.residencia_fiscal
            state.ubicaciones.destinos[0].residencia_fiscal_descripcion = destinos.residencia_fiscal_descripcion
            state.ubicaciones.destinos[0].fechahora_prog_llegada = destinos.fechahora_prog_llegada
            state.ubicaciones.destinos[0].domicilio = destinos.domicilio
        },

        SET_UBICACION_DESTINO_DIST_REC: (state, distRec) => {
            state.ubicaciones.destinos[0].distancia_recorrida = distRec;
        },

        SET_TOTAL_DIST_REC: (state, distRec) => {
            state.total_dist_rec = distRec;
        },

        SET_CARTA_PORTE_DEFAULTS: state => {
            state.transp_internac = "";
            state.entrada_salida_merc = null;
            state.regimen_aduanero = null;
            state.regimenes_aduaneros = null;
            state.pais_origen_destino = null;
            state.via_entrada_salida = null;
            state.total_dist_rec = "0";
            state.ubicaciones = {
                origenes: [
                    {
                        id_origen: null,
                        rfc_remitente: "",
                        nombre_remitente: "",
                        num_reg_id_trib: null,
                        residencia_fiscal: null,
                        fechahora_salida: null,
                        domicilio: {}
                    }
                ],
                destinos: [
                    {
                        distancia_recorrida:"0",
                        id_destino: null,
                        rfc_destinatario: "",
                        nombre_destinatario: "",
                        num_reg_id_trib: null,
                        residencia_fiscal: null,
                        fechahora_prog_llegada: null,
                        domicilio: {}
                    }
                ]
            };
            state.mercancias = {
                num_total_mercancias: "",
                items: [],
                autotransporte_federal: {
                    perm_sct: "",
                    num_permiso_sct: "",
                    seguros:{},
                    identificacion_vehicular: {
                        pesobruto_vehicular :"",
                        config_vehicular: "",
                        placa_vm: "",
                        anio_modelo_vm: ""
                    },
                    remolques: null
                }
            };
            state.figura_transporte = {
                operadores: null,
                propietarios: null,
                arrendatarios: null,
                notificados: null
            };
        }
    },

    actions: {
        saveVersion: ({ commit }, data) => {
            commit("SET_VERSION", data);
        },

        saveTipoTransporte: ({ commit }, data) => {
            commit("SET_TIPO_TRASPORTE", data);
        },
        
        addMercanciasItem({ commit }, item) {
            commit("ADD_MERCANCIAS_ITEM", item);
        },

        removeMercanciasItem({ commit }, index) {
            commit("REMOVE_MERCANCIAS_ITEM", index);
        },

        editMercanciasItem({ commit }, data) {
            commit("EDIT_MERCANCIAS_ITEM", data);
        },

        saveAutotransporteFederal({ commit }, data) {
            commit("SAVE_AUTOTRANSPORTE_FEDERAL", data);
        },

        saveFiguraTransporteOperador({ commit }, data) {
            commit("SAVE_FIGURA_TRANSPORTE_OPERADOR", data);
        },

        setUbicacionesOrigenes({ commit }, data) {
            commit("SET_UBICACIONES_ORIGENES", data);
        },

        setUbicacionesDestinos({ commit }, data) {
            commit("SET_UBICACIONES_DESTINOS", data);
        },

        setUbicacionDestinoDistRec({ commit }, data) {
            commit("SET_UBICACION_DESTINO_DIST_REC", data);
        },

        setTotalDistRec({ commit }, data) {
            commit("SET_TOTAL_DIST_REC", data);
        },

        setCartaPorteDefaults({ commit }) {
            commit("SET_CARTA_PORTE_DEFAULTS");
        }
    }
};
