<template>
    <div>
        <div class="row justify-content-between">
            <div class="col-lg-5 col-md-12">
                <div class="form-row">
                    <label for="sltUso"
                           class="col-sm-4 col-form-label">Uso CFDI</label>
                    <div class="col-sm-8">
                        <select class="custom-select custom-select-sm mb-2"
                                v-model="form.usoCfdi"
                                :disabled=" !(customer.rfc.length >= 12)">
                            <option selected value="">
                                Seleccione Uso de CFDI
                            </option>
                            <option v-for="item in listUsosCfdiFilter"
                                    :key="item.clave"
                                    :value="item.clave">
                                    {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <label for="sltMetodo"
                           class="col-sm-4 col-form-label">Met&oacute;do de Pago</label>
                    <div class="col-sm-8">
                        <select class="custom-select custom-select-sm"
                                v-model="form.paymentMethod"
                                @change="changePaymentMethod()">
                            <option selected
                                    value="PUE">
                                PUE - Pago En Una Sola Exhibicion
                            </option>
                            <option value="PPD">
                                PPD - Pago En Parcialidades o Diferido
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <label class="col-sm-4 col-form-label">Condiciones</label>
                    <div class="col-sm-8">
                        <select class="custom-select custom-select-sm"
                                v-model.trim="form.paymentCondition"
                                v-if="form.paymentCondition != 'OTRO'">
                            <option value="CONTADO">Contado</option>
                            <option value="CREDITO">Crédito</option>
                            <option value="">Sin Condiciones</option>
                            <option value="OTRO">Otro</option>
                        </select>
                        <div class="input-group"
                             v-if="form.paymentCondition == 'OTRO'">
                            <input type="text"
                                   v-model="otherPaymentCondition"
                                   class="form-control form-control-sm" />
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-secondary"
                                        type="button"
                                        @click="form.paymentCondition = 'CONTADO'">
                                    x
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label class="col-sm-4 col-form-label">Forma</label>
                    <div class="col-sm-8">
                        <select class="custom-select custom-select-sm"
                                v-model="form.paymentForm">
                            <option v-for="item in listFormasPago"
                                    :key="item.clave"
                                    :value="item.clave">
                                {{ item.descripcion }} - {{ item.clave }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row"
                     v-if="showTC">
                    <label for="SltMoneda"
                           class="col-sm-4 col-form-label">Moneda</label>
                    <div class="col-sm-4">
                        <select class="custom-select custom-select-sm mb-2"
                                v-model="form.paymentCurrency"
                                @change="changePaymentCurrency()">
                            <option v-for="item in listMonedas"
                                    :key="item.id"
                                    :value="item.clave">
                                {{ item.clave }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <div class="input-group-text">T.C.</div>
                            </div>
                            <input type="text"
                                   class="form-control"
                                   v-model.trim="form.paymentCurrencyTC" />
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label class="col-sm-4 col-form-label">CFDI Relacionados</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            
                            <input type="text"
                                    v-model="cfdisRelacionados"
                                   class="form-control"
                                   disabled />
                            <div class="input-group-append">
                                <button @click.prevent="searchCfdiRel()" class="btn btn-light border" type="button" id="button-addon2">
                                    <font-awesome-icon icon="search" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12" v-if="listConceptos.length">
                <div v-for="item in listConceptos"
                      :key="item.clave">
                      <div v-if="item.clave !=''" class="form-row">
                          <label class="col-sm-5 col-form-label">{{item.clave}}</label>
                            <div class="col-sm-7">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"
                                            id="basic-addon1">$</span>
                                    </div>
                                    <input type="text"
                                                class="form-control text-right"
                                                v-model="item.precio"/>
                                </div>
                            </div>
                      </div> 
                </div>
            </div>
            <div class="col-lg-3 col-md-12 ml-auto">
                <div class="form-row">
                    <label for="txtSubtotal"
                           class="col-sm-4 col-form-label">Subtotal
                    </label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="getMontos.subtotal | money"
                                   disabled />
                        </div>
                    </div>
                </div>
                <div class="form-row"
                     v-if="getMontos.descuento != 0">
                    <label for="txtDescuento"
                           class="col-sm-4 col-form-label">Descuento</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="getMontos.descuento | money"
                                   disabled />
                        </div>
                    </div>
                </div>
                <div class="form-row"
                     v-if="getMontos.iva != 0">
                    <label for="txtIva"
                           class="col-sm-4 col-form-label">IVA</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="getMontos.iva | money"
                                   disabled />
                        </div>
                    </div>
                </div>
                <div class="form-row"
                     v-if="getMontos.retiva != 0">
                    <label for="txtRetIva"
                           class="col-sm-4 col-form-label">Ret. IVA</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="('-' + getMontos.retiva) | money"
                                   disabled />
                        </div>
                    </div>
                </div>
                <div class="form-row"
                     v-if="getMontos.retisr != 0">
                    <label for="txtRetIva"
                           class="col-sm-4 col-form-label">Ret. ISR</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="('-' + getMontos.retisr) | money"
                                   disabled />
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label for="txtTotal"
                           class="col-sm-4 col-form-label">Total</label>
                    <div class="col-sm-8">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                      id="basic-addon1">$</span>
                            </div>
                            <input type="text"
                                   class="form-control text-right"
                                   :value="getMontos.total | money"
                                   disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <b-table class="mb-2"
                 hover
                 small
                 outlined
                 responsive
                 head-variant="light"
                 :fields="fields"
                 :items="listConceptos">
            <template #head(options)>
                <a class="btn btn-outline-info btn-sm"
                   @click="addConcepto()">
                    <font-awesome-icon icon="plus" /> Agregar Concepto
                </a>
            </template>
            <template #cell(cant)="row">
                <small>{{ row.item.cant }} {{ row.item.unidad }} {{ row.item.unidadclave }} </small>
            </template>
            <template #cell(descripcion)="row">
                <small>{{ row.item.claveprodserv }} -
                    {{ row.item.descripcion }}</small>
            </template>
            <template #cell(precio)="row">
                <small>${{ row.item.precio | money(decimales) }}</small>
                <!-- <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            $
                        </div>
                    </div>

                    <input
                        type="text"
                        v-model="row.item.precio"
                        @input="editConceptosItem(row)"
                        @keypress="formatInputMoney(row.item.precio)"
                        class="form-control form-control-sm text-right"
                        placeholder="$0.00"
                    />
                </div> -->
            </template>
            <template #cell(importe)="row">
                <small>${{ (row.item.cant * row.item.precio) | money }}</small>
            </template>
            <template #cell(val_iva)="row">
                <small>{{ row.item.val_iva | money }}%</small>
                <!-- <div class="input-group input-group-sm">
                    <input
                        type="text"
                        v-model.trim="row.item.val_iva"
                        @input="editConceptosItem(row)"
                        class="form-control text-right"
                        placeholder="0"
                    />

                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div> -->
            </template>
            <template #cell(val_retiva)="row">
                <small>{{ row.item.val_retiva | money }}%</small>
                <!-- <div class="input-group input-group-sm">
                    <input
                        type="text"
                        v-model.trim="row.item.val_retiva"
                        @input="editConceptosItem(row)"
                        class="form-control text-right"
                        placeholder="0"
                    />

                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div> -->
            </template>
            <template #cell(usa_retisr)="row">
                <small v-if="row.item.usa_retisr">{{ impuestos.val_retisr | money }}%</small>
                <small v-else>0.00%</small>
            </template>
            <template #cell(options)="row">
                <a href="#" class="text-danger text-decoration-none mr-2"
                   @click.prevent="removeConcepto(row.index)"><small>
                        <font-awesome-icon icon="times" /> Quitar
                    </small></a>
                <a href="#" class="text-primary text-decoration-none"
                   @click.prevent="editConcepto(row)"><small>
                        <font-awesome-icon icon="pen" /> Editar
                    </small></a>
            </template>
        </b-table>
        <button type="button"
                @click="editObservaciones()"
                class="btn btn-default border col-md-6 col-lg-auto">
            <font-awesome-icon icon="pen" /> Observaciones
        </button>
      
        <div class="row justify-content-between mt-3">
            <div class="col-md-12 col-lg-auto">
                <button type="button"
                    @click="confirmDeleteSection()"
                    class="btn btn-danger btn-block mb-2 mb-lg-0">
                <font-awesome-icon icon="trash-alt" />
                Limpiar CFDI
            </button> 
            </div>
            <div class="col-md-12 col-lg-auto">
                <button type="button"
                    @click="nextStep()"
                    class="btn btn-success btn-block">
                <font-awesome-icon icon="arrow-alt-circle-right" /> Siguiente
            </button>
            </div>
        </div>        
        <ModalConcepto ref="ModalConcepto" />
        <ModalCfdisRelacionados ref="ModalCfdisRelacionados" />
        <ModalObservaciones ref="ModalObservaciones" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";
import ModalConcepto from "@/views/crear_factura/cfdi/ViewModalConcepto.vue";
import ModalCfdisRelacionados from "@/views/crear_factura/cfdi/ViewModalCfdisRelacionados.vue";
import ModalObservaciones from "@/views/crear_factura/cfdi/ViewModalObservaciones.vue";

export default {
    name: "TabViewCfdi",
    components: { 
        VueBootstrapTypeaheadCustom, 
        ModalConcepto, 
        ModalCfdisRelacionados, 
        ModalObservaciones 
    },
    data() {
        return {
            showDivNuevoConcepto: false,
            selectedUnidad: "",
            selectedProducto: "",
            otherPaymentCondition: "",
            errorClaveSat: "",
            errorDescripcion: "",
            errorCantidad: "",
            errorUnidadSat: "",
            errorPrecio: "",

            itemConcepto: {
                claveprodserv: "",
                descripcion: "",
                cant: "",
                unidad: "",
                unidadclave: "",
                precio: "",
                val_iva: "",
                val_retiva: "",
                //usa_iva: 1,
                //usa_retiva: 1,
            },

            form: {
                usoCfdi: "",
                cfdisRel: "",
                paymentMethod: "PUE",
                paymentCondition: "CONTADO",
                paymentForm: "01",
                paymentCurrency: "MXN",
                paymentCurrencyTC: "1",
            },
            disabledSltUsoCfdi:true,
            listUsosCfdiFilter: [{clave:"S01", descripcion:"Sin efectos fiscales"}],

            fields: [
                {
                    key: "cant",
                    label: "Cantidad",
                    thStyle: "width: 10%",
                },
                {
                    key: "descripcion",
                    label: "Descripción",
                    thStyle: "width: 20%",
                },
                {
                    key: "precio",
                    label: "Precio",
                    class: "text-right",
                    thStyle: "width: 10%",
                },
                {
                    key: "importe",
                    label: "Importe",
                    class: "text-right",
                    thStyle: "width: 10%",
                },
                {
                    key: "val_iva",
                    label: "IVA",
                    class: "text-right",
                    thStyle: "width: 6%",
                },
                {
                    key: "val_retiva",
                    label: "Ret. IVA",
                    class: "text-right",
                    thStyle: "width: 7%",
                },
                {
                    key: "usa_retisr",
                    label: "Ret. ISR",
                    class: "text-right",
                    thStyle: "width: 7%",
                },
                {
                    key: "options",
                    label: "",
                    class: "text-center",
                    thStyle: "width: 15%",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            listFormasPago: "catalogos/listFormasPago",
            listMonedas: "catalogos/listMonedas",
            listUsoCfdi: "catalogos/listUsoCfdi",
            customer: "cfdi/getCustomer",
            decimales: "catalogos/getDecimales",
            impuestos: "catalogos/getImpuestos",
            tipoCambio: "catalogos/getTipoCambio",
            listConceptos: "cfdi/listConceptos",
            configCartaPorte: "catalogos/getConfigCartaPorte",
            config: "catalogos/getConfig",
            getMontos: "cfdi/getMontos",
            isConfigConceptsAggregated:"cfdi/isConfigConceptsAggregated",
            dataCfdisRelacionados: "cfdi/getCfdisRelacionados",
            getUsoCfdi: "cfdi/getUsoCfdi",
            getCondicionesPago: "cfdi/getCondicionesPago",
        }),
        showTC() {
            let moneda = this.listMonedas.find((mon) => mon.clave == "MXN");
            if (!moneda || this.listMonedas.length > 1) {
                return true;
            }
            return false;
        },

        cfdisRelacionados() {
             var cfdis = this.dataCfdisRelacionados.cfdisRelacionados;
            var folios = '';
            cfdis.forEach((cfdi, i) => {
                folios += (cfdis.length !== i + 1 ? cfdi['factura'] + ',' : cfdi['factura']);
            });
            return folios
        },
    },
    watch: {
        "customer.rfc"(value) {
            if (value && value.length >= 12) {
                let esmoral = value.length == 12;
                this.loadCfdiUses(esmoral);  
            }else{
                this.form.usoCfdi = "";
            }
        },

       /*  "form.usoCfdi"() {
            this.changeUsoCfdi();
        },   */
    },

    async mounted() {
        await this.$store.dispatch("catalogos/fetchFormasPago");
        await this.$store.dispatch("catalogos/fetchUsoCfdi");
        this.loadConfigCartaPorte();
        this.loadLocalData();
        
    },

    methods: {
        loadLocalData() {

            if (this.customer.rfc && this.customer.rfc.length >= 12) {
                let esmoral = this.customer.rfc.length == 12;
                this.loadCfdiUses(esmoral);  
            }else{
                this.form.usoCfdi = "";
            }

            this.form.usoCfdi = this.getUsoCfdi;
            this.form.cfdisRel = this.dataCfdisRelacionados
            this.form.paymentMethod = this.getCondicionesPago.paymentMethod
            this.form.paymentCondition = this.getCondicionesPago.paymentCondition
            this.form.paymentForm= this.getCondicionesPago.paymentForm
            this.form.paymentCurrency= this.getCondicionesPago.paymentCurrency
            this.form.paymentCurrencyTC= this.getCondicionesPago.paymentCurrencyTC
        },

        loadCfdiUses(isMoral){
            this.listUsosCfdiFilter = [];
            this.listUsoCfdi.forEach(item => {
                if(!(item.clave == "P01" && this.config.val_version == 40)){// No agregar uso P01 cuando version cfdi sea 4.0
                    if (item.esmoral && isMoral || !isMoral) {
                        this.listUsosCfdiFilter.push(item);
                    }
                }
            });

            let usocfdi = this.listUsosCfdiFilter.find(
                (item) => item.clave == this.form.usoCfdi
            );

            if(!usocfdi){
                this.form.usoCfdi = "";
            }
        },

/*         changeUsoCfdi() {
            this.$store.dispatch("cfdi/setCfdiUse", this.form.usoCfdi);
        }, */

        loadConfigCartaPorte() {
            if(!this.isConfigConceptsAggregated){ // conceptos agregados, evita que se esten agregando mas de una vez
                this.$store.dispatch("catalogos/fetchConfigCartaPorte").then(() => {
                    if (this.configCartaPorte) {
                        this.$store.dispatch("cfdi/addConfigConceptosItems", this.configCartaPorte);
                    }
                });
            }
           
        },

        changePaymentMethod() {
            if (this.form.paymentMethod == "PPD") {
                this.form.paymentCondition = "CREDITO";
                this.form.paymentForm = "99";
            }
            if (this.form.paymentMethod == "PUE") {
                this.form.paymentCondition = "CONTADO";
                this.form.paymentForm = "01";
            }
        },

        changePaymentCurrency() {
            if (this.form.paymentCurrency == "MXN") {
                this.form.paymentCurrencyTC = "1.00";
            } else {
                if (this.form.paymentCurrency == "USD") {
                    this.$store
                        .dispatch("catalogos/fetchTipoCambio")
                        .then(() => {
                            this.form.paymentCurrencyTC = this.tipoCambio;
                        });
                } else {
                    this.form.paymentCurrencyTC = "";
                }
            }
        },

        addConcepto() {
            this.$refs.ModalConcepto.show({id:"new"});
        },

        editConcepto(data) {
            this.$refs.ModalConcepto.show({id:data.index,...data.item});
        },

        removeConcepto(index) {
            this.$store.dispatch("cfdi/removeConceptosItem", index);
        },

        searchCfdiRel(){
            this.$refs.ModalCfdisRelacionados.show();
        },

        /*async saveCondicionesPago() {
            if(this.form.paymentCondition == 'OTRO'){
                this.form.paymentCondition = this.otherPaymentCondition;
            }
            await this.$store.dispatch("cfdi/setCondicionesPago", this.form);
            this.$emit("nextTab");
        },*/

        editObservaciones(){
            this.$refs.ModalObservaciones.show();
        },

        nextStep(){
            this.$emit("nextTab");
        },

		async saveTab() {
            if(this.form.paymentCondition == 'OTRO'){
                this.form.paymentCondition = this.otherPaymentCondition;
            }
            await this.$store.dispatch("cfdi/setCfdiUse", this.form.usoCfdi);
			await this.$store.dispatch("cfdi/setCondicionesPago", this.form);
		},

        isValidConcepto() {
            let isValid = true;
            this.errorClaveSat = "";
            this.errorDescripcion = "";
            this.errorCantidad = "";
            this.errorPrecio = "";
            this.errorUnidadSat = "";
            //this.errorIva = "";
            //this.errorRetIva = "";

            if (this.itemConcepto.claveprodserv == "") {
                this.errorClaveSat =
                    "Clave del Producto/Servicio debe de ser numerica";
                isValid = false;
            }

            if (this.itemConcepto.claveprodserv.length != 8) {
                this.errorClaveSat =
                    "Clave del Producto/Servicio Invalida, minimo 8 digitos";
                isValid = false;
            }

            if (
                this.itemConcepto.descripcion.length < 4 ||
                this.itemConcepto.descripcion == ""
            ) {
                this.errorDescripcion = "Descripción debe de ser más extensa.";
                isValid = false;
            }

            if (this.itemConcepto.descripcion.length >= 1000) {
                this.errorDescripcion =
                    "Descripción del producto o servicio es muy extensa, máximo 1,000 caracteres";
                isValid = false;
            }

            if (this.itemConcepto.descripcion.indexOf("|") > 1) {
                this.errorDescripcion =
                    "Descripción del producto o servicio tiene el caracter invalido '|' ";
                isValid = false;
            }

            if (this.itemConcepto.cant <= 0 || this.itemConcepto.cant == "") {
                this.errorCantidad = "Cantidad invalida";
                isValid = false;
            }

            if (
                (("" + this.itemConcepto.precio).split(".")[1] || []).length > 6
            ) {
                this.errorPrecio = "Solo se permiten un máximo de 6 decimales.";
                isValid = false;
            }

            if (
                this.itemConcepto.precio < 0 ||
                this.itemConcepto.precio == ""
            ) {
                this.errorPrecio = "Precio no valido";
                isValid = false;
            }

            if (
                this.itemConcepto.unidadclave == "" ||
                this.itemConcepto.unidad == ""
            ) {
                this.errorUnidadSat = "Clave Unidad SAT indefinida";
                isValid = false;
            }

            if (this.itemConcepto.unidad.length >= 20) {
                this.errorUnidadSat =
                    "Descripcion de la unidad es demasiado larga";
                isValid = false;
            }

            /* if (this.itemConcepto.val_iva == "") {
                this.errorIva = "IVA del producto o servicio es requerido";
                isValid = false;
            }

            if (this.itemConcepto.val_retiva == "") {
                this.errorRetIva = "Ret. IVA del producto o servicio es requerido";
                isValid = false;
            }*/

            return isValid;
        },

        cleanConceptoForm() {
            this.selectedUnidad = "";
            this.selectedProducto = "";
            this.itemConcepto.claveprodserv = "";
            this.itemConcepto.descripcion = "";
            this.itemConcepto.cant = "";
            this.itemConcepto.unidad = "";
            this.itemConcepto.unidadclave = "";
            this.itemConcepto.precio = "";
            this.itemConcepto.val_iva = "";
            this.itemConcepto.val_retiva = "";
            // this.itemConcepto.usa_iva = 1;
            // this.itemConcepto.usa_retiva = 1;
        },

        confirmDeleteSection() {
            this.$bvModal.msgBoxConfirm('¿Esta seguro(a) de limpiar/borrar todos los datos de esta sección?', {
                title: 'Limpiar Origen y Destino',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Limpiar Origen y Destino',
                cancelTitle: 'Cancelar',
                cancelVariant: 'light border',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
              if(value){
                
                this.$store.dispatch("cfdi/setCfdiDefaults");
                this.$emit("resetTabCFDI");
                this.resetView()
                this.saveTab()
              }
            })
            .catch(err => {
                // An error occurred
            })
        },

        resetView() {
            this.form.usocfdi = "";
            this.form.cfdisRel = "";
            this.form.paymentMethod = "PUE";
            this.form.paymentCondition = "CONTADO";
            this.form.paymentForm = "01";
            this.form.paymentCurrency = "MXN";
            this.form.paymentCurrencyTC = "1";
            this.loadConfigCartaPorte();
        },
    },
};
</script>