import { HTTP } from "@/core/init";
import { dataConfigStorage } from "@/utils/localStorage";
import api from "@/api/f123";

export default {
    namespaced: true,
    state: {
        series: [],
        formatos: [],
        formas_pago: [],
        monedas: [],
        decimales: 0,
        impuestos:{},
        tipo_cambio: 0.0,
        cfdis: [],
        productos: [],
        clientes: [],
        paises: [],
        estados: [],
        data_codigo_postal: {},
        claves_unidad: [],
        claves_producto: [],
        claves_material: [],
        claves_embalaje: [],
        claves_permiso_sct: [],
        claves_config_autotransporte:[],
        claves_remolque:[],
        uso_cfdi: [],
        regimenes: [],
        config_carta_porte:[],
        config:[],
        data_company:{},
        
    },

    getters: {
        listSeries: state => {
            return state.series;
        },

        listFormatos: state => {
            return state.formatos;
        },

        listFormasPago: state => {
            return state.formas_pago;
        },

        listMonedas: state => {
            return state.monedas;
        },

        getDecimales: state => {
            return state.decimales;
        },

        getImpuestos: state => {
            return state.impuestos;
        },

        getTipoCambio: state => {
            return state.tipo_cambio;
        },

        listCfdis: state => {
            return state.cfdis;
        },

        listProductos: state => {
            return state.productos;
        },

        listClientes: state => {
            return state.clientes;
        },

        listPaises: state => {
            return state.paises;
        },

        listEstados: state => {
            return state.estados;
        },

        getDataCodigoPostal: state => {
            return state.data_codigo_postal;
        },

        listClavesUnidad: state => {
            return state.claves_unidad;
        },

        listClavesProducto: state => {
            return state.claves_producto;
        },

        listClavesMaterial: state => {
            return state.claves_material;
        },

        listClavesEmbalaje: state => {
            return state.claves_embalaje;
        },

        listClavesPermisoSCT: state => {
            return state.claves_permiso_sct;
        },

        listClavesConfigAutotransporte: state => {
            return state.claves_config_autotransporte;
        },

        listClavesRemolque: state => {
            return state.claves_remolque;
        },

        listUsoCfdi: state => state.uso_cfdi,

        listRegimenes: state => state.regimenes,

        getConfigCartaPorte: state => {
            return state.config_carta_porte;
        },

        getConfig: state => {
            return state.config;
        },

        getDataCompany: state => {
            return state.data_company;
        },

    },

    mutations: {
        SET_SERIES: (state, payload) => {
            state.series = payload;
        },

        SET_FORMATOS: (state, payload) => {
            state.formatos = payload;
        },

        SET_FORMAS_PAGO: (state, payload) => {
            state.formas_pago = payload;
        },

        SET_MONEDAS: (state, payload) => {
            state.monedas = payload;
        },

        SET_DECIMALES: (state, payload) => {
            state.decimales = payload;
        },

        SET_IMPUESTOS: (state, payload) => {
            state.impuestos = payload;
        },

        SET_TIPO_CAMBIO: (state, payload) => {
            state.tipo_cambio = payload;
        },

        SET_CFDIS: (state, payload) => {
            state.cfdis = payload;
        },

        SET_PRODUCTOS: (state, payload) => {
            state.productos = payload;
        },

        SET_CLIENTES: (state, payload) => {
            state.clientes = payload;
        },

        SET_PAISES: (state, payload) => {
            state.paises = payload;
        },

        SET_ESTADOS: (state, payload) => {
            state.estados = payload;
        },

        SET_DATA_CODIGO_POSTAL: (state, payload) => {
            state.data_codigo_postal = payload;
        },

        SET_CLAVES_UNIDAD: (state, payload) => {
            state.claves_unidad = payload;
        },

        SET_CLAVES_PRODUCTO: (state, payload) => {
            state.claves_producto = payload;
        },

        SET_CLAVES_MATERIAL: (state, payload) => {
            state.claves_material = payload;
        },

        SET_CLAVES_EMBALAJE: (state, payload) => {
            state.claves_embalaje = payload;
        },

        SET_CLAVES_PERMISO_SCT: (state, payload) => {
            state.claves_permiso_sct = payload;
        },

        SET_CLAVES_CONFIG_AUTOTRANSPORTE: (state, payload) => {
            state.claves_config_autotransporte = payload;
        },

        SET_CLAVES_REMOLQUE: (state, payload) => {
            state.claves_remolque = payload;
        },

        SET_USO_CFDI: (state, payload) => {
            state.uso_cfdi = payload;
        },

        SET_REGIMENES: (state, payload) => {
            state.regimenes = payload;
        },

        SET_CONFIG_CARTA_PORTE: (state, payload) => {
            state.config_carta_porte = payload;
        },

        SET_CONFIG: (state, payload) => {
            state.config = payload;
        },

        SET_DATA_COMPANY: (state, payload) => {
            state.data_company = payload;
        },
    },

    actions: {
        fetchSeries: async ({ commit }) => {
            let list = [];
            let { data } = await HTTP.get("/series", {
                params: {
                    list: true,
                    active: true,
                    invoice: true
                }
            });

            data.forEach(item => {
                if (item.tipo == "F" || item.tipo == "T") {
                    list.push(item);
                }
            });
            commit("SET_SERIES", list);
        },

        fetchFormatos: async ({ commit }) => {
            let { data } = await HTTP.get("/data/formats");
            commit("SET_FORMATOS", data);
        },

        fetchFormasPago: async ({ commit }) => {
            let data = await api.fetchFormasPago();
            commit("SET_FORMAS_PAGO", data);
        },

        fetchMonedas: ({ commit }) => {
            let data = dataConfigStorage.getValue("monedas");
            commit("SET_MONEDAS", JSON.parse(data));
        },

        fetchDecimales: ({ commit }) => {
            let data = dataConfigStorage.getValue("decimales");
            commit("SET_DECIMALES", data);
        },

        fetchImpuestos: ({ commit }) => {
            let val_iva = dataConfigStorage.getValue("val_iva");
            let val_retisr = dataConfigStorage.getValue("val_retisr");
            let usa_retisr = dataConfigStorage.getValue("usa_retisr");
            commit("SET_IMPUESTOS", {val_iva:val_iva, val_retisr: val_retisr, usa_retisr:usa_retisr });
        },

        fetchTipoCambio: async ({ commit }) => {
            let { data } = await HTTP.get("/data/currencytc");
            commit("SET_TIPO_CAMBIO", data);
        },

        fetchPaises: async ({ commit }, search) => {
            let { data } = await HTTP.get("/data/countries", {
                params: {
                    search: search
                }
            });
            commit("SET_PAISES", data);
        },

        setPaisesEmpty: ({ commit }) => {
            commit("SET_PAISES", []);
        },

        fetchEstados: async ({ commit }, params) => {
            let { data } = await HTTP.get("/cat/cartaporte/states", {
                params: {
                    search: params.search,
                    country: params.country
                }
            });
            commit("SET_ESTADOS", data);
        },

        setEstadosEmpty: ({ commit }) => {
            commit("SET_ESTADOS", []);
        },

        fetchDataCodigoPostal: async ({ commit }, id) => {
            return new Promise((resolve, reject) => {
                HTTP.get(`/cat/cps/${id}`)
                    .then(response => {
                        resolve(response);
                        commit("SET_DATA_CODIGO_POSTAL", response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },

        validateClaveUnidad: async ({ commit }, unidad) => {
            return new Promise((resolve, reject) => {
                HTTP.get(`/cat/unitssat/${unidad}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },

        fetchClavesUnidad: async ({ commit }, search) => {
            await HTTP.get("/cat/unitssat", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_UNIDAD", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_UNIDAD", []);
                });
        },
        

        setClavesUnidadEmpty: ({ commit }) => {
            commit("SET_CLAVES_UNIDAD", []);
        },

        validateClavesProducto: async ({ commit }, clave) => {
            return new Promise((resolve, reject) => {
                HTTP.get(`/cat/prodclasses/${clave}`, {
                    params: {
                        escp: 1
                    }})
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },

        fetchClavesProducto: async ({ commit }, search) => {
            let { data } = await HTTP.get("/cat/prodclasses", {
                params: {
                    escp: 1,
                    list: true,
                    search: search
                }
            });
            commit("SET_CLAVES_PRODUCTO", data);
        },

        fetchCfdis: async ({commit},search) => {
            let { data } = await HTTP.get("/cfdis", {
                params: {
                    list: true,
                    sort:'idcfdi',
                    order:'desc',
                    number: search
                }
            });
            commit("SET_CFDIS", data);
        },

        fetchClientes: async ({ commit }, search) => {
            let { data } = await HTTP.get("/customers", {
                params: {
                    list: true,
                    search: search
                }
            });
            commit("SET_CLIENTES", data);
        },

        setClavesProductoEmpty: ({ commit }) => {
            commit("SET_CLAVES_PRODUCTO", []);
        },

        fetchClavesMaterial: async ({ commit }, search) => {
            await HTTP.get("/cat/cartaporte/materiales-peligrosos", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_MATERIAL", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_MATERIAL", []);
                });
        },

        setClavesMaterialEmpty: ({ commit }) => {
            commit("SET_CLAVES_MATERIAL", []);
        },

        fetchClavesEmbalaje: async ({ commit }, search) => {
            await HTTP.get("/cat/cartaporte/embalajes", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_EMBALAJE", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_EMBALAJE", []);
                });
        },

        setClavesEmbalajeEmpty: ({ commit }) => {
            commit("SET_CLAVES_EMBALAJE", []);
        },

        fetchClavesPermisoSCT: async ({ commit }, search) => {
            await HTTP.get("/cat/cartaporte/permisos-sct", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_PERMISO_SCT", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_PERMISO_SCT", []);
                });
        },

        setClavesPermisoSCTEmpty: ({ commit }) => {
            commit("SET_CLAVES_PERMISO_SCT", []);
        },

        fetchClavesConfigAutotransporte: async ({ commit }, search) => {
            await HTTP.get("/cat/cartaporte/config-autotransportes", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_CONFIG_AUTOTRANSPORTE", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_CONFIG_AUTOTRANSPORTE", []);
                });
        },

        setClavesConfigAutotransporteEmpty: ({ commit }) => {
            commit("SET_CLAVES_CONFIG_AUTOTRANSPORTE", []);
        },

        fetchClavesRemolque: async ({ commit }, search) => {
            await HTTP.get("/cat/cartaporte/remolques", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_REMOLQUE", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_REMOLQUE", []);
                });
        },

        setClavesRemolqueEmpty: ({ commit }) => {
            commit("SET_CLAVES_REMOLQUE", []);
        },

        fetchUsoCfdi: async ({commit}) => {
            let { data } = await HTTP.get("/cat/cfdiuses");
            commit("SET_USO_CFDI", data);
        },

        fetchRegimenes: async ({commit}) => {
            let { data } = await HTTP.get("/data/regimenes");
            commit("SET_REGIMENES", data);
        },

        fetchConfigCartaPorte: async ({ commit }) => {
            let { data } = await HTTP.get("/config/0?fields=cartaporte");
            commit("SET_CONFIG_CARTA_PORTE", JSON.parse(data.cartaporte));
        },

        fetchConfig: ({ commit }) => {
            return new Promise((resolve, reject) => {
                HTTP.get("/config/0")
                    .then(response => {
                        resolve(response);
                        commit("SET_CONFIG", response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
            
        },
        fetchDataCompany: ({ commit }) => {
            return new Promise((resolve, reject) => {
                HTTP.get("/data/company")
                    .then(response => {
                        resolve(response);
                        commit("SET_DATA_COMPANY", response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
            
        },
    }
};
