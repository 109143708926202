<template>
  <b-modal
    ref="modal-pedimentos"
    id="modal-pedimentos"
    size="lg"
    title="Editar Mercancia"
    ok-title="Guardar"
    cancel-title="Cerrar"
    cancel-variant="light border"
    @hidden="cleanMercanciaForm"
    @ok="handleOk"
  >
    <form
      @submit.stop.prevent="save"
      @keydown.enter.prevent
    >
      <AppDocumentacionAduanera
        ref="documentacionAduanera"
        v-model="documentacion_aduanera"
        @is-valid="isValidDocAduanera = $event"
      />
      <div class="form-row">
        <div class="col-lg-12" v-if="requiredPedimento">
          <input
            type="text"
            class="form-control form-control-sm mb-2"
            placeholder="Pedimento"
            v-model.trim="numPedimento"
            required
          />
          <div class="text-danger">
            <small>{{ errorPedimento }}</small>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import AppDocumentacionAduanera from "@/components/AppDocumentacionAduanera.vue";

export default {
  name: "ModalEditPedimentos",
  components: {
    AppDocumentacionAduanera,
  },
  data() {
    return {
      selectedMercacnias: [],
      errorPedimento: "",

      numPedimento: "",
      pedimentos: [],
      documentacion_aduanera: {
        tipo_documento: null,
        num_pedimento: null,
        rfc_impo: null,
        ident_doc_aduanero: null,
      },

      mercanciaData: {
        documentacion_aduanera: null,
        pedimentos: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      tipoTrasporte: "carta_porte/getTipoTrasporte",
      version: "carta_porte/getVersion",
    }),

    requiredPedimento() {
      if (this.version == "20") {
        if (this.tipoTrasporte.entrada_salida_merc == "Entrada") {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  methods: {
    show(data) {
      this.selectedMercancias = JSON.parse(JSON.stringify(data));
      this.$bvModal.show("modal-pedimentos");
    },

    hide() {
      this.$bvModal.hide("modal-pedimentos");
    },

    formatoPedimento(numPedimento) {
      let anio, aduana, patente, anio_prog_aduana;
      anio = numPedimento.substring(0, 2);
      aduana = numPedimento.substring(2, 4);
      patente = numPedimento.substring(4, 8);
      anio_prog_aduana = numPedimento.substring(8, 15);

      let pedimento =
        anio + "  " + aduana + "  " + patente + "  " + anio_prog_aduana;
      return pedimento;
    },

    isValidMercancia() {
      let isValid = true;
      this.errorPedimento = "";

      if (this.tipoTrasporte.entrada_salida_merc == "Entrada") {
        //if (this.version == "30") {
          this.$refs.documentacionAduanera.validateDoc();
          if (!this.isValidDocAduanera) {
            isValid = false;
          }
/*         } else {
          if (
            !new RegExp("[0-9]{2}  [0-9]{2}  [0-9]{4}  [0-9]{7}").test(
              this.numPedimento
            ) &&
            !new RegExp("[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}").test(
              this.numPedimento
            ) &&
            !new RegExp("[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}").test(
              this.numPedimento
            )
          ) {
            this.errorPedimento = "Numero de pedimento mal formado";
            isValid = false;
          } else if (
            new RegExp("[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}").test(
              this.numPedimento
            )
          ) {
            this.errorPedimento =
              "Numero de pedimento mal formado, separe con 2 espacios cada grupo de dígitos";
          }
        } */
      }

      return isValid;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.save();
    },

    async save() {
      if (!this.isValidMercancia()) return;

      if (this.tipoTrasporte.entrada_salida_merc == "Entrada") {
        //if (this.version == "30") {
          this.mercanciaData.documentacion_aduanera = this.documentacion_aduanera;
          if (this.mercanciaData.documentacion_aduanera.tipo_documento == "01") {
            if (new RegExp("[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}").test(this.mercanciaData.documentacion_aduanera.num_pedimento)) {
              this.mercanciaData.documentacion_aduanera.num_pedimento = this.formatoPedimento(this.mercanciaData.documentacion_aduanera.num_pedimento);
            }
          }
     /*    } else {
          if (new RegExp("[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}").test( this.numPedimento)) {
            this.pedimentos.push(this.formatoPedimento(this.numPedimento));
          } else {
            this.pedimentos.push(this.numPedimento);
          }
          this.mercanciaData.pedimentos = this.pedimentos;
        } */
      }

      this.selectedMercancias.forEach((item) => {
        
        item.pedimentos = this.mercanciaData.pedimentos ;
        item.documentacion_aduanera = this.mercanciaData.documentacion_aduanera;

        this.$store.dispatch("carta_porte/editMercanciasItem", item);
      });

      this.cleanMercanciaForm();

      this.$nextTick(() => {
        this.$bvModal.hide("modal-pedimentos");
      });
    },

    cleanMercanciaForm() {
      this.selectedMercacnias = [];
      this.errorPedimento = "";
      this.numPedimento = "";
      this.pedimentos = [];

      this.mercanciaData.documentacion_aduanera = null;
      this.mercanciaData.pedimentos = null;

      this.documentacion_aduanera.tipo_documento = null;
      this.documentacion_aduanera.num_pedimento = null;
      this.documentacion_aduanera.rfc_impo = null;
      this.documentacion_aduanera.ident_doc_aduanero = null;
    },
  },
};
</script>
