<template>
  <div v-if="requiredDocumentacionAduanera">
    <h5 class="mb-3">Documentación Aduanera</h5>
    <div class="form-row">
      <div class="col-lg-3">
        <select
          class="custom-select custom-select-sm mb-2"
          v-model="documentacion_aduanera.tipo_documento"
          required
        >
          <option value="null">Seleccione Tipo Documento</option>
          <option value="01">Pedimento</option>
          <option value="02">Autorización de importación temporal</option>
          <option value="03">Otro</option>
        </select>
        <div class="text-danger">
          <small>{{ errorTipoDocumento }}</small>
        </div>
      </div>
      <div
        v-if="documentacion_aduanera.tipo_documento == '01'"
        class="col-lg-5"
      >
        <input
          type="text"
          class="form-control form-control-sm mb-2"
          placeholder="Num. Pedimento"
          v-model.trim="documentacion_aduanera.num_pedimento"
          required
        />
        <div class="text-danger">
          <small>{{ errorPedimento }}</small>
        </div>
      </div>
      <div
        v-if="documentacion_aduanera.tipo_documento == '01'"
        class="col-lg-4"
      >
        <input
          type="text"
          class="form-control form-control-sm mb-2"
          placeholder="RFC del Importador"
          v-model.trim="documentacion_aduanera.rfc_impo"
          maxlength="13"
          required
        />
        <div class="text-danger">
          <small>{{ errorRfcImport }}</small>
        </div>
      </div>
      <div
        v-if="
          documentacion_aduanera.tipo_documento == '02' ||
          documentacion_aduanera.tipo_documento == '03'
        "
        class="col-lg-4"
      >
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Identificador o Folio del Documento Aduanero"
          v-model.trim="documentacion_aduanera.ident_doc_aduanero"
          required
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AppDocumentacionAduanera",
  props: {
    documentacionAduanera: {
      type: Object,
      default(rawProps) {
        return {
          tipo_documento: null,
          num_pedimento: null,
          rfc_impo: null,
          ident_doc_aduanero: null,
        };
      },
    },
  },
  data() {
    return {
      errorRfcImport:"",
      errorPedimento: "",
      errorTipoDocumento: "",
      documentacion_aduanera: this.documentacionAduanera,
    };
  },
  computed: {
    ...mapGetters({
      version: "carta_porte/getVersion",
      tipoTrasporte: "carta_porte/getTipoTrasporte",
    }),

    requiredDocumentacionAduanera() {
      //if (this.version == "30") {
        if (this.tipoTrasporte.entrada_salida_merc == "Entrada") {
          return true;
        } else {
          return false;
        }
      //}
    },
  },
  mounted() {},
  watch: {
    "documentacion_aduanera.tipo_documento"(newValue) {
      this.$emit("input", this.documentacion_aduanera);
      this.changeTipoDocumento();
    },
  },
  methods: {
    changeTipoDocumento: function () {
      this.documentacion_aduanera.num_pedimento = null;
      this.documentacion_aduanera.rfc_impo = null;
      this.documentacion_aduanera.ident_doc_aduanero = null;
    },

    validateDoc: function () {
        let isValid = true;
        this.errorTipoDocumento = ""; 
        this.errorRfcImport = "";
        this.errorPedimento = "";

        if(this.documentacion_aduanera.tipo_documento == null || this.documentacion_aduanera.tipo_documento == "null"){
            this.errorTipoDocumento = "Tipo de Documento indefinido";
            isValid = false;
        }

        if(this.documentacion_aduanera.tipo_documento == "01"){
                if(!new RegExp('[0-9]{2}  [0-9]{2}  [0-9]{4}  [0-9]{7}').test(this.documentacion_aduanera.num_pedimento) 
                && !new RegExp('[0-9]{2}[0-9]{2}[0-9]{4}[0-9]{7}').test(this.documentacion_aduanera.num_pedimento) 
                && !new RegExp('[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}').test(this.documentacion_aduanera.num_pedimento)){
                    this.errorPedimento = "Numero de pedimento mal formado";
                    isValid = false;
                }else if(new RegExp('[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}').test(this.documentacion_aduanera.num_pedimento)){
                    this.errorPedimento = "Numero de pedimento mal formado, separe con 2 espacios cada grupo de dígitos";
                    isValid = false;
                }

                if(this.documentacion_aduanera.rfc_impo == null || this.documentacion_aduanera.rfc_impo.length < 12 ){
                    this.errorRfcImport = "RFC del importador invalido";
                    isValid = false;
                }

                if(this.documentacion_aduanera.tipo_documento == null || this.documentacion_aduanera.tipo_documento == "null"){
                    this.errorTipoDocumento = "Tipo de documento indefinido";
                    isValid = false;
                }
        }

        this.$emit("is-valid", isValid)
    }
  },
};
</script>
