<template>
  <div class="form-row">
    <div class="col-lg-4">
      <VueBootstrapTypeaheadCustom
        ref="bienes_transp"
        :data="listClavesProducto"
        v-model.trim="selectedClaveSat"
        :serializer="(s) => s.clavesat"
        placeholder="Clave SAT"
        @hit="setSelectedClaveProducto"
        @input="searchClaveProducto"
        class="mb-2"
        size="sm"
        :minMatchingChars="1"
        :rawResults="true"
        required
      >
        <template v-slot:suggestion="slotProp">
          <span class="badge badge-secondary">{{
            slotProp.data.clavesat
          }}</span>
          {{ slotProp.data.descripcion }}
        </template>
      </VueBootstrapTypeaheadCustom>
      <div class="text-danger">
        <small>{{ errorClaveSat }}</small>
      </div>
    </div>
    <div class="col-lg-8">
      <input
        type="text"
        class="form-control form-control-sm mb-2"
        placeholder="Descripción"
        v-model="producto_sat.descripcion"
        required
      />
      <div class="text-danger">
        <small>{{ errorDescripcion }}</small>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
  name: "AppDocumentacionAduanera",
  components: { VueBootstrapTypeaheadCustom },
  props: {
    productoSat: {
      type: Object,
      default(rawProps) {
        return {
          bienes_transp: "",
          descripcion: "",
          is_material_peligroso: null,
        };
      },
    },
  },
  data() {
    return {
      errorClaveSat: "",
      errorDescripcion: "",
      selectedClaveSat: "",
      producto_sat: this.productoSat,
    };
  },
  computed: {
    ...mapGetters({
      listClavesProducto: "catalogos/listClavesProducto",
    }),

  },
  mounted() {},
  watch: {
    "producto_sat.bienes_transp"(newValue) {
      this.selectedClaveSat = newValue;
      this.$emit("input", this.producto_sat);
    },
  },
  methods: {
    setFocusBienesTransp() {
      this.$refs.bienes_transp.setFocus();
    },
    searchClaveProducto(query) {
      this.producto_sat.descripcion = "";
      this.$store.dispatch("catalogos/fetchClavesProducto", query);
    },

    setSelectedClaveProducto(value) {
      this.producto_sat.bienes_transp = value.clavesat;
      this.producto_sat.descripcion = value.descripcion;
      this.producto_sat.is_material_peligroso = value.material_peligroso;

      setTimeout(() => {
        this.$store.dispatch("catalogos/setClavesProductoEmpty");
      }, 200);
    },

    async validateProductoSat() {
      let isValid = true;
      this.errorClaveSat = "";
      this.errorDescripcion = "";

      if (
        (this.producto_sat.bienes_transp == "" || this.producto_sat.bienes_transp != this.selectedClaveSat) &&
        this.producto_sat.descripcion != ""
      ) {
        await this.$store
          .dispatch(
            "catalogos/validateClavesProducto",
            this.selectedClaveSat.trim()
          )
          .then((response) => {
            this.producto_sat.bienes_transp = response.data.clavesat;
            this.producto_sat.is_material_peligroso =
              response.data.material_peligroso;
            this.$emit("input", this.producto_sat);
            
          })
          .catch((error) => {
            if (error.data.msg) {
              this.errorClaveSat = error.data.msg;
            } else {
              this.errorClaveSat = "Unidad no encontrada";
            }
            isValid = false;
          });
      }

      if (this.producto_sat.bienes_transp == "") {
        this.errorClaveSat = "Clave del Producto/Servicio debe de ser numerica";
        isValid = false;
      }

      if (this.producto_sat.bienes_transp.length != 8) {
        this.errorClaveSat =
          "Clave del Producto/Servicio Invalida, minimo 8 digitos";
        isValid = false;
      }

      if (
        this.producto_sat.descripcion.length < 4 ||
        this.producto_sat.descripcion == ""
      ) {
        this.errorDescripcion = "Descripción debe de ser más extensa.";
        isValid = false;
      }

      if (this.producto_sat.descripcion.length >= 1000) {
        this.errorDescripcion =
          "Descripción del producto o servicio es muy extensa, máximo 1,000 caracteres";
        isValid = false;
      }

      if (this.producto_sat.descripcion.indexOf("|") > 1) {
        this.errorDescripcion =
          "Descripción del producto o servicio tiene el caracter invalido '|' ";
        isValid = false;
      }

      this.$emit("is-valid", isValid);
    },
  },
};
</script>
