<template>
    <b-modal ref="modal-add-serie"
                id="modal-add-serie"
             title="Nueva Serie de Factura"
             ok-title="Guardar"
             cancel-title="Cerrar"
             cancel-variant="light border"
             @shown="setInitFocus()"
             @hidden="cleanForm()"
             @ok="handleOk">
        <form @submit.stop.prevent="save()">
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label>Serie:</label>
                    <input ref="serie"
                           type="text"
                           v-model.trim="form.serie"
                           class="form-control form-control-sm"
                           placeholder="Se sugiere utilizar una letra"
                           required />
                </div>
                <div class="form-group col-lg-6">
                    <label>Folio Siguiente:</label>
                    <input type="number"
                           v-model.trim="form.foliosig"
                           class="form-control form-control-sm"
                           required />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-lg-6">
                    <label>Tipo:</label>
                    <select v-model.trim="form.tipo" 
                            class="custom-select custom-select-sm" 
                            required>
                        <option value="F">Factura</option>
                        <option value="T">Traslado</option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label>Formato:</label>
                    <select
                        class="custom-select custom-select-sm"
                        v-model="form.idfto"
                        required>
                        <option selected value="">Seleccione Formato</option>
                        <option
                            v-for="item in listFormatosCartaPorte"
                            :key="item.idfto"
                            :value="item.idfto"
                        >
                            {{ item.descripcion }}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ModalAddSerie",
    data() {
        return {
            form: {
                serie: "CP",
                tipo: "F",
                foliosig: 1,
                idfto: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            listFormatos: "catalogos/listFormatos",
        }),
        listFormatosCartaPorte: function () {
            return this.listFormatos.filter(i => i.idfto == 14)
        },
    },
    methods: {
        setInitFocus(){
             this.$refs.serie.focus();
        },

        initComponents(){
            this.$store.dispatch("catalogos/fetchFormatos").then(() => {
                let formatosCartaPorte = this.listFormatos.filter(i => i.idfto == 14)

                if(formatosCartaPorte.length == 1){
                    this.form.idfto = formatosCartaPorte[0].idfto
                }
            });
        },

        show() {
            this.$bvModal.show("modal-add-serie");   
            this.initComponents();
        },

        hide() {
            this.$bvModal.hide("modal-add-serie");
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.save()
        },

        save() {
            if(this.form.serie == "" || this.form.idfto == ""){
                return
            }

            this.$store.dispatch("cfdi/addSerie", this.form).then(()=>{
                this.$store.dispatch("catalogos/fetchSeries");
            }).catch((error) => {
                this.$bvModal.hide("modal-add-serie");
                this.$bvModal  
                .msgBoxOk(error.data.msg, {
                    title: "Error al agregar serie",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "primary",
                    headerClass: "p-2 border-bottom-0",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {});
            });

            this.$nextTick(() => {
                this.$bvModal.hide("modal-add-serie");
            })   
        },

        cleanForm() {
            this.form.serie = "";
            this.form.tipo = "F";
            this.form.oliosig = 1;
            this.form.idfto = "";
        },
    },
};
</script>   