import axios from "axios";
import { cookie } from "@/utils/cookies";

export const APIHOST = location.protocol + '//' + location.host;

export const HTTP = axios.create({
    baseURL: APIHOST + "/api/v2",
    headers: {
        "Content-Type": "application/json",
    },
});

HTTP.interceptors.request.use(
    (config) => {
        let token = cookie.getToken()
        if (token) {
            config.headers = { "X-Token": token };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

HTTP.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.open("https://factura123.mx/app/#login","_self") 
            console.log(error)
        }
        return Promise.reject(error);
    }
);
