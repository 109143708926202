export function filterUUID(value) {
    if (!value) return '-';
    if (value.length < 32) return value;
    let cadena = ''
    value = value.toUpperCase();
    cadena += value.substring(0, 8) + '-';
    cadena += value.substring(8, 12) + '-';
    cadena += value.substring(12, 16) + '-';
    cadena += value.substring(16, 20) + '-';
    cadena += value.substring(20, 32);
    return cadena;
}

export function truncate(text, n) {
    return text ? (text.substr(0, n - 1) + (text.length > n ? '...' : '')) : '';
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
}

// dateMX convierte la fecha en español dependiendo de la zona horaria donde se encuentre el cliente
// la hora del servidor esta configurada a MST (UTC-07:00) y hace un calculo para que coincida
// con la zona horaria. Se puede usar como filter de VUE o como funcion.
export function dateMX(date, showHour, showShort) {

    if (!date) return '-';
    // Se agrega la hora relativa a MST
    // Se agrega una T para que funcione con internet explorer
    date = date.split(' ').join('T');
    date += '-07:00';
    let mydate = new Date(date), rdate = '';
    let hours = mydate.getHours();
    let minutes = mydate.getMinutes();
    let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
    let ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;


    rdate = mydate.getDate() + '-' + months[mydate.getMonth()] + '-';
    rdate += showShort ? mydate.getFullYear().toString().substring(2, 4) : mydate.getFullYear();
    rdate += showHour ? '  ' + hours + ':' + minutes + ampm : "";

    return rdate;
}

export function round(number, decimals) {
    return parseFloat(parseFloat(number).toFixed(decimals));  
}

export function isObjectEmpty(objectName) {
    return Object.keys(objectName).length === 0
}

