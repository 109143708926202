<!-- https://es.vuejs.org/v2/guide/components#Usando-v-model-en-Componentes -->
<template>
    <div>
        <VueBootstrapTypeaheadCustom
            :data="listPaises"
            v-model.trim="pais_sat.pais_descripcion"
            :serializer="(s) => s.descripcion"
            :placeholder="placeHolder"
            @hit="setSelectedPais"
            @input="searchPais"
            :prepend="pais_sat.pais"
            :rawResults="true"
            :minMatchingChars="1"
            size="sm"
            class="mb-2"
            required
        >
            <template v-slot:suggestion="slotProp">
                <small class="badge badge-secondary">{{
                    slotProp.data.clave
                }}</small
                >&nbsp;{{ slotProp.data.descripcion }}
            </template>
        </VueBootstrapTypeaheadCustom>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
    name: "AppPaisSat",
    components: { VueBootstrapTypeaheadCustom },
    props: {
        placeHolder: {
            type: String,
            default: "Pais",
        },
        value: {
            type: Object,
            default(rawProps) {
                return {
                    pais: "",
                    pais_descripcion: "",
                };
            },
        },
    },
    data() {
        return {
            pais_sat: {
                pais: this.value.pais == null ? "" : this.value.pais,
                pais_descripcion:
                    this.value.pais_descripcion == null
                        ? ""
                        : this.value.pais_descripcion,
            },
        };
    },
    computed: {
        ...mapGetters({
            listPaises: "catalogos/listPaises",
        }),
    },

    watch: {
        "value.pais"(value) {
            (this.pais_sat.pais =
                this.value.pais == null ? "" : this.value.pais),
                (this.pais_sat.pais_descripcion =
                    this.value.pais_descripcion == null
                        ? ""
                        : this.value.pais_descripcion);
        },

        "pais_sat.pais"(value) {
            this.$emit("input", this.pais_sat);
        },

        "pais_sat.pais_descripcion"(value) {
            if (value == "") {
                this.pais_sat.pais = "";
                this.pais_sat.pais_descripcion = "";
                this.$emit("input", this.pais_sat);
            }
        },
    },
    methods: {
        searchPais(query) {
            this.$emit("input", this.pais_sat);
            this.$store.dispatch("catalogos/fetchPaises", query);
        },

        setSelectedPais(pais) {
            this.pais_sat.pais = pais.clave;
            this.pais_sat.pais_descripcion = pais.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setPaisesEmpty");
            }, 200);
        },
    },
};
</script>
