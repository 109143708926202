import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/floating-labels.css'
import { dateMX, formatMoney, truncate } from '@/utils/filters';
import { BListGroup,BListGroupItem, NavbarPlugin, FormCheckboxPlugin, BFormTextarea, BFormCheckboxGroup, BInputGroup, BDropdown, BDropdownItem, BTable, BCard, BTabs, BTab, BFormDatepicker, BFormTimepicker, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEraser, faSquare, faCheckSquare, faSave, faTrashAlt, faInfoCircle,faSearch, faPencilAlt, faEye, faFileInvoice, faFileCode, faTimes, faPlus, faArrowAltCircleRight, faArrowAltCircleLeft, faCogs, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

library.add(faEraser, faSquare, faCheckSquare, faSave, faTrashAlt, faInfoCircle,faSearch, faPencilAlt, faEye, faFileInvoice, faFileCode, faTimes, faPlus, faArrowAltCircleRight, faArrowAltCircleLeft, faCogs, faPen)

Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('v-select', vSelect)
Vue.component('b-table', BTable)
Vue.component('b-card', BCard)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-input-group', BInputGroup) 
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-form-timepicker', BFormTimepicker)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-list-group', BListGroup)
Vue.component('b-list-group-item', BListGroupItem)

Vue.filter('truncate', truncate);
Vue.filter('money', formatMoney);
Vue.filter('dateMX', dateMX);

Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormCheckboxPlugin)

Vue.config.productionTip = false

store.subscribe((mutation, state) => { 
  var moduloMutado = mutation.type.split("/")[0];
 
  if(moduloMutado == "cfdi"){
    localStorage.setItem('f123-invoice-cfdi', JSON.stringify(state.cfdi));
  }

  if(moduloMutado == "carta_porte"){
    localStorage.setItem('f123-invoice-carta-porte', JSON.stringify(state.carta_porte));
  }
  
});

new Vue({
  router,
  store,
  beforeCreate() {
    console.log("initialise_store")
    this.$store.commit('initialise_store');
  },
  render: h => h(App)
}).$mount('#app')
