<template>
  <div class="row ml-3 mr-3">
    <div class="">
        <b-form-checkbox
          :id="'Servicio#' + i"
          v-model="status"
          :name="'Servicio#' + i"
          value="Activado"
          unchecked-value="Desactivado">
          <div>
            Servicio: <strong>{{ status }}</strong>
          </div>
        </b-form-checkbox>
    </div>

    <form
      class="row"
      ref="formFlete"
      @submit.prevent="saveFlete()"
      @keydown.enter.prevent
    >
      <div class="col-lg-6 col-md-12 row">
        <!--Titulo de Servicio Nuevo-->
        <div class="col-12">
          <div class="form-group row pt-3">
            <label for="" class="col-sm-2 col-form-label">Titulo</label>
            <div class="form-group col-7">
              <input
                maxlength="20"
                type="text"
                class="form-control"
                placeholder="Titulo del servicio"
                id=""
                v-model.trim="form.nombre"
              />
            </div>
          </div>
        </div>

        <!--Clave SAT-->
        <div class="col-12 mb-1">
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">Clave SAT</label>
            <div class="form-group col-7 mb-1">
              <VueBootstrapTypeaheadCustom
                ref="bienes_transp"
                :data="listClavesProducto"
                v-model="selectedProducto"
                :serializer="(s) => s.clavesat"
                placeholder="Clave SAT"
                @hit="setSelectedClaveProducto"
                @input="searchClaveProducto"
                :rawResults="true"
              >
                <template v-slot:suggestion="slotProp">
                  <span class="badge badge-secondary">{{
                    slotProp.data.clavesat
                  }}</span>
                  {{ slotProp.data.descripcion }}
                </template>
              </VueBootstrapTypeaheadCustom>
            </div>
          </div>
        </div>

        <!--Descripcion Clave SAT-->
        <div class="col-md-7 offset-md-2 mb-4">
          <textarea
            type="text"
            class="form-control c"
            placeholder="Descripción"
            v-model="flete.descripcion"
            readonly
          />
        </div>

        <!--Unidad SAT-->
        <div class="col-12">
          <div class="form-group row">
            <label for="" class="col-sm-2 col-form-label">Unidad SAT</label>
            <div class="form-group col-7">
              <VueBootstrapTypeaheadCustom
                :data="listClavesUnidad"
                v-model="selectedUnidad"
                :serializer="(s) => s.clave + ' ' + s.descripcion"
                placeholder="Unidad SAT"
                @hit="setSelectedClaveUnidad"
                @input="searchClaveUnidad"
                :rawResults="true"
                required
              >
                <template v-slot:suggestion="slotProp">
                  <span class="badge badge-secondary">{{
                    slotProp.data.clave
                  }}</span>
                  {{ slotProp.data.descripcion }}
                </template>
              </VueBootstrapTypeaheadCustom>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 row">
        <!--IVA-->
        <div class="col-12">
          <div class="form-group col-12 row pt-3">
            <label for="" class="col-sm-2 col-form-label">IVA</label>
            <input
              type="text"
              class="form-control col-3"
              placeholder="IVA"
              v-model="form.precio"
              id=""
            />
          </div>
        </div>

        <!--Ret IVA-->
        <div class="col-12">
          <div class="form-group col-12 row">
            <label for="" class="col-sm-2 col-form-label">Ret IVA</label>
            <input
              type="text"
              class="form-control col-3"
              placeholder="Ret IVA"
              id=""
              v-model="form.retIva"
            />
          </div>
        </div>

        <!--Ret ISR-->
        <div class="col-12">
          <div class="form-group col-12 row">
            <label for="" class="col-sm-2 col-form-label">Ret ISR</label>
            <input
              type="text"
              class="form-control col-3"
              placeholder="Ret ISR"
              id=""
              v-model="form.retIsr"
            />
          </div>
        </div>
      </div>
    </form>
    
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
  name: "TabViewFlete",
  components: { VueBootstrapTypeaheadCustom },
  data() {
    return {
      status: "Desactivado",

      form: {
        nombre: "",
        unidadSat: "",
        claveSat: "",
        precio: "",
        retIva: "",
        retIsr: "",
      },
      selectedProducto: "",
      selectedUnidad: "",
      flete: {
        descripcion: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      listClavesProducto: "flete/listClavesProducto",
      listClavesUnidad: "flete/listClavesUnidad",
    }),
    ...mapGetters({
      fletesConfig: "flete/item",
    }),
  },

  methods: {
    loadData() {
      this.form.nombre = this.fletesConfig.clave.trim();
      this.form.unidadSat = this.fletesConfig.unidadSat.trim();
      this.form.claveSat = this.fletesConfig.claveSat.trim();
      this.form.iva = this.fletesConfig.precio.trim();
      this.form.retIva = this.fletesConfig.retIva.trim();
      this.form.retIsr = this.fletesConfig.retIsr.trim();
    },

    searchClaveProducto(query) {
      this.flete.descripcion = "";
      this.$store.dispatch("flete/fetchClavesProducto", query);
    },

    setSelectedClaveProducto(value) {
      this.flete.bienes_transp = value.clavesat;
      this.flete.descripcion = value.descripcion;

      setTimeout(() => {
        this.$store.dispatch("flete/setClavesProductoEmpty");
      }, 200);
      this.$refs.valor_flete.focus();
    },

    searchClaveUnidad(query) {
      this.$store.dispatch("flete/fetchClavesUnidad", query);
    },

    setSelectedClaveUnidad(value) {
      this.flete.clave_unidad = value.clave;
      this.flete.unidad = value.descripcion;
      setTimeout(() => {
        this.$store.dispatch("flete/setClavesUnidadEmpty");
      }, 200);
      this.$refs.bienes_transp.setFocus();
    },

    cleanForm() {
      this.selectedProducto = "";
    },
  },
};
</script>
