<template>
    <b-navbar
        fixed
        toggleable="md"
        type="light"
        variant="light"
        class="shadow-sm"
    >
        <b-navbar-brand href="https://factura123.mx/app">
            <img
                src="@/assets/logo.png"
                height="25"
                class="d-inline-block align-top"
            />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="text-center" small>
                <b-nav-text><b>Emitir Carta de Porte</b></b-nav-text>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav small class="ml-auto">
                <b-nav-text class="mr-2">
                    <b>{{dataCompany.rfc}}</b> - 
                    {{dataCompany.nombre}}
                </b-nav-text>
                <b-nav-item :to="{ name: 'AjustesCartaPorte' }" disabled>
                    <font-awesome-icon icon="cogs" />
                    Ajustes
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "TheNavbar",
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            dataCompany: "catalogos/getDataCompany",
        }),
    },
};
</script>
<style>
.navbar {
    padding: 0 1rem !important;
}
</style>

