/**
 * Rutas 
 */
 import { HTTP } from "@/core/init";

  export default {
    fetchFormasPago: async  () => {
        let  {data}  = await HTTP.get("/data/payforms");
        return data;
    },
  }