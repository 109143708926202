import { HTTP } from "@/core/init";
import { cookie } from "@/utils/cookies";
export default {
    namespaced: true,
    state: {
        token: cookie.getToken(),
    },

    mutations: {
        SET_USER_AUTH(state) {
            state.token = cookie.getToken()
        }
    },

    actions: {
        loginAuth: async ({ commit }) => {
            await HTTP.post('/public/login', {
                user: "AAA010101AAA",
                passwd: "asidefaciles"
            }, {
                headers: { 'X-Unique': "2cf24dba5fb0a30e26e83b2ac5b9e29e1b161e5c1fa7425e73043362938b9824" }
            }).then((response) => {
                cookie.setToken(response.data.token);
                commit('SET_USER_AUTH');
            }).catch((error) => {
                cookie.remove();
                commit('SET_USER_AUTH');
            });

        },
    }
};
