<template>
    <b-modal ref="modal-cfdis-relacionados"
    id="modal-cfdis-relacionados"
             size="lg"
             title="Folios de CFDIs a Relacionar"
             @shown="setInitFocus()"
             @hidden="cleanForm"
             @ok="handleOk">
        <form @submit.stop.prevent="save">
            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                <b><font-awesome-icon icon="info-circle" /> Importante</b>: El CFDI a relacionar <b>ya no podrá cancelarse,</b> recuerde que si va a cancelar el CFDI a relacionar deberá hacerlo antes de timbrar esta factura.
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <label>Seleccione Tipo Relaci&oacute;n</label>
                    <select class="custom-select custom-select-sm"
                            ref="claveTipoRelacion"
                            v-model="form.cfdisTipoRelacion">
                            <option value="" selected>Seleccione</option>
                            <option value="01">01 - Nota de crédito de los documentos relacionados</option>
                            <option value="02">02 - Nota de débito de los documentos relacionados</option>
                            <option value="03">03 - Devolución de mercancía sobre facturas o traslados previos</option>
                            <option value="04">04 - Sustitución de los CFDI previos</option>
                            <option value="05">05 - Traslados de mercancias facturados previamente</option>
                            <option value="06">06 - Factura generada por los traslados previos</option>
                            <option value="07">07 - CFDI por aplicación de anticipo</option>
                            <option value="08">08 - Factura generada por pagos en parcialidades</option>
                            <option value="09">09 - Factura generada por pagos diferidos</option>
                    </select>
                    <div class="text-danger">
                        <small>{{ errorTipoRelacion }}</small>
                    </div>
                </div>
                <div class="form-group col-12">
                    <label>Ingrese Folios de CFDI's Relacionados</label>
                    <v-select 
                        ref="selectSearchCfdis"
                        multiple 
                        v-model="form.cfdisRelacionados"
                        label="factura"
                        @search="searchCfdis" 
                        :selectable="isValidCfdiOption"
                        :options="listCfdis"
                        :filterable="false" >
                        
                    <template #option="option">
                        <span class="badge badge-secondary">{{ option.factura }}</span>
                        &nbsp;<small><b>{{ option.rfc }}</b></small>&nbsp;
                        <span class="badge badge-secondary">{{ option.fecha | dateMX }}</span>
                        &nbsp;${{ option.total | money }}
                    </template>  
                    <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                            <span class="text-danger"> No se encontro Documento</span>
                        </template>
                        <em v-else style="opacity: 0.5">Ingrese Folio de CFDI</em>
                    </template>  
                    </v-select>
                    <div class="text-danger">
                        <small>{{ errorCfdisRelacionados }}</small>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="form-check">
                        <input v-model="isCfdiUuid" class="form-check-input" type="checkbox" >
                        <label class="form-check-label">
                            Relacionar por UUID
                        </label>
                    </div>
                </div>
                <div v-if="isCfdiUuid" class="form-group col-12">
                    <label>Ingrese UUID de CFDI's Relacionados</label>
                    <div class="input-group input-group-sm">
                        <input type="text"
                               v-model="cfdiUuid"
                               class="form-control form-control-sm"
                               placeholder="00000000-0000-0000-0000-000000000000"
                               maxlength="36"
                               required />
                        <div class="input-group-append">
                            <button @click.prevent="addCfdiUuid()" class="btn btn-light border" type="button" id="button-addon2">
                                <font-awesome-icon icon="plus" />
                            </button>
                        </div>
                    </div>
                    <div class="text-danger">
                        <small>{{ errorCfdisUuid }}</small>
                    </div>
                </div>
            </div>
        </form>
        <template #modal-footer="{ ok, cancel }">
            
            <button class="btn btn-light border" @click="removeCfdisRel()">
                <font-awesome-icon icon="trash-alt" /> Remover CFDIs Relacionados
            </button>
            <button class="btn btn-danger" @click="cancel()">
               <font-awesome-icon icon="times" /> Cancelar
            </button>
            <button class="btn btn-primary" @click="ok()">
                <font-awesome-icon icon="save" /> Guardar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ModalCfdisRelacionados",
    data() {
        return {
            errorTipoRelacion:"",
            errorCfdisRelacionados: "",
            errorCfdisUuid: "",
            isCfdiUuid:false,
            cfdiUuid:"",
            form: {
                cfdisTipoRelacion: null,
                cfdisRelacionados: []
            },
        };
    },
    watch: {
        "form.cfdisTipoRelacion"(value) {
            if (value != "") {
               this.errorTipoRelacion = ""
            }
        },

        "form.cfdisRelacionados"(value) {
            if (value != "") {
               this.errorCfdisRelacionados = ""
            }
        },

        "cfdiUuid"(value) {
            if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value)) {
               this.errorCfdisUuid = ""
            }
        },
    },
    computed: {
        ...mapGetters({
            dataCfdisRelacionados: "cfdi/getCfdisRelacionados",
            listCfdis: "catalogos/listCfdis",
        }),
    },
    methods: {
        setInitFocus(){
            //this.$refs.selectSearchCfdis.focus();
        },

        show() {   
            this.loadData();        
            this.$bvModal.show("modal-cfdis-relacionados");   
        },

        hide() {
            this.$bvModal.hide("modal-cfdis-relacionados");
        },

        loadData() {
            this.form.cfdisTipoRelacion = this.dataCfdisRelacionados.cfdisTipoRelacion;
            this.form.cfdisRelacionados = this.dataCfdisRelacionados.cfdisRelacionados;
        },
        
        searchCfdis(search, loading) {
            if(search.length) {
                loading(true);
                this.$store.dispatch("catalogos/fetchCfdis", search).then(res => {
                    loading(false);
                });
            }
           
        },

        cfdiSelectedOption(option){
            console.log(option)
            /*let indexOption = this.form.cfdisRelacionados.findIndex((item) => item.uuid == option.uuid.replace(/-/g, ""));
            console.log(indexOption)
             this.form.cfdisRelacionados.some(({uuid})=>{
                if (this.cfdiUuid.replace(/-/g, "") == uuid){
 
                }
            })*/

            //this.form.cfdisRelacionados.splice(indexOption, 1);
        },

        isValidCfdiOption(option) {
            if(this.form.cfdisRelacionados.some(({uuid})=> uuid == option.uuid)){
                return false
            }
            return true
        },

        isValidCfdiUuid(){
            let isValid = true;
            this.errorCfdisUuid = "";

            if (!(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(this.cfdiUuid))) {
                this.errorCfdisUuid = 'UUID inválido, debe de ser de 36 dígitos';
                isValid = false;
            } 

            if(this.form.cfdisRelacionados.length){
                this.form.cfdisRelacionados.some(({uuid})=>{
                    if (this.cfdiUuid.replace(/-/g, "") == uuid){
                        this.errorCfdisUuid = 'El documento ya fue relacionado';
                        isValid = false;
                    }
				})
            }
               
            
            return isValid;
        },

        addCfdiUuid(){
            if (!this.isValidCfdiUuid()) return;

            this.form.cfdisRelacionados.push({
                factura: this.cfdiUuid,
                uuid: this.cfdiUuid.replace(/-/g, "")
            });

            this.cfdiUuid = "";

        },


        async removeCfdisRel(){
            this.form.cfdisTipoRelacion = null;
            this.form.cfdisRelacionados = [];

            await this.$store.dispatch("cfdi/setCfdisRel", this.form);
            
            this.$nextTick(() => {
                this.$bvModal.hide("modal-cfdis-relacionados");
            })
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.save()
        },

        async save() {
            if (!this.isValidCfidsRel()) return;

            await this.$store.dispatch("cfdi/setCfdisRel", this.form);
            
            this.$nextTick(() => {
                this.$bvModal.hide("modal-cfdis-relacionados");
            })
            
        },
        
        isValidCfidsRel() {
            let isValid = true;
            this.errorTipoRelacion = "";
            this.errorCfdisRelacionados = "";

            if (this.form.cfdisTipoRelacion == "" || this.form.cfdisTipoRelacion == null){
                this.errorTipoRelacion = "Debe de Seleccionar Tipo de Relación";
                isValid = false;
            }

            if (!this.form.cfdisRelacionados.length) {
                this.errorCfdisRelacionados = "Debe de Seleccionar CFDIs a Relacionar";
                isValid = false;
            }
            
            return isValid;
        },

        cleanForm() {
            this.isCfdiUuid = false;
            this.cfdiUuid = "";

            this.form.cfdisTipoRelacion = "";
            this.form.cfdisRelacionados = "";

            this.errorTipoRelacion = "";
            this.errorCfdisRelacionados = "";
            this.errorCfdisUuid = "";
        },
    },
};
</script>   