import { HTTP } from "@/core/init";

export default {
    namespaced: true,
    state: {
        claves_unidad: [],
        claves_producto: [],
        item: {},
    },

    getters: {
        listClavesUnidad: state => {
            return state.claves_unidad;
        },

        listClavesProducto: state => {
            return state.claves_producto;
        },

        item: (state) => {
            return state.item;
        },
    },

    mutations: {
        SET_CLAVES_UNIDAD: (state, payload) => {
            state.claves_unidad = payload;
        },

        SET_CLAVES_PRODUCTO: (state, payload) => {
            state.claves_producto = payload;
        },

        SET_CONFIG: (state, payload) => {
            state.item = payload;
        },


    },

    actions: {




        fetchClavesUnidad: async ({ commit }, search) => {
            await HTTP.get("/cat/unitssat", {
                params: {
                    list: true,
                    search: search
                }
            })
                .then(response => {
                    commit("SET_CLAVES_UNIDAD", response.data);
                })
                .catch(() => {
                    commit("SET_CLAVES_UNIDAD", []);
                });
        },

        setClavesUnidadEmpty: ({ commit }) => {
            commit("SET_CLAVES_UNIDAD", []);
        },

        fetchClavesProducto: async ({ commit }, search) => {
            let { data } = await HTTP.get("/cat/prodclasses", {
                params: {
                    list: true,
                    search: search
                }
            });
            commit("SET_CLAVES_PRODUCTO", data);
        },


        setClavesProductoEmpty: ({ commit }) => {
            commit("SET_CLAVES_PRODUCTO", []);
        },

    }
};
