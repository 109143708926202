<!-- https://es.vuejs.org/v2/guide/components#Usando-v-model-en-Componentes -->
<template>
    <div>
        <VueBootstrapTypeaheadCustom
            ref="estado_Origen_descripcion"
            :data="listEstados"
            v-model.trim="estado_sat.estado_descripcion"
            :serializer="(s) => s.descripcion"
            placeholder="Estado"
            @hit="setSelectedEstado"
            @input="
                searchEstado({
                    search: estado_sat.estado_descripcion,
                    country: pais,
                })
            "
            :prepend="estado_sat.estado"
            :rawResults="true"
            :minMatchingChars="1"
            class="mb-2"
            size="sm"
            :disabled="disabled"
            required
        >
            <template v-slot:suggestion="slotProp">
                <small class="badge badge-secondary">{{
                    slotProp.data.estado
                }}</small
                >&nbsp;{{ slotProp.data.descripcion }}
            </template>
        </VueBootstrapTypeaheadCustom>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
    name: "AppPaisSat",
    components: { VueBootstrapTypeaheadCustom },
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
        pais: {
            type: String,
            default: "MEX",
        },
        value: {
            type: Object,
            default(rawProps) {
                return {
                    estado: "",
                    estado_descripcion: "",
                };
            },
        },
    },
    data() {
        return {
            estado_sat: {
                estado: this.value.estado == null ? "" : this.value.estado,
                estado_descripcion:
                    this.value.estado_descripcion == null
                        ? ""
                        : this.value.estado_descripcion,
            },
        };
    },
    computed: {
        ...mapGetters({
            listEstados: "catalogos/listEstados",
        }),
    },

    watch: {
        "value.estado"(value) {
            this.estado_sat.estado =
                this.value.estado == null ? "" : this.value.estado;
            this.estado_sat.estado_descripcion =
                this.value.estado_descripcion == null
                    ? ""
                    : this.value.estado_descripcion;
        },
        "estado_sat.estado"(value) {
            this.$emit("input", this.estado_sat);
        },

        "estado_sat.estado_descripcion"(value) {
            if (value == "") {
                this.estado_sat.estado = "";
                this.estado_sat.estado_descripcion = "";
                this.$emit("input", this.estado_sat);
            }
        },
    },
    methods: {
        searchEstado(query) {
            this.$emit("input", this.estado_sat);
            //hacer busqueda de estados solo cuando pais sea USA o CANADA, (cuando pais es mexico se asigna aumaticammnete mediante cp)
            if (
                this.pais == "USA" ||
                this.pais == "CAN" ||
                this.pais == "USA" ||
                this.pais == "CAN"
            ) {
                this.$store.dispatch("catalogos/fetchEstados", {
                    search: query.search,
                    country: query.country,
                });
            }
        },
        setSelectedEstado(estado) {
            this.estado_sat.estado = estado.estado;
            this.estado_sat.estado_descripcion = estado.descripcion;

            setTimeout(() => {
                //para evitar que el 'vbt-autocomplete-list' se abra
                this.$store.dispatch("catalogos/setEstadosEmpty");
            }, 200);
        },
    },
};
</script>
