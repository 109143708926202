<template>
  <div>
    <VueBootstrapTypeaheadCustom
      :data="listClavesUnidad"
      v-model.trim="unidad_sat.unidad"
      :serializer="(s) => s.descripcion"
      placeholder="Unidad SAT"
      @hit="setSelectedClaveUnidad"
      @input="searchClaveUnidad"
      class="mb-2"
      size="sm"
      :minMatchingChars="1"
      :rawResults="true"
      :prepend="unidad_sat.clave_unidad"
      required
    >
      <template v-slot:suggestion="slotProp">
        <span class="badge badge-secondary">{{ slotProp.data.clave }}</span>
        {{ slotProp.data.descripcion }}
      </template>
    </VueBootstrapTypeaheadCustom>
    <div class="text-danger">
      <small>{{ errorUnidadSat }}</small>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";

export default {
  name: "AppDocumentacionAduanera",
  components: { VueBootstrapTypeaheadCustom },
  props: {
    unidadSat: {
      type: Object,
      default(rawProps) {
        return {
          clave_unidad: "",
          unidad: "",
        };
      },
    },
  },
  data() {
    return {
      errorUnidadSat: "",
      unidad_sat: this.unidadSat,
    };
  },
  computed: {
    ...mapGetters({
      listClavesUnidad: "catalogos/listClavesUnidad",
    }),

  },
  mounted() {},
  watch: {
    "unidad_sat.clave_unidad"(newValue) {
      this.$emit("input", this.unidad_sat);
    },
  },
  methods: {
    searchClaveUnidad(query) {
      this.$store.dispatch("catalogos/fetchClavesUnidad", query);
    },

    setSelectedClaveUnidad(value) {
      this.unidad_sat.clave_unidad = value.clave;
      this.unidad_sat.unidad = value.descripcion;
      setTimeout(() => {
        this.$store.dispatch("catalogos/setClavesUnidadEmpty");
      }, 200);
    },

    async validateUnidadSat() {
      let isValid = true;
      this.errorUnidadSat = "";

      if (this.unidad_sat.clave_unidad == "" && this.unidad_sat.unidad == "") {
        this.errorUnidadSat = "Clave Unidad SAT indefinida";
        isValid = false;
      }

      if (this.unidad_sat.clave_unidad == "" && this.unidad_sat.unidad != "") {
        await this.$store
          .dispatch(
            "catalogos/validateClaveUnidad",
            this.unidad_sat.unidad.trim()
          )
          .then((response) => {
            this.unidad_sat.clave_unidad = response.data.clave;
            this.unidad_sat.unidad = response.data.descripcion;
          })
          .catch((error) => {
            if (error.data.msg) {
              this.errorUnidadSat = error.data.msg;
            } else {
              this.errorUnidadSat = "Unidad no encontrada";
            }
            isValid = false;
          });
      }

      if (this.unidad_sat.unidad.length >= 20) {
        this.errorUnidadSat = "Descripción de la unidad es demasiado larga";
        isValid = false;
      }

      this.$emit("is-valid", isValid);
    },
  },
};
</script>
