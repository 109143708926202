<template>
    <div class="container-fluid">
       <!--  <div v-show="showVersion" class="row justify-content-center align-items-center">
            <div class="col-auto">
                <div class="my-2">
                    <span>Usar Versión: </span> &nbsp;
                    <div class="form-check form-check-inline">
                        <input
                            v-model="radioNumVersion"
                            class="form-check-input"
                            type="radio"
                            value="20"
                        />
                        <label class="form-check-label">Carta Porte 2.0</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            v-model="radioNumVersion"
                            class="form-check-input"
                            type="radio"
                            value="30"
                        />
                        <label class="form-check-label">Carta Porte 3.0</label>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="col-lg-8 col-md-12">
                <div class="mb-2">
                    <VueBootstrapTypeaheadCustom
                        :data="listClientes"
                        size="sm"
                        v-model="customer.nombre"
                        :serializer="(s) => s.nombre"
                        placeholder="Buscar cliente"
                        @hit="setSelectedCustomer"
                        @input="searchCustomer"
                        :minMatchingChars="1"
                        :rawResults="true"
                        :disabled="customerSelected"
                        ref="nombre"
                    >
                        <template v-slot:suggestion="slotProp">
                            <span class="badge badge-secondary">{{
                                slotProp.data.rfc
                            }}</span>
                            {{ slotProp.data.nombre }}
                        </template>
                        <template v-slot:append>
                            <button
                                class="btn btn-danger"
                                @click="deselectCustomer"
                                :disabled="disabledBtnDeselectCustomer"
                            >
                                <font-awesome-icon icon="times" />
                            </button>
                        </template>
                    </VueBootstrapTypeaheadCustom>
                </div>
                <div class="form-row">
                    <div class="col-lg-3">
                        <input
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="RFC"
                            maxlength="13"
                            v-model.trim="customer.rfc"
                            @blur="customer.rfc = customer.rfc.toUpperCase()"
                            :disabled="customerSelected"
                        />
                    </div>
                    <div :class="[isVersion40 ? 'col-lg-3' : 'col-lg-6']">
                        <input
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Correo Electronico"
                            v-model.trim="customer.email1"
                            ref="email"
                        />
                    </div>
                    <div :class="[isVersion40 ? 'col-lg-2' : 'col-lg-3']">
                        <input
                            type="text"
                            class="form-control form-control-sm mb-2"
                            placeholder="Código Postal"
                            v-model.trim="customer.cp"
                            ref="cp"
                        />
                    </div>
                    <div class=" col-lg-4" v-if="isVersion40"> 
                        <select
                            class="custom-select custom-select-sm mb-2"
                            v-model="customer.regimen"
                            :disabled="!(customer.rfc.length >= 12)"
                        >
                            <option selected value="">
                                Seleccione R&eacute;gimen Fiscal
                            </option>
                            <option
                                v-for="item in listRegimenesFilter"
                                :key="item.clave"
                                :value="item.clave"
                            >
                                {{ item.clave }} - {{ item.descripcion }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="input-group input-group-sm mb-2">
                    <select
                        class="custom-select"
                        v-model="selectedIdSerieFac"
                        @change="setSerieFac()"
                    >
                        <option selected :value="{id: null, tipo: null}">Seleccione Serie</option>
                        <option
                            v-for="item in listSeriesFormatoCartaPorte"
                            :key="item.id"
                            :value="{id: item.id, tipo: item.tipo}"
                        >
                            <span v-if="item.tipo == 'F'">Factura</span> 
                            <span v-if="item.tipo == 'T'">Traslado</span>
                            - {{ item.serie }}{{ item.foliosig }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button class="btn btn-outline-info" @click="addNewSerie()" type="button"><font-awesome-icon icon="plus" /></button>
                    </div> 
                </div>
                <b-form-datepicker 
                    v-model="date"
                    @input="setDate()"
                    :min="minDateInvoice" 
                    :max="maxDateInvoice" 
                    locale="es" 
                    size="sm"
                    class="mb-2">
                </b-form-datepicker>
            </div>
        </div>
        <b-card no-body>
            <b-tabs card content-class="m-3" v-model="tabIndex" @activate-tab="tabChanged" >
                <b-tab no-body title="CFDI" :disabled="!isActivedTab1" :active="isActivedTab1">
                    <CFDI ref="cfdi" @nextTab="nextTab()" @resetTabCFDI="resetTabCFDI()" />
                </b-tab>
                <b-tab no-body title="Origen y destino">
                    <Ubicaciones ref="ubicaciones" @previousTab="previousTab()" @nextTab="nextTab()" />
                </b-tab>
                <b-tab no-body title="Transporte">
                    <Transporte ref="trasporte" @previousTab="previousTab()" @nextTab="nextTab()" />
                </b-tab>
                <b-tab no-body title="Mercancias">
                    <Mercancia ref="mercancia" @previousTab="previousTab()" @resetTabsView="resetTabsView()" />
                </b-tab>
                <template #tabs-end>
                    <li role="presentation" v-if="isDemo" class="nav-item align-self-center">
                        <div class="card">
                            <div class="card-body text-center">
                                <p class="mb-1">Regístrate hoy mismo <br>
                                y Recibe 5 timbres <b>GRATIS</b></p>
                                <div class="mb-1"><small class="text-secondary">Sin Contratos. Sin Anualidad. Fácil de Usar.</small></div>
                                <a href="https://factura123.mx/app/#register-account" class="btn btn-block btn-success rounded-pill"><b>¡Contrátalo ahora!</b></a> 
                            </div>
                        </div>
                    </li>
                </template>

            </b-tabs>
        </b-card>
        <ModalAddSerie ref="ModalAddSerie" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import CFDI from "@/views/crear_factura/cfdi/View.vue";
import Ubicaciones from "@/views/crear_factura/ubicaciones/View.vue";
import Transporte from "@/views/crear_factura/transporte/View.vue";
import Mercancia from "@/views/crear_factura/mercancia/View.vue";
import ModalAddSerie from "@/views/crear_factura/ViewModalAddSerie.vue";
import VueBootstrapTypeaheadCustom from "@/components/VueBootstrapTypeahead/VueBootstrapTypeahead";
import { dataCompanyStorage } from "@/utils/localStorage";

export default {
    name: "ViewCreateInvoice",
    components: {
        VueBootstrapTypeaheadCustom,
        CFDI,
        Ubicaciones,
        Transporte,
        Mercancia,
        ModalAddSerie,
    },
    data() {
        return {
            //showVersion:true,
            isTraslado:null,
            isDemo:false,
            selectedRegimen: "",
            selectedIdSerieFac:{
                id:null,
                tipo:null
            },
            customerSelected: false,
            disabledBtnDeselectCustomer:false,
            disabledSltRegimen:true,
            //radioNumVersion: "30",
            isActivedTab1:true,
            isActivedTab2:false,
            isActivedTab3:false,
            isActivedTab4:false,
            date: "",
            minDateInvoice: "",
            maxDateInvoice:"",
            tabIndex: 0,
            listRegimenesFilter: [],
        };
    },
    watch: {
        tabIndex(actualTab){
			switch (actualTab) {
                case 0:
					this.$refs.cfdi.loadLocalData();
                    break;
                case 1:
					this.$refs.ubicaciones.loadLocalData();
                    break;
                case 2:
					this.$refs.trasporte.loadLocalData();
                    break;
            } 
		},

        radioNumVersion(version) {
            this.$store.dispatch("carta_porte/saveVersion", version); 
        },

        "customer.rfc"(value) {
            if (value.length >= 12) {
                let esfisica = value.length == 13;
                //this.disabledSltRegimen = false;
                this.loadRegimenes(esfisica);  
            }else{
                this.customer.regimen = "";
                //this.disabledSltRegimen = true;
            }

            this.setCustomCustomer()
        },
        "customer.email1"() {
           this.setCustomCustomer()
        },

        "customer.cp"() {
            this.setCustomCustomer()
        },

        "customer.regimen"() {
            this.setCustomCustomer()
        },

        "selectedIdSerieFac.tipo"(newSerieTipo, oldSerieTipo) {
            if(newSerieTipo == "T"){
                this.isTraslado = true;
                this.initTraslado();
                this.isActivedTab1 = false; 
            }else{ 
                this.isTraslado = false;
                this.initInvoice();
                this.isActivedTab1 = true;
            }

            if(oldSerieTipo == "T" && (newSerieTipo == "F" || newSerieTipo == "" )){
                this.deselectCustomer();      
            } 
        },
        
    },
    computed: {
        ...mapGetters({
            listSeries: "catalogos/listSeries",
            listClientes: "catalogos/listClientes",
            listRegimenes: "catalogos/listRegimenes",
            customer: "cfdi/getCustomer",
            config: "catalogos/getConfig",
            version: "carta_porte/getVersion",
            getSerieFac: "cfdi/getSerieFac"
        }),

        isVersion40(){
            if(this.config.val_version == 40){
                return true;
            }else{
                return false;
            } 
        },

        listSeriesFormatoCartaPorte() {
            return this.listSeries.filter(i => i.idfto == 14 || i.tipo == "T")
        },

        isDemos(){
            if (location.host == "cartaporte.demo2.factura123.mx" || location.host == "cartaporte.demo.factura123.mx") {
                this.isDemo =  true
            }else{
                this.isDemo = false
            }
        }
    },
    async mounted() {
        this.loadLists();
        this.loadDateDefault();
        this.$refs.nombre.setFocus()
/*         this.radioNumVersion = this.version;

        var datenow = new Date();
        var dateEnd = new Date(2023,10,25);

        if (datenow.getTime() <= dateEnd.getTime()) { 
            this.showVersion = false
            this.radioNumVersion = "20"
            this.$store.dispatch("carta_porte/saveVersion", this.radioNumVersion); 
        } */
        await this.$store.dispatch("catalogos/fetchRegimenes");

        this.loadLocalData();

    },
    methods: {

        loadLocalData() {
            this.selectedIdSerieFac.id = this.getSerieFac.idSerieFac;
            this.selectedIdSerieFac.tipo = this.getSerieFac.type;
            
            if (this.customer.rfc.length >= 12) {
                let esfisica = this.customer.rfc.length == 13;
                this.loadRegimenes(esfisica);  
            }

            if(this.customer.rfc != "" && this.customer.idcli) {
                this.customerSelected = true;
            }
                
            this.$refs.email.focus();
        },

        initTraslado(){
            let dataAccount = dataCompanyStorage.get();
            this.$store.dispatch("cfdi/setCustomer",{ 
                idcli: null,
                rfc: dataAccount.rfc.trim(),
                nombre: dataAccount.nombre.trim(),
                cp: dataAccount.cp.trim(),
                regimen: dataAccount.regimen.trim(),
                email1: "",
            });

            this.customerSelected = true;
            this.disabledBtnDeselectCustomer = true;
            
            this.$refs.email.focus();
        },

        initInvoice(){
            this.$store.dispatch("cfdi/setCfdiUse", ""); 
            this.disabledBtnDeselectCustomer = false;      
        },

        loadLists() {
            this.$store.dispatch("catalogos/fetchSeries").then(() => {
                switch(this.listSeriesFormatoCartaPorte.length){
                    case 0:
                        this.addSerieInFactura123();
                        break;
                    case 1:
                        this.selectedIdSerieFac = {id:this.listSeriesFormatoCartaPorte[0].id, tipo: this.listSeriesFormatoCartaPorte[0].tipo}
                        this.setSerieFac();
                        break;
                }
            });
        },
		tabChanged(newTab,prevTab){
			switch (prevTab) {
                case 0:
					this.$refs.cfdi.saveTab();
                    break;
                case 1:
					this.$refs.ubicaciones.saveTab();
                    break;
                case 2:
					this.$refs.trasporte.saveTab();
                    break;
            }
		},
        loadDateDefault() {
            let now = new Date()
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            let minDate = new Date(today)
            minDate.setDate(minDate.getDate() - 3)
            this.minDateInvoice = minDate;
            this.maxDateInvoice = new Date(today)
    
            this.date = now.getFullYear().toString() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate().toString().padStart(2, "0");
            this.$store.dispatch("cfdi/setDate", this.date);
        },

        addNewSerie() {
            this.$refs.ModalAddSerie.show();
        },

        addSerieInFactura123(){
            this.$bvModal.msgBoxConfirm('Por favor configure una serie con el formato "Complemento carta porte" para poder facturar', {
                title: 'No se encontraron series',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Configurar serie',
                cancelVariant: 'light border',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if(value){
                    window.open("https://factura123.mx/app/#config/series","_self");
                }
            }).catch(err => {

            })
        },

        setSelectedCustomer(c) {
            this.$store.dispatch("cfdi/setCustomerID", c);
            this.customerSelected = true;
            this.$refs.email.focus();
        },

        setCustomCustomer() {
            this.$store.dispatch("cfdi/setCustomer", this.customer);            
        },

        searchCustomer(query) {
            this.$store.dispatch("catalogos/fetchClientes", query);
        },

        deselectCustomer() {
            this.$store.dispatch("cfdi/setCustomerEmpty");
            this.customerSelected = false;
            setTimeout(() => {
                this.$refs.nombre.setFocus();
            }, 200);
        },

        loadRegimenes(isFisica){
            this.listRegimenesFilter = [];
            this.listRegimenes.forEach(item => {
                if (isFisica && item['esfisica'] == 1 || isFisica && item['esfisica'] == 2)
                    this.listRegimenesFilter.push(item);
                if (!isFisica&& item['esfisica'] == 0 || !isFisica && item['esfisica'] == 2)
                    this.listRegimenesFilter.push(item);
            });

            let regimen = this.listRegimenesFilter.find(
                (item) => item.clave == this.customer.regimen
            );

            if(!regimen){
                this.customer.regimen = "";
            }

        },

        setSerieFac() {
            this.$store.dispatch("cfdi/setSerieFac", this.selectedIdSerieFac);
        },

        setDate() {
            this.$store.dispatch("cfdi/setDate", this.date);
        },

        changeTabDisabled(tab){
            tab == 0 ? this.isActivedTab1 = true : this.isActivedTab1 =  false;
            tab == 1 ? this.isActivedTab2 = true : this.isActivedTab2 =  false;
            tab == 2 ? this.isActivedTab3 = true : this.isActivedTab3 =  false;
            tab == 3 ? this.isActivedTab4 = true : this.isActivedTab4 =  false;
        },

        previousTab() {
            //this.changeTabDisabled(this.tabIndex-1)
            this.tabIndex--;
        },

        nextTab() {
            //this.changeTabDisabled(this.tabIndex+1)
            this.tabIndex++;
            
        },

        resetTabCFDI(){
            this.selectedIdSerieFac.id = null;
            this.selectedIdSerieFac.tipo = null;
            this.customerSelected = false;
            this.loadDateDefault();
        },

        resetTabsView() {
            this.selectedIdSerieFac.id = null;
            this.selectedIdSerieFac.tipo = null;
            this.customerSelected = false;
            this.changeTabDisabled(0)
            this.tabIndex = 0;
            this.loadDateDefault();
            this.$refs.cfdi.resetView();
            this.$refs.ubicaciones.resetView();
            this.$refs.trasporte.resetView();
            this.$refs.mercancia.resetView();
        },
    },
};
</script>