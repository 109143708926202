<template>
    <div id="app">
        <TheNavbar></TheNavbar>
        <router-view v-if="config.usa_cartaporte"/>
    </div>
</template>
<script>
import TheNavbar from "./components/TheNavbar.vue";
import { dataConfigStorage, dataCompanyStorage } from "@/utils/localStorage";
import { mapGetters } from "vuex";
export default {
    name: "App",
    components: {
        TheNavbar,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            config: "catalogos/getConfig",
        }),
    },
    mounted() {
        if (location.host == "cartaporte.demo2.factura123.mx" || location.host == "cartaporte.demo.factura123.mx") {
            this.$store.dispatch("demo_auth/loginAuth").then(() => {
                this.getDataCompany();
                this.getConfig();
            })
        }else{
            this.getDataCompany();
            this.getConfig();
        }

        var datenow = new Date();
        var dateEnd = new Date(2024,6,17);

        if (datenow.getTime() < dateEnd.getTime()) { 
            this.$store.dispatch("carta_porte/saveVersion", 30); 
        }else{
            this.$store.dispatch("carta_porte/saveVersion", 31);
        }

    },
    methods: {
        getDataCompany(){
            this.$store.dispatch("catalogos/fetchDataCompany")
            .then((response) => {
                dataCompanyStorage.set(response.data);
            })
        },

        getConfig(){
            this.$store.dispatch("catalogos/fetchConfig")
            .then((response) => {
                dataConfigStorage.set(response.data);
                this.$store.dispatch("catalogos/fetchMonedas");
                this.$store.dispatch("catalogos/fetchDecimales");
                this.$store.dispatch("catalogos/fetchImpuestos");
                this.$store.dispatch("catalogos/fetchMonedas");

                if(!response.data.usa_cartaporte){
                    this.$bvModal
                    .msgBoxOk(
                        "Usted no cuenta con el complemento Carta Porte, por favor regrese a Factura 123 para obtenerlo.",
                        {
                            title: "Complemento Carta Porte sin activar",
                            size: "sm",
                            buttonSize: "sm",
                            okVariant: "primary",
                            headerClass: "p-2 border-bottom-0",
                            footerClass: "p-2 border-top-0",
                            centered: true
                        }
                    )
                    .then((value) => {
                        if (value) {
                            window.close();
                        }
                    });
                }
            })
            .catch((error) => {
                if(error.status == 401){
                    this.$bvModal
                    .msgBoxOk(error.data.msg, {
                        title: "Sesión Terminada",
                        size: "sm",
                        buttonSize: "sm",
                        okTitle:"Iniciar sesión",
                        okVariant: "primary", 
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        if(value){
                            window.location.href = "https://factura123.mx/app";
                        }
                    });

                }else{
                    let msg = "";
                    if(error.data.msg){
                        msg = error.data.msg;
                    }else{
                        msg = error.data
                    }
                    this.$bvModal
                    .msgBoxOk(msg, {
                        title: "Problemas al obtener configuración",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "primary", 
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        if(value){
                            window.close();
                        }
                    });
                }
                console.log(error.data)
            });
        },
    }
};
</script>
<style>
.vh-75 {
    height: 75vh !important;
}
</style>
