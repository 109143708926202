<template>
    <b-modal
        ref="modal-observaciones"
        id="modal-observaciones"
        size="lg"
        title="Observaciones"
        @shown="setInitFocus()"
        @hidden="cleanForm"
        @ok="handleOk"
    >
        <form @submit.stop.prevent="save">
            <b-form-textarea
                ref="observaciones"
                v-model="form.observaciones"
                placeholder="Observaciones"
                :rows="rows"
            ></b-form-textarea>
            <div class="text-danger">
                <small>{{ errorObs }}</small>
            </div>
            <br>
            <button class="btn btn-light border" @click.prevent="addRows()">
                <font-awesome-icon icon="plus" /> Agregar renglón
            </button>
        </form>
        <template #modal-footer="{ ok, cancel }">
            <button class="btn btn-danger" @click="elimnarObs()">
                <font-awesome-icon icon="trash-alt" /> Borrar observaciones
            </button>
            <button class="btn btn-light border" @click="cancel()">
               <font-awesome-icon icon="times" /> Cerrar
            </button>
            <button class="btn btn-primary" @click="ok()">
                <font-awesome-icon icon="save" /> Guardar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ModalConcepto",
    data() {
        return {
            errorObs: "",

            rows:6,
            form: {
                observaciones: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            getObservaciones: "cfdi/getObservaciones",
        }),
    },
    methods: {
        setInitFocus() {
            this.$refs.observaciones.focus();
        },

        show() {
            this.loadData();
            this.$bvModal.show("modal-observaciones");
        },

        hide() {
            this.$bvModal.hide("modal-observaciones");
        },

        loadData() {
            this.form.observaciones = this.getObservaciones
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.save();
        },

        save() {
            this.$store.dispatch(
                "cfdi/setCfdiObservations",
                this.form.observaciones
            );

            this.$nextTick(() => {
                this.$bvModal.hide("modal-observaciones");
            });
        },


        addRows() {
            this.rows = this.rows + 1;
        },
        elimnarObs() {
            this.rows = 6;
            this.$store.dispatch("cfdi/setCfdiObservations","");
            this.$nextTick(() => {
                this.$bvModal.hide("modal-observaciones");
            });
        },

        cleanForm() {
            this.form.observaciones = "";
            this.errorObs = "";
        },
    },
};
</script>   