import Vue from 'vue'
import Vuex from 'vuex'
import Catalogos from './modules/catalogos'
import Flete from './modules/flete'
import Cfdi from './modules/cfdi'
import CartaPorte from './modules/carta_porte'
import DemoAuth from './modules/demo_auth'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        catalogos: Catalogos,
        flete: Flete,
        cfdi: Cfdi,
        carta_porte: CartaPorte,
        demo_auth: DemoAuth
    },
    mutations: {
        initialise_store(state) {
            if (localStorage.getItem('f123-invoice-carta-porte')) {
                state.carta_porte = JSON.parse(localStorage.getItem('f123-invoice-carta-porte'))
            }

            if (localStorage.getItem('f123-invoice-cfdi')) {
                state.cfdi = JSON.parse(localStorage.getItem('f123-invoice-cfdi'))
            }
        }
    }
})

