import Vue from 'vue'
import VueRouter from 'vue-router'
import CrearFactura from '../views/crear_factura/View.vue'
import AjustesCartaPorte from '../views/ajustes/View.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: { name: 'CrearFactura' }
    }, {
        path: '/emitir',
        name: 'CrearFactura',
        component: CrearFactura
    },
    {
        path: '/configuracion',
        name: 'AjustesCartaPorte',
        component: AjustesCartaPorte
    },
]

const router = new VueRouter({
    routes
})

export default router
