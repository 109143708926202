const nameDataConfig = 'f123-config'
const nameDataCompany = 'f123-data-company'

export const dataConfigStorage = {
    set(data) {
        try {
            delete data.cartaporte;
            delete data.logotipo;
            delete data.formato_color;
            localStorage.setItem(nameDataConfig, JSON.stringify(data));
        } catch (e) {
            console.warn('Error al tratar de guardar configuración, ' + e.toString());
        }
    },

    remove() {
        localStorage.removeItem(nameDataConfig);
    },  

    get() {
        var config = null;
        try {
            config = JSON.parse(localStorage.getItem(nameDataConfig));
        } catch (e) {
            console.warn('Error al tratar de obtener configuración, ' + e.toString());
        }
        return config;
    },


    getValue(key) {
        if(this.get()) {
            if (typeof this.get()[key] === 'undefined')
                throw new Error(key + " Inexistente");
            return this.get()[key];
        }
    },
};

export const dataCompanyStorage = {
    set(data) {
        try {
            delete data.cartaporte;
            delete data.logotipo;
            delete data.formato_color;
            localStorage.setItem(nameDataCompany, JSON.stringify(data));
        } catch (e) {
            console.warn('Error al tratar de guardar datos compañia, ' + e.toString());
        }
    },

    remove() {
        localStorage.removeItem(nameDataCompany);
    },  

    get() {
        var config = null;
        try {
            config = JSON.parse(localStorage.getItem(nameDataCompany));
        } catch (e) {
            console.warn('Error al tratar de obtener datos compañia, ' + e.toString());
        }
        return config;
    },


    getValue(key) {
        if(this.get()) {
            if (typeof this.get()[key] === 'undefined')
                throw new Error(key + " Inexistente");
            return this.get()[key];
        }
    },
};


